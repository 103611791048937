import { SecretaryClassRepository } from "@/api/Repositories";
import { onMounted, ref } from "vue";
import { periodSelectMapper } from "../mappers/class";

export default function usePeriodType() {
  const periodOptions = ref<SelectOptionType[]>([]);

  onMounted(async () => {
    periodOptions.value = periodSelectMapper(await SecretaryClassRepository.getPeriod())
  });

  return { periodOptions }
}
