<template>
  <template-dashboard>
    <template-secretary-filters>
      <template #filters>
        <app-select label="Unidade" class="select-units" />
        <app-select label="Ano" class="select-years" />
      </template>
    </template-secretary-filters>
    <dashboard-title title="Inserir Série">
      <app-button
        left-icon="arrow_back"
        class="w-auto back"
        mini
        @click="$router.back"
        >Voltar</app-button
      >
    </dashboard-title>
    <form class="grid grid-cols-2 gap-4" @submit="onSubmit">
      <simple-text-field
        placeholder="Descrição"
        class="col-span-2 mt-4"
        name="descricao"
      />
      <app-select label="Segmento" name="curso_id" :options="courseOptions" />
      <simple-text-field placeholder="Ordem" name="ordem" />

      <h2 class="col-span-2 text-xl font-bold text-primary md:text-2xl">
        Próximo
      </h2>
      <app-select
        label="Segmento"
        :options="courseOptions"
        @change="getSeriesByCourse"
        name="proxima_serie_curso"
      />
      <app-select
        label="Série"
        name="proxima_serie_id"
        :options="seriesOptions"
      />

      <h2 class="col-span-2 text-xl font-bold text-primary md:text-2xl">
        Histórico
      </h2>
      <app-select
        label="Série"
        name="codserie_historico"
        :options="historicOptions"
        class="col-span-2 md:col-span-1"
      />

      <div
        class="flex justify-end w-full col-span-2 mt-10  footer-separation-border"
      >
        <app-button class="w-auto mt-4 yellow mini" leftIcon="save"
          >Salvar</app-button
        >
      </div>
    </form>
  </template-dashboard>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppButton from "@/components/AppButton.vue";
import {
  CourseRepository,
  SecretarySeriesRepository,
} from "@/api/Repositories";
import TemplateSecretaryFilters from "@/components/TemplateSecretaryFilters.vue";
import { useRoute, useRouter } from "vue-router";
import SimpleTextField from "@/components/SimpleTextField.vue";
import schema from "@/utils/validations/serie";
import { useForm } from "vee-validate";
import historicOptions from "@/utils/mocks/historic";
import useCourseType from "@/utils/composables/course_type";
import useSeriesType from "@/utils/composables/series_type";
import DashboardTitle from "@/components/DashboardTitle.vue";

export default defineComponent({
  components: {
    TemplateDashboard,
    AppSelect,
    AppButton,
    TemplateSecretaryFilters,
    SimpleTextField,
    DashboardTitle,
  },
  name: "SecretaryUnits",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const courseOptions = useCourseType();
    const editId = ref();
    const { getSeriesByCourse, seriesOptions } = useSeriesType();

    const {
      handleSubmit,
      meta: formMeta,
      setValues,
    } = useForm({
      validationSchema: schema,
    });

    onMounted(async () => {
      editId.value = Number(route.params.id);

      if (!editId.value) return;

      const formValues = await SecretarySeriesRepository.getSerieById(
        editId.value
      );

      await getSeriesByCourse(formValues.curso_id);

      setValues({
        descricao: formValues.descricao,
        curso_id: formValues.curso_id,
        ordem: formValues.ordem,
        proxima_serie_id: formValues.proxima_serie_id,
        codserie_historico: formValues.codserie_historico,
        proxima_serie_curso: formValues.proxima_serie.curso_id,
      });
    });

    const onSubmit = handleSubmit((values) => {
      if (!editId.value) {
        SecretarySeriesRepository.create(values).then(() => {
          router.push("/secretaria/series");
        });

        return;
      }

      SecretarySeriesRepository.update(editId.value, values).then(() => {
        router.push("/secretaria/series");
      });
    });

    const back = () => {
      router.back();
    };

    return {
      back,
      formMeta,
      historicOptions,
      ...courseOptions,
      getSeriesByCourse,
      seriesOptions,
      onSubmit,
    };
  },
});
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-enter-from {
  opacity: 0;
}

.select-years {
  @apply md:max-w-yearFilter w-full;
}

.select-units {
  @apply md:max-w-unityFilter w-full;
}

.title-separation-border {
  border-bottom: 1px solid transparentize($color: $primary-color, $amount: 0.88);
}

.footer-separation-border {
  border-top: 1px solid transparentize($color: $primary-color, $amount: 0.88);
}

.list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.border-gray-100 {
  border: 1px solid $gray100-color;
}

.table-header {
  color: transparentize($color: $black-color, $amount: 0.38);
}

.table-text {
  color: transparentize($color: $black-color, $amount: 0.6);
}
</style>
