<template>
  <template-dashboard>
    <dashboard-title title="Curso">
      <div>
        <app-button
          @click="$router.push('/secretaria/cursos/inserir')"
          mini
          left-icon="add"
          class="yellow"
          >Inserir</app-button
        >
      </div>
    </dashboard-title>
    <template-information-card
      :headers="['Curso', 'Tipo', 'Sigla', 'Situação', '']"
    >
      <tr v-for="c in courses" :key="c.id">
        <td>{{ c.titulo }}</td>
        <td>{{ c.tipo_curso.descricao }}</td>
        <td>{{ c.sigla }}</td>
        <td
          class="text-error font-medium"
          :class="c.ativo ? 'text-success' : 'text-error'"
        >
          {{ c.ativo ? "ATIVO" : "INATIVO" }}
        </td>
        <td></td>
      </tr>
    </template-information-card>
  </template-dashboard>
</template>

<script>
import TemplateDashboard from "@/components/TemplateDashboard.vue";
import TemplateInformationCard from "@/components/TemplateInformationCard.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";
import AppButton from "@/components/AppButton.vue";
import { defineComponent, ref, onMounted } from "vue";
import { CourseRepository } from "@/api/Repositories";

export default defineComponent({
  components: {
    TemplateInformationCard,
    TemplateDashboard,
    DashboardTitle,
    AppButton,
  },

  setup() {
    const courses = ref([]);

    onMounted(async () => {
      courses.value = await CourseRepository.getAll();
    });

    return { courses };
  },
});
</script>

<style>
</style>
