
import { defineComponent } from "vue";
import TemplateLogin from "@/components/TemplateLogin.vue";
import AppButton from "@/components/AppButton.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import { useForm } from "vee-validate";

import * as yup from "yup";
import { UserRepository } from "@/api/Repositories";
import { setAuth } from "@/api/AxiosClient";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "Login",
  components: {
    TemplateLogin,
    AppButton,
    SimpleTextField
  },
  setup() {
    const toast = useToast();

    const { handleSubmit } = useForm<Login>({
      validationSchema: yup.object().shape({
        user: yup
          .string()
          .required()
          .label("Usúario"),
        password: yup
          .string()
          .required()
          .label("Senha")
      }) as any
    });

    const router = useRouter();

    const onSubmit = handleSubmit(data => {
      UserRepository.login(data)
        .then(({ data }) => {
          setAuth(data.data);

          router.push("/");
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
        });
    });
    return { onSubmit };
  }
});
