<template>
  <div class="selects-container">
    <div class="filters">
      <slot name="filters" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.selects-container {
  margin-bottom: 3rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.filters {
  @apply w-full flex flex-col items-end md:flex-row md:items-center justify-end gap-4;
}
</style>
