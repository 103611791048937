
import { defineComponent } from "vue";
import { maska } from "maska";
import ErrorMessage from "./ErrorMessage.vue";
import { useField } from "vee-validate";

export default defineComponent({
  components: { ErrorMessage },
  name: "SimpleTextField",
  props: {
    name: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    type: String,
    label: String,
    placeholder: String,
    required: Boolean,
    readonly: {
      type: Boolean,
      default: false
    },
    rightIcon: String,
    mask: {}
  },
  data() {
    return {
      inputType: this.type
    };
  },
  methods: {
    switchVisibility() {
      this.inputType = this.inputType === "password" ? "text" : "password";
    }
  },
  directives: {
    maska
  },
  setup(props) {
    const { handleChange, handleBlur, value: inputValue, errorMessage } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value
      }
    );

    return {
      handleChange,
      handleBlur,
      inputValue,
      errorMessage
    };
  }
});
