
import { defineComponent, onMounted, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppButton from "@/components/AppButton.vue";
import {
  SecretaryClassRepository,
  SecretarySeriesRepository,
} from "@/api/Repositories";
import AppDropdownItem from "@/components/AppDropdownItem.vue";
import TemplateSecretaryFilters from "@/components/TemplateSecretaryFilters.vue";
import TemplateInformationCard from "@/components/TemplateInformationCard.vue";
import { useRouter } from "vue-router";
import useCourseType from "@/utils/composables/course_type";
import useSeriesType from "@/utils/composables/series_type";
import AppDropdown from "@/components/AppDropdown.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";

type OptionType = {
  label: string;
  value: number;
};

export default defineComponent({
  components: {
    TemplateDashboard,
    AppSelect,
    AppButton,
    TemplateInformationCard,
    AppDropdownItem,
    TemplateSecretaryFilters,
    AppDropdown,
    DashboardTitle,
  },
  name: "SecretaryUnits",
  setup() {
    const courseOptions = useCourseType();
    const showSeries = ref(false);
    const tableData = ref([] as Class[]);
    const CourseOptionId = ref();
    const router = useRouter();
    const { getSeriesByCourse, seriesOptions } = useSeriesType();

    const navigate = (route: string) => {
      router.push(route);
    };

    const getData = async (value: string) => {
      if (!CourseOptionId.value) {
        return;
      }

      console.log("entrou");

      tableData.value = await SecretaryClassRepository.getAll({
        course_id: CourseOptionId.value,
        serie_id: value
      });
    };

    const getSeries = async (value: string) => {
      CourseOptionId.value = Number(value);
      await getSeriesByCourse(CourseOptionId.value);
      showSeries.value = true;
    };

    const handleEdit = (classId: string) => {
      router.push(`/secretaria/turmas/${classId}`);
    };

    const handleDelete = async (id: string) => {
      await SecretaryClassRepository.delete(Number(id));
      tableData.value = tableData.value.filter((c: Class) => {
        return c.id !== Number(id);
      });
    };

    return {
      ...courseOptions,
      navigate,
      showSeries,
      seriesOptions,
      handleEdit,
      getData,
      tableData,
      getSeries,
      handleDelete,
    };
  },
});
