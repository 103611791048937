
import { defineComponent } from "vue";
import ErrorMessage from "./ErrorMessage.vue";
import { useField } from "vee-validate";

export default defineComponent({
  components: { ErrorMessage },
  name: "SimpleTextArea",
  props: {
    name: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    label: String,
    placeholder: String,
    required: String,
    readonly: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const { handleChange, handleBlur, value: inputValue, errorMessage } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value
      }
    );

    return {
      handleChange,
      handleBlur,
      inputValue,
      errorMessage
    };
  }
});
