import { array, object, number, string } from "yup";
import addressSchema from "./address";
import contactSchema from "./contact";
import { cnpj } from "../regex";

const personSchema = object().shape({
  id: number()
    .positive()
    .nullable()
    .label("ID"),
  pessoa: object().shape({
    tipo_pessoa: string()
      .oneOf(["PF", "PJ"])
      .required()
      .label("Tipo da pessoa"),
    cnpj: string()
      .matches(cnpj)
      .required()
      .label("CNPJ"),
    nome: string()
      .required()
      .label("Nome"),
    nome_social: string()
      .required()
      .label("Nome Social"),
    enderecos: array()
      .of(addressSchema)
      .min(1)
      .required(),
    contatos: array()
      .of(contactSchema)
      .min(1)
      .required()
  })
});

export default personSchema;
