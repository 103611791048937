<template>
  <template-dashboard>
    <template-secretary-filters>
      <template #filters>
        <app-select label="Unidade" class="select-units" />
        <app-select label="Ano" class="select-years" />
      </template>
    </template-secretary-filters>
    <div class="flex justify-between pb-4 title-container">
      <h1 class="dashboard-title">Unidades</h1>
      <div class="flex gap-4">
        <app-button @click="$router.back" left-icon="arrow_back" class="w-auto back" mini>
          Voltar
        </app-button>
        <app-button v-if="!allowEdit" @click="allowEdit = true" mini left-icon="edit" class="yellow"
          >Editar</app-button
        >
      </div>
    </div>

    <form @submit="onSubmit">
      <div class="form-content">
        <div class="grid grid-cols-1">
          <simple-text-field placeholder="Nome" name="nome" />
        </div>
        <div class="grid grid-cols-1 mt-4">
          <simple-text-field placeholder="Autorização" name="autorizacao" />
        </div>

        <div class="grid w-full grid-cols-2 mt-4 gap">
          <dashboard-upload-card :initialValue="values.logotipo" name="logotipo" label="Logotipo" />
          <dashboard-upload-card
            :initialValue="values.assinatura"
            name="assinatura"
            label="Assinatura"
          />
        </div>

        <div class="mt-10">
          <p class="mb-6 text-2xl font-semibold text-primary">Endereços</p>
          <template-information-card
            :headers="addressHeaders"
            :class="{
              'pointer-events-none text-black text-opacity-60': !allowEdit
            }"
          >
            <tr v-for="(e, idx) in values?.enderecos" :key="e.id">
              <td>
                <radio-button
                  name="endereco_principal"
                  :value="idx"
                  compact
                  @change="changePrimaryAddress"
                />
              </td>
              <td>{{ e.cidade.nome }}</td>
              <td>{{ e.cidade.uf.sigla }}</td>
              <td>{{ e.logradouro }}, {{ e.numero }}, {{ e.bairro }}</td>
              <td>{{ e.cep }}</td>
              <td>
                <app-dropdown>
                  <span class="material-icons card-actions-icon">more_vert</span>
                  <template #content>
                    <app-dropdown-item @click="showAddressForm(idx)">
                      Editar
                    </app-dropdown-item>
                    <app-dropdown-item @click="removeAddress(idx)">Excluir</app-dropdown-item>
                  </template>
                </app-dropdown>
              </td>
            </tr>
          </template-information-card>

          <app-button
            type="button"
            left-icon="add"
            mini
            class="w-auto mt-5 yellow"
            @click="showAddressForm(null)"
            :disabled="!allowEdit || addressShow"
            >Endereço</app-button
          >

          <div v-if="addressShow" class="grid grid-cols-4 gap-4 mt-4">
            <simple-text-field
              :name="`endereco_tmp.cep`"
              placeholder="CEP"
              class="col-span-2"
              :mask="zipCodeMask"
              @keyup="handleGetCep"
            />

            <div class="grid grid-cols-4 col-span-4 gap-4">
              <simple-text-field
                :name="`endereco_tmp.logradouro`"
                class="col-span-3"
                placeholder="Endereço"
              />
              <simple-text-field :name="`endereco_tmp.numero`" placeholder="Número" />
            </div>
            <simple-text-field
              :name="`endereco_tmp.bairro`"
              placeholder="Bairro"
              class="col-span-2"
            />
            <simple-text-field
              :name="`endereco_tmp.complemento`"
              placeholder="Complemento"
              class="col-span-2"
            />
            <app-select
              :name="`endereco_tmp.cidade_id`"
              class="col-span-3"
              label="Cidade"
              compact
              :options="cityOptions"
            />
            <app-select
              :name="`endereco_tmp.uf`"
              class="col-span-1"
              label="UF"
              @change="handleGetCities"
              compact
              :options="statesOptions"
            />

            <div class="flex justify-between col-span-4">
              <app-button
                type="button"
                left-icon="arrow_back"
                mini
                class="w-auto back"
                @click="clearAddress"
              >
                Cancelar
              </app-button>

              <app-button
                type="button"
                left-icon="add"
                mini
                class="w-auto yellow"
                @click="saveAddress"
              >
                Salvar
              </app-button>
            </div>
          </div>
        </div>

        <div class="mt-10">
          <p class="mb-6 text-2xl font-semibold text-primary">Contatos</p>
          <template-information-card
            :headers="contactHeaders"
            :class="{
              'pointer-events-none text-black text-opacity-60': !allowEdit
            }"
          >
            <tr v-for="(c, idx) in values?.contatos" :key="c.id">
              <td>
                <radio-button
                  :name="`contato_principal`"
                  :value="idx"
                  compact
                  @change="changePrimaryContact"
                />
              </td>
              <td>{{ c.tipo_contato_id }}</td>
              <td>{{ c.nome_contato }}</td>
              <td>{{ c.email }}</td>
              <td>{{ c.ddd }}</td>
              <td>{{ c.telefone }}</td>
              <td>
                <app-dropdown>
                  <span class="material-icons card-actions-icon">more_vert</span>
                  <template #content>
                    <app-dropdown-item @click="showContactForm(idx)">
                      Editar
                    </app-dropdown-item>
                    <app-dropdown-item @click="removeContact(idx)">Excluir</app-dropdown-item>
                  </template>
                </app-dropdown>
              </td>
            </tr>
          </template-information-card>

          <app-button
            type="button"
            left-icon="add"
            mini
            class="w-auto mt-5 yellow"
            @click="showContactForm(null)"
            :disabled="!allowEdit || contactShow"
          >
            Contato
          </app-button>

          <div v-if="contactShow" class="grid grid-cols-4 gap-4 mt-4">
            <simple-text-field
              :name="`contato_tmp.nome_contato`"
              class="col-span-2"
              placeholder="Contato"
            />

            <app-select
              :name="`contato_tmp.tipo_contato_id`"
              label="Tipo"
              class="col-span-2"
              :options="contactTypeOptions"
            />

            <simple-text-field :name="`contato_tmp.email`" class="col-span-3" placeholder="Email" />

            <simple-text-field
              :name="`contato_tmp.ddd`"
              class="col-span-1"
              placeholder="DDD"
              :mask="dddMask"
            />

            <simple-text-field
              :name="`contato_tmp.telefone`"
              class="col-span-2"
              placeholder="Telefone"
              :mask="phoneCellphoneMask"
            />

            <div class="flex justify-between col-span-4">
              <app-button
                type="button"
                left-icon="arrow_back"
                mini
                class="w-auto back"
                @click="clearContact"
              >
                Cancelar
              </app-button>

              <app-button
                type="button"
                left-icon="add"
                mini
                class="w-auto yellow"
                @click="saveContact"
              >
                Salvar
              </app-button>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-end w-full mt-10">
        <app-button class="w-auto yellow mini" leftIcon="save">Salvar</app-button>
      </div>
    </form>
  </template-dashboard>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppButton from "@/components/AppButton.vue";
import RadioButton from "@/components/RadioButton.vue";
import { FileRepository, SecretaryUnitRepository } from "@/api/Repositories";
import SimpleTextField from "@/components/SimpleTextField.vue";
import TemplateSecretaryFilters from "@/components/TemplateSecretaryFilters.vue";
import DashboardUploadCard from "@/components/DashboardUploadCard.vue";
import TemplateInformationCard from "@/components/TemplateInformationCard.vue";
import useEditAddress from "@/utils/composables/editAddress";
import useEditContact from "@/utils/composables/editContact";
import { useForm } from "vee-validate";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import schema from "@/utils/validations/unity";

export default defineComponent({
  components: {
    TemplateDashboard,
    AppSelect,
    AppButton,
    RadioButton,

    TemplateSecretaryFilters,
    SimpleTextField,
    DashboardUploadCard,
    TemplateInformationCard
  },
  name: "SecretaryUnitsView",
  setup() {
    const unit = ref({} as Unity);
    const allowEdit = ref(false);

    const router = useRouter();
    const route = useRoute();

    const toast = useToast();

    const { setFieldValue, values, setValues, handleSubmit } = useForm({
      validationSchema: schema as any
    });

    const editAddress = useEditAddress({
      formPrefix: "enderecos",
      prefix: "endereco_tmp",
      setFieldValue,
      values
    });

    const editContact = useEditContact({
      formPrefix: "contatos",
      prefix: "contato_tmp",
      setFieldValue,
      values
    });

    const { id: unitId } = route.params;

    onMounted(async () => {
      unit.value = await SecretaryUnitRepository.get(unitId as string);

      setValues({
        ...unit.value,
        contato_principal: unit.value.contatos.findIndex(c => !!c.principal),
        endereco_principal: unit.value.enderecos.findIndex(e => !!e.principal)
      });
    });

    const onSubmit = handleSubmit(async data => {
      if (data.logotipo[0] instanceof File) {
        const logo = await FileRepository.savePhoto({ file: data.logotipo[0] });
        data.logotipo = logo.url;
      }

      if (data.assinatura[0] instanceof File) {
        const signature = await FileRepository.saveFile({
          file: data.assinatura[0]
        });
        data.assinatura = signature.url;
      }

      SecretaryUnitRepository.edit(unitId as string, data as Unity)
        .then(() => {
          router.push("/secretaria/unidades");
          toast.success("Unidade atualizada com sucesso!");
        })
        .catch(({ response }) => {
          const { data } = response;
          const errors = Object.values(data.data)
            .map(e => (e as string[]).join(", "))
            .join(", ");

          toast.error(errors);
        });
    });

    return {
      ...editAddress,
      ...editContact,
      unit,
      allowEdit,
      values,
      onSubmit
    };
  }
});
</script>

<style lang="scss" scoped>
.selects-container {
  margin-bottom: 3rem;
  display: flex;
  justify-content: flex-end;
  gap: $default-gap;
}

.form-content {
  margin-top: $default-gap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.select-years {
  @apply md:max-w-yearFilter w-full;
}

.select-units {
  @apply md:max-w-unityFilter w-full;
}

.title-container {
  border-bottom: 1px solid transparentize($color: $primary-color, $amount: 0.88);
}

.color-gray-300 {
  color: $gray300-color;
}

.color-gray-600 {
  color: $gray300-color;
}

.gap {
  gap: $default-gap;
}

.border-gray-100 {
  border: 1px solid $gray100-color;
}
</style>
