
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    headers: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
});
