<template>
  <template-dashboard>
    <dashboard-title title="Inserir Empresa">
      <app-button
        left-icon="arrow_back"
        class="w-auto back"
        mini
        @click="$router.back"
        >Voltar</app-button
      >
    </dashboard-title>
    <form class="grid grid-cols-2 gap-6">
      <simple-text-field
        name="pessoa.cnpj"
        placeholder="CNPJ"
        :mask="cnpjMask"
        class="col-span-2 md:col-span-1"
      />
      <simple-text-field
        name="pessoa.nome"
        placeholder="Nome"
        class="col-span-2"
      />
      <simple-text-field
        name="pessoa.nome_social"
        placeholder="Nome Social"
        class="col-span-2"
      />
      <simple-text-field
        name="pessoa.enderecos[0].cep"
        placeholder="CEP"
        class="col-span-2 md:col-span-1"
        :mask="zipCodeMask"
        @keyup="handleGetCep"
      />
      <div class="grid grid-cols-2 col-span-2 gap-6 md:grid-cols-4">
        <simple-text-field
          name="pessoa.enderecos[0].logradouro"
          class="col-span-2 md:col-span-3"
          placeholder="Endereço"
        />
        <simple-text-field
          name="pessoa.enderecos[0].numero"
          placeholder="Número"
          class="col-span-2 md:col-span-1"
        />
      </div>
      <simple-text-field
        name="pessoa.enderecos[0].bairro"
        placeholder="Bairro"
        class="col-span-2 md:col-span-1"
      />
      <simple-text-field
        name="pessoa.enderecos[0].complemento"
        placeholder="Complemento"
        class="col-span-2 md:col-span-1"
      />
      <div class="grid grid-cols-2 col-span-2 gap-6 md:grid-cols-4">
        <app-select
          name="pessoa.enderecos[0].cidade_id"
          class="col-span-2 md:col-span-3"
          label="Cidade"
          compact
          :options="cityOptions"
        />
        <app-select
          name="pessoa.enderecos[0].uf"
          class="col-span-2 md:col-span-1"
          label="UF"
          @change="handleGetCities"
          compact
          :options="statesOptions"
        />
      </div>
      <simple-text-field
        name="pessoa.contatos[0].contato"
        placeholder="Contato"
      />
      <app-select
        name="pessoa.contatos[0].tipo_contato_id"
        label="Tipo"
        :options="contactTypeOptions"
      />
      <div class="grid col-span-2 gap-6 md:grid-cols-6">
        <simple-text-field
          name="pessoa.contatos[0].email"
          placeholder="Email"
          class="col-span-3"
        />
        <simple-text-field
          name="pessoa.contatos[0].ddd"
          placeholder="DDD"
          :mask="dddMask"
        />
        <simple-text-field
          name="pessoa.contatos[0].telefone"
          class="col-span-2"
          placeholder="Telefone"
          :mask="phoneCellphoneMask"
        />
      </div>
      <div
        class="col-span-2 pt-4 mt-4 border-t border-primary border-opacity-10"
      >
        <app-button
          :disabled="meta.valid"
          left-icon="save"
          class="w-auto ml-auto yellow"
          mini
          >Salvar</app-button
        >
      </div>
    </form>
  </template-dashboard>
</template>

<script lang="ts">
import TemplateDashboard from "@/components/TemplateDashboard.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";
import AppButton from "@/components/AppButton.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import AppSelect from "@/components/AppSelect.vue";
import personSchema from "@/utils/validations/yup/person";
import { useForm } from "vee-validate";
import {
  cnpjMask,
  dddMask,
  phoneCellphoneMask,
  zipCodeMask,
} from "@/utils/validations/mask";
import useAddress from "@/utils/composables/address";
import useContactTypes from "@/utils/composables/contact_type";
import { SecretaryCompanyRepository } from "@/api/Repositories";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default {
  components: {
    TemplateDashboard,
    DashboardTitle,
    AppButton,
    SimpleTextField,
    AppSelect,
  },
  setup() {
    const router = useRouter();
    const toast = useToast();
    const { handleSubmit, values, errors, meta, setFieldValue } = useForm({
      validationSchema: personSchema as any,
    });

    const address = useAddress({
      setFieldValue,
      prefix: "pessoa.enderecos[0]",
    });

    const contactTypes = useContactTypes();

    const onSubmit = handleSubmit(async (values) => {
      SecretaryCompanyRepository.create(values)
        .then(() => {
          router.push("/secretaria/cursos");
          toast.success("Empresa criada com sucesso!");
        })
        .catch(({ response }) => {
          const { data } = response;
          const errors = Object.values(data.data)
            .map((e) => (e as string[]).join(", "))
            .join(", ");

          toast.error(errors);
        });
    });

    return {
      onSubmit,
      cnpjMask,
      dddMask,
      phoneCellphoneMask,
      zipCodeMask,
      values,
      errors,
      meta,
      ...address,
      ...contactTypes,
    };
  },
};
</script>

<style scoped></style>
