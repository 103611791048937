import { isDate, parse } from "date-fns";

export const parseDateString = (value: string, originalValue: Date | string) => {
  if (typeof originalValue == "string" && !originalValue.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
    return false;
  }

  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue as string, "dd/MM/yyyy", new Date());

  return parsedDate;
};
