
import { useForm } from "vee-validate";

import TemplateDashboard from "../components/TemplateDashboard.vue";
import DashboardTitle from "../components/DashboardTitle.vue";
import AppButton from "../components/AppButton.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import DashboardUploadCard from "@/components/DashboardUploadCard.vue";
import AppSelect from "@/components/AppSelect.vue";
import TemplateInformationCard from "@/components/TemplateInformationCard.vue";
import RadioButton from "../components/RadioButton.vue";
import AppDropdown from "../components/AppDropdown.vue";
import AppDropdownItem from "../components/AppDropdownItem.vue";
import useEditAddress from "@/utils/composables/editAddress";
import useEditContact from "@/utils/composables/editContact";
import ResponsablePersonModal from "@/components/ResponsablePersonModal.vue";
import { onMounted, ref } from "vue";
import { cpfMask, dateMask } from "@/utils/validations/mask";
import studentSchema from "@/utils/validations/yup/student";
import { AnyObjectSchema } from "yup";
import { AcademicStudentRepository, FileRepository } from "@/api/Repositories";
import { useRoute } from "vue-router";

export default {
  components: {
    TemplateDashboard,
    DashboardTitle,
    AppButton,
    SimpleTextField,
    DashboardUploadCard,
    AppSelect,
    TemplateInformationCard,
    RadioButton,
    ResponsablePersonModal,
    AppDropdown,
    AppDropdownItem
  },
  setup() {
    const route = useRoute();
    const { id: studentId } = route.params;
    const student = ref({} as Person);

    const { setFieldValue, setValues, values, errors, handleSubmit } = useForm({
      validationSchema: studentSchema as AnyObjectSchema
    });
    const responsablePersons = ref<ResponsablePerson[]>([]);

    const editAddress = useEditAddress({
      formPrefix: "enderecos",
      prefix: "endereco_tmp",
      setFieldValue,
      values
    });

    const editContact = useEditContact({
      formPrefix: "contatos",
      prefix: "contato_tmp",
      setFieldValue,
      values
    });

    const rolesOptions: SelectOptionType[] = [
      { value: "ALUNO", label: "Aluno" },
      { value: "RESPONSÁVEL", label: "Responsável" }
    ];

    const responsablePersonModalOpen = ref(false);

    const responsablePersonHeaders = ["Descrição", "Tipo", "Contato", "Email", "DDD", "Telefone"];

    const getResponsablePerson = (responsable: ResponsablePerson) => {
      responsablePersons.value.push(responsable);
    };

    const responsablePersonTypes = ref<RelationshipType[]>([]);

    const removeResponsablePerson = (idx: number) => {
      responsablePersons.value.splice(idx, 1);
    };

    onMounted(async () => {

      responsablePersonTypes.value = await AcademicStudentRepository.getRelationshipTypes();

      student.value = await AcademicStudentRepository.get(String(studentId));
      responsablePersons.value = student.value.pessoas_relacionamento?.map((p) => {
        return ({ ...p.pessoa_relacionada, tipo_relacionamento_id: p.pessoa_relacionada_id.toString() })
      })
      setValues({
        ...student.value,
        roles: student.value.roles.map((r) => r.codigo),
      });

      setFieldValue(
        'endereco_principal',
        student.value.enderecos.findIndex(e => !!e.principal)
      );

      setFieldValue(
        'contato_principal',
        student.value.contatos.findIndex(c => !!c.principal)
      );

      setFieldValue(
        'contato_principal',
        student.value.contatos.findIndex(c => !!c.principal)
      );
    });

    const onSubmit = handleSubmit(async data => {
      console.log(data);

      const photo = data.photo[0]
        ? (await FileRepository.savePhoto({ file: data.photo[0] })).id
        : "";

      const payload = { id: null, imagem_id: photo, documentos: [], formulario: [], ...data };

      AcademicStudentRepository.create(payload).then(response => {
        console.log(response);
      });
    });

    return {
      ...editAddress,
      ...editContact,
      values,
      responsablePersonModalOpen,
      rolesOptions,
      getResponsablePerson,
      responsablePersonHeaders,
      responsablePersons,
      responsablePersonTypes,
      cpfMask,
      dateMask,
      onSubmit,
      errors,
      removeResponsablePerson
    };
  }
};
