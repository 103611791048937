<template>
  <div class="template" @keydown.ctrl="onPressSearchKeys" :tabindex="-1">
    <dashboard-navbar
      @close-input="closeInput"
      :showInput="showInput"
      @open-input="openInput"
      @toggle-drawer="toggleDrawer"
    />
    <dashboard-drawer :showDrawer="showDrawer" />
    <main class="template-content">
      <div class="px-4 md:px-20 py-6">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from "vue";
import DashboardDrawer from "./DashboardDrawer.vue";
import DashboardNavbar from "./DashboardNavbar.vue";

export default {
  components: { DashboardNavbar, DashboardDrawer },
  name: "TemplateDashboard",
  setup() {
    const showInput = ref(false);
    const showDrawer = ref(false);

    const toggleDrawer = () => {
      showDrawer.value = !showDrawer.value;
      console.log(showDrawer.value);
    };

    const openInput = () => {
      showInput.value = true;
    };

    const onPressSearchKeys = (event) => {
      if (event.code === "KeyB") {
        event.preventDefault();
        openInput();
      }
    };

    const closeInput = () => {
      showInput.value = false;
    };

    return {
      openInput,
      showInput,
      showDrawer,
      closeInput,
      onPressSearchKeys,
      toggleDrawer,
    };
  },
};
</script>

<style lang="scss">
.dashboard-title {
  color: $primary-color;
  font-size: 2rem;
  font-weight: 600;
}
</style>

<style lang="scss" scoped>
.template {
  background-color: $bg-color;
  @apply w-full h-full;
}

.template-content {
  background-color: $bg-color;
  @apply lg:pl-72 pt-20 md:pt-header;
}
</style>
