<template>
  <template-login>
    <div class="form-container sign-in-container">
      <h1 class="template-title">Olá! Bem vindo(a) ao Education1</h1>
      <h2 class="template-text">Para acessar, digite seu login e sua senha</h2>
      <form action="#" class="form" @submit="onSubmit">
        <simple-text-field class="row" name="user" placeholder="RM, e-mail ou CPF" v-model="user" />
        <simple-text-field
          class="row"
          name="password"
          type="password"
          placeholder="Senha"
          v-model="password"
        />
        <a href="/esqueci-senha" class="forgot-password">Esqueci minha senha</a>
        <app-button class="button-margin primary">Entrar</app-button>
        <app-button class="button-margin white-primary" @click="$router.push('/cadastrar')">
          Primeiro Acesso
        </app-button>
      </form>
    </div>
  </template-login>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TemplateLogin from "@/components/TemplateLogin.vue";
import AppButton from "@/components/AppButton.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import { useForm } from "vee-validate";

import * as yup from "yup";
import { UserRepository } from "@/api/Repositories";
import { setAuth } from "@/api/AxiosClient";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "Login",
  components: {
    TemplateLogin,
    AppButton,
    SimpleTextField
  },
  setup() {
    const toast = useToast();

    const { handleSubmit } = useForm<Login>({
      validationSchema: yup.object().shape({
        user: yup
          .string()
          .required()
          .label("Usúario"),
        password: yup
          .string()
          .required()
          .label("Senha")
      }) as any
    });

    const router = useRouter();

    const onSubmit = handleSubmit(data => {
      UserRepository.login(data)
        .then(({ data }) => {
          setAuth(data.data);

          router.push("/");
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
        });
    });
    return { onSubmit };
  }
});
</script>

<style lang="scss" scoped>
form.form {
  margin-top: $default-gap;
}

.forgot-password {
  margin-top: $default-gap;
  color: $primary-color;
  text-decoration: none;
  font-weight: 600;
  display: block;
  text-align: center;
}

.button-margin {
  margin-top: 1rem;
}

@media screen and (max-width: $lg) {
  form {
    padding: 0 20px;
  }
}
</style>
