
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";
import AppButton from "@/components/AppButton.vue";
import AppDropdown from "@/components/AppDropdown.vue";
import AppDropdownItem from "@/components/AppDropdownItem.vue";
import AppSelect from "@/components/AppSelect.vue";
import RadioButton from "@/components/RadioButton.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import TemplateInformationCard from "@/components/TemplateInformationCard.vue";
import { SecretaryCompanyRepository } from "@/api/Repositories";
import { useForm } from "vee-validate";
import { cnpjMask } from "@/utils/validations/mask";
import useEditAddress from "@/utils/composables/editAddress";
import useEditContact from "@/utils/composables/editContact";
import { useToast } from "vue-toastification";

export default {
  components: {
    AppButton,
    AppDropdown,
    AppDropdownItem,
    AppSelect,
    TemplateDashboard,
    DashboardTitle,
    RadioButton,
    SimpleTextField,
    TemplateInformationCard
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const allowEdit = ref(false);
    const company = ref({} as Company);

    const { setValues, handleSubmit, setFieldValue, values } = useForm();
    const { id: company_id } = route.params;

    const editAddress = useEditAddress({
      formPrefix: "pessoa.enderecos",
      prefix: "endereco_tmp",
      setFieldValue,
      values
    });

    const editContact = useEditContact({
      formPrefix: "pessoa.contatos",
      prefix: "contato_tmp",
      setFieldValue,
      values
    });

    onMounted(async () => {
      company.value = await SecretaryCompanyRepository.get(Number(company_id));
      setValues({
        ...company.value
      });

      setFieldValue(
        "pessoa.endereco_principal",
        company.value.pessoa.enderecos.findIndex(e => !!e.principal)
      );

      setFieldValue(
        "pessoa.contato_principal",
        company.value.pessoa.contatos.findIndex(c => !!c.principal)
      );
    });

    const onSubmit = handleSubmit(values => {
      SecretaryCompanyRepository.edit(Number(company_id), values)
        .then(() => {
          router.push("/secretaria/empresas");
          toast.success("Empresa atualizada com sucesso!");
        })
        .catch(({ response }) => {
          const { data } = response;
          const errors = Object.values(data.data)
            .map(e => (e as string[]).join(", "))
            .join(", ");

          toast.error(errors);
        });
    });

    return {
      ...editAddress,
      ...editContact,
      allowEdit,
      company,
      onSubmit,
      cnpjMask,
      values
    };
  }
};
