
import { defineComponent, onMounted, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppButton from "@/components/AppButton.vue";
import RadioButton from "@/components/RadioButton.vue";
import { FileRepository, SecretaryUnitRepository } from "@/api/Repositories";
import SimpleTextField from "@/components/SimpleTextField.vue";
import TemplateSecretaryFilters from "@/components/TemplateSecretaryFilters.vue";
import DashboardUploadCard from "@/components/DashboardUploadCard.vue";
import TemplateInformationCard from "@/components/TemplateInformationCard.vue";
import useEditAddress from "@/utils/composables/editAddress";
import useEditContact from "@/utils/composables/editContact";
import { useForm } from "vee-validate";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import schema from "@/utils/validations/unity";

export default defineComponent({
  components: {
    TemplateDashboard,
    AppSelect,
    AppButton,
    RadioButton,

    TemplateSecretaryFilters,
    SimpleTextField,
    DashboardUploadCard,
    TemplateInformationCard
  },
  name: "SecretaryUnitsView",
  setup() {
    const unit = ref({} as Unity);
    const allowEdit = ref(false);

    const router = useRouter();
    const route = useRoute();

    const toast = useToast();

    const { setFieldValue, values, setValues, handleSubmit } = useForm({
      validationSchema: schema as any
    });

    const editAddress = useEditAddress({
      formPrefix: "enderecos",
      prefix: "endereco_tmp",
      setFieldValue,
      values
    });

    const editContact = useEditContact({
      formPrefix: "contatos",
      prefix: "contato_tmp",
      setFieldValue,
      values
    });

    const { id: unitId } = route.params;

    onMounted(async () => {
      unit.value = await SecretaryUnitRepository.get(unitId as string);

      setValues({
        ...unit.value,
        contato_principal: unit.value.contatos.findIndex(c => !!c.principal),
        endereco_principal: unit.value.enderecos.findIndex(e => !!e.principal)
      });
    });

    const onSubmit = handleSubmit(async data => {
      if (data.logotipo[0] instanceof File) {
        const logo = await FileRepository.savePhoto({ file: data.logotipo[0] });
        data.logotipo = logo.url;
      }

      if (data.assinatura[0] instanceof File) {
        const signature = await FileRepository.saveFile({
          file: data.assinatura[0]
        });
        data.assinatura = signature.url;
      }

      SecretaryUnitRepository.edit(unitId as string, data as Unity)
        .then(() => {
          router.push("/secretaria/unidades");
          toast.success("Unidade atualizada com sucesso!");
        })
        .catch(({ response }) => {
          const { data } = response;
          const errors = Object.values(data.data)
            .map(e => (e as string[]).join(", "))
            .join(", ");

          toast.error(errors);
        });
    });

    return {
      ...editAddress,
      ...editContact,
      unit,
      allowEdit,
      values,
      onSubmit
    };
  }
});
