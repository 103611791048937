import Client from "../AxiosClient";

export default {
  async getAll(): Promise<Serie[]> {
    const { data } = await Client.get("/e1-secretaria-api/public/api/treinamentos/series");
    return data.data.data;
  },

  async getSerieByCourse(id: number): Promise<Serie[]> {
    const { data } = await Client.get(`/e1-secretaria-api/public/api/treinamentos/series?curso_id=${id}`);
    return data.data.data;
  },

  async getSerieById(id: number): Promise<Serie> {
    const { data } = await Client.get(`/e1-secretaria-api/public/api/treinamentos/series/${id}`);
    return data.data;
  },

  async create(payload: Record<string, unknown>): Promise<Serie> {
    const { data } = await Client.post('/e1-secretaria-api/public/api/treinamentos/series', payload);
    return data.data;
  },

  async update(id: number, payload: Record<string, unknown>): Promise<Serie> {
    const { data } = await Client.put(`/e1-secretaria-api/public/api/treinamentos/series/${id}`, payload);
    return data.data;
  },

  async delete(id: number) {
    const { data } = await Client.delete(`/e1-secretaria-api/public/api/treinamentos/series/${id}`);
    return data.data;
  },
};
