
import { defineComponent, onMounted, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppButton from "@/components/AppButton.vue";
import {
  CourseRepository,
  SecretarySeriesRepository,
} from "@/api/Repositories";
import TemplateSecretaryFilters from "@/components/TemplateSecretaryFilters.vue";
import { useRoute, useRouter } from "vue-router";
import SimpleTextField from "@/components/SimpleTextField.vue";
import schema from "@/utils/validations/serie";
import { useForm } from "vee-validate";
import historicOptions from "@/utils/mocks/historic";
import useCourseType from "@/utils/composables/course_type";
import useSeriesType from "@/utils/composables/series_type";
import DashboardTitle from "@/components/DashboardTitle.vue";

export default defineComponent({
  components: {
    TemplateDashboard,
    AppSelect,
    AppButton,
    TemplateSecretaryFilters,
    SimpleTextField,
    DashboardTitle,
  },
  name: "SecretaryUnits",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const courseOptions = useCourseType();
    const editId = ref();
    const { getSeriesByCourse, seriesOptions } = useSeriesType();

    const {
      handleSubmit,
      meta: formMeta,
      setValues,
    } = useForm({
      validationSchema: schema,
    });

    onMounted(async () => {
      editId.value = Number(route.params.id);

      if (!editId.value) return;

      const formValues = await SecretarySeriesRepository.getSerieById(
        editId.value
      );

      await getSeriesByCourse(formValues.curso_id);

      setValues({
        descricao: formValues.descricao,
        curso_id: formValues.curso_id,
        ordem: formValues.ordem,
        proxima_serie_id: formValues.proxima_serie_id,
        codserie_historico: formValues.codserie_historico,
        proxima_serie_curso: formValues.proxima_serie.curso_id,
      });
    });

    const onSubmit = handleSubmit((values) => {
      if (!editId.value) {
        SecretarySeriesRepository.create(values).then(() => {
          router.push("/secretaria/series");
        });

        return;
      }

      SecretarySeriesRepository.update(editId.value, values).then(() => {
        router.push("/secretaria/series");
      });
    });

    const back = () => {
      router.back();
    };

    return {
      back,
      formMeta,
      historicOptions,
      ...courseOptions,
      getSeriesByCourse,
      seriesOptions,
      onSubmit,
    };
  },
});
