<template>
  <div class="wrapper">
    <main class="main-content">
      <section class="container">
        <slot />
      </section>
    </main>
    <aside class="aside">
      <img class="logo" src="@/assets/logo_ed1.png" />
    </aside>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton.vue";
export default {
  name: "TemplateLogin",
  components: { AppButton }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
}

.main-content {
  & {
    flex: 1;
    background-color: #f2f2f2;
    height: 100%;
    padding: 1rem;
  }

  & .container {
    margin: 0 auto;
    max-width: 25rem;
    width: 100%;
  }

  & .template-title {
    color: $primary-color;
    font-weight: bold;
    font-size: 2rem;
    line-height: 3rem;
    margin-top: 0.5rem;
  }

  & .template-text {
    font-weight: normal;
    color: transparentize($color: #000000, $amount: 0.5);
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}

.aside {
  & {
    padding: 5.625rem 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    background: url("../assets/bg_ed1.png") center bottom / cover no-repeat #e9e7ea;
  }

  & .logo {
    max-width: 18rem;
    width: 100%;
  }
}

@media (min-width: $sm) {
  .main-content {
    padding: 2rem;
  }
}

@media (min-width: $md) {
  .wrapper {
    flex-direction: row;
  }
  .main-content {
    padding: 10rem 4rem;
    margin-right: 25rem;
  }

  .aside {
    position: fixed;
    right: 0;
    width: 100%;
    height: 100vh;
    max-width: 25rem;

    & .logo {
      max-width: 13rem;
      padding: 0 2rem;
    }
  }
}

@media (min-width: $xxl) {
  .main-content {
    padding: 13.75rem 10rem;
  }

  .main-content {
    margin-right: 45rem;
  }

  .aside {
    max-width: 45rem;
  }
}
</style>

<style lang="scss">
.main-content {
  & .template-title {
    color: $primary-color;
    font-weight: bold;
    font-size: 2rem;
    line-height: 3rem;
    margin-top: 0.5rem;
  }

  & .template-text {
    font-weight: normal;
    color: transparentize($color: #000000, $amount: 0.5);
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}

@media (min-width: $md) {
  .main-content {
    & .template-title {
      font-size: 2.25rem;
      line-height: 3.375rem;
    }
  }
}
</style>
