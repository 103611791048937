import { boolean, number, object, string } from "yup";
import { zipCode } from "../regex";

const addressSchema = object().shape({
  principal: boolean()
    .required()
    .label("Principal"),
  id: number()
    .positive()
    .nullable()
    .label("ID"),
  cidade_id: number()
    .positive()
    .required()
    .label("Cidade"),
  cep: string()
    .matches(zipCode)
    .required()
    .label("CEP"),
  logradouro: string()
    .required()
    .label("Logradouro"),
  numero: number()
    .required()
    .label("Número"),
  complemento: string().label("Complemento"),
  bairro: string()
    .required()
    .label("Bairro"),
  uf: string()
    .notRequired()
    .label("Estado")
});

export default addressSchema;
