export const phoneCellphone = /^(?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/;

export const cpf = /\d{3}\.\d{3}\.\d{3}-\d{2}$/;

export const cnpj = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

export const zipCode = /^\d{5}-\d{3}/;

export const imageFileName = /^.*[/]/;

export const removeSpace = /\s\s+/g;
