<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { POSITION, provideToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

export default defineComponent({
  setup() {
    provideToast({ timeout: 3000, position: POSITION.BOTTOM_RIGHT });
  }
});
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

.material-icons {
  user-select: none;
}

* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html {
  scroll-behavior: smooth;
}

html,
body,
#app {
  height: 100%;
}
</style>
