import * as yup from "yup";

export default yup.object({
  id: yup.number().label('Id'),
  descricao: yup.string().required().label('Descrição'),
  periodo_id: yup.number().required().label('Período'),
  sala_id: yup.number().label('Sala'),
  ano: yup.number().required().label('Ano Letivo'),
  situacao_id: yup.number().required().label('Situação'),
  curso_id: yup.number().required().label('Segmento'),
  serie_id: yup.number().required().label('Série'),
  conta_id: yup.number().label('Conta'),
  unidade_id: yup.number().required().label('Unidade'),
  empresa_id: yup.number().required().label('Empresa'),
  vagas: yup.number().required().label('Vagas'),
  ordem: yup.string().required().label('Ordem'),
  data_inicio: yup.string().required().label('Data Inicio'),
  data_termino: yup.string().required().label('Data Termino'),
  hora_entrada: yup.string().required().label('Hora Entrada'),
  hora_saida: yup.string().required().label('Hora saida'),
  dias_letivos: yup.number().required().label('Dias Letivos'),
  semestre: yup.number().required().label('Semestre'),
  carga_horaria: yup.number().required().label('Carga Horária'),
});
