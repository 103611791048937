<template>
  <div class="app-dropdown-item">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {}
});
</script>

<style lang="scss" scoped>
.app-dropdown-item {
  padding: 1rem 1.5rem;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 500;
  color: $gray800-color;
  border-bottom: 1px solid transparentize($color: $primary-color, $amount: 0.88);

  &:hover {
    cursor: pointer;
    background-color: $gray100-color;
  }

  &:last-child {
    border-bottom: none;
  }
}
</style>
