<template>
  <template-dashboard>
    <template-secretary-filters>
      <template #filters>
        <app-select label="Unidade" class="select-units" />
        <app-select label="Ano" class="select-years" />
      </template>
    </template-secretary-filters>
    <dashboard-title title="Unidades">
      <div>
        <app-button
          @click="$router.push('/secretaria/unidades/inserir')"
          left-icon="add"
          class="w-auto yellow"
          mini
          >Inserir</app-button
        >
      </div>
    </dashboard-title>
    <main v-if="!loading">
      <p
        v-if="units.length === 0"
        class="mt-4 text-2xl font-semibold text-primary"
      >
        Nenhuma unidade cadastrada
      </p>
      <div class="grid gap-4 mt-4 md:grid-cols-2 lg:grid-cols-4">
        <transition-group v-if="units" name="fade" mode="out-in">
          <dashboard-item-card
            v-for="unit of units"
            :key="unit.id"
            :item="unit"
          >
            <template #dropdown>
              <app-dropdown-item @click="editUnit(unit.id)"
                >Editar unidade</app-dropdown-item
              >
              <app-dropdown-item @click="deleteUnit(unit.id)"
                >Excluir unidade</app-dropdown-item
              >
            </template>
          </dashboard-item-card>
        </transition-group>
      </div>
    </main>
  </template-dashboard>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppButton from "@/components/AppButton.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";
import DashboardItemCard from "@/components/DashboardItemCard.vue";
import { SecretaryUnitRepository } from "@/api/Repositories";
import AppDropdownItem from "@/components/AppDropdownItem.vue";
import TemplateSecretaryFilters from "@/components/TemplateSecretaryFilters.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    TemplateDashboard,
    AppSelect,
    AppButton,
    DashboardItemCard,
    DashboardTitle,
    AppDropdownItem,
    TemplateSecretaryFilters,
  },
  name: "SecretaryUnits",
  setup() {
    const units = ref([] as Unity[]);
    const loading = ref(true);

    const router = useRouter();

    const getUnits = async () => {
      loading.value = true;
      const unitList = await SecretaryUnitRepository.list();
      units.value = unitList;
      loading.value = false;
    };

    onMounted(() => {
      getUnits();
    });

    const editUnit = (id: string) => {
      router.push(`/secretaria/unidades/${id}`);
    };

    const deleteUnit = async (id: string) => {
      await SecretaryUnitRepository.delete(id);

      getUnits();
    };

    return { units, deleteUnit, editUnit };
  },
});
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-enter-from {
  opacity: 0;
}

.select-years {
  @apply md:max-w-yearFilter w-full;
}

.select-units {
  @apply md:max-w-unityFilter w-full;
}

.title-container {
  border-bottom: 1px solid transparentize($color: $primary-color, $amount: 0.88);
  @apply flex justify-between pb-4;
}

.list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
