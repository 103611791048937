import { number, object, string } from "yup";
import { cpf, phoneCellphone } from "../regex";

const responsablePersonSchema = object({
  responsavel: object().shape({
    nome: string()
      .required()
      .label("Nome"),
    tipo_relacionamento_id: string()
      .required()
      .label("Tipo"),
    cpf: string()
      .required()
      .matches(cpf)
      .label("CPF"),
    contato: object().shape({
      email: string()
        .email()
        .required()
        .label("E-mail"),
      ddd: number()
        .min(0)
        .max(99)
        .required()
        .label("DDD"),
      telefone: string()
        .matches(phoneCellphone)
        .required()
        .label("Telefone")
    })
  })
});

export default responsablePersonSchema;
