import { UtilsRepository } from "@/api/Repositories";
import { onMounted, ref } from "vue";

type SelectOption = {
  label: string;
  value: number;
};

export default function useContactType() {
  const contactTypeOptions = ref<SelectOption[]>([]);

  onMounted(async () => {
    contactTypeOptions.value = (await UtilsRepository.listContactTypes())
      .map((c) => ({ value: c.id, label: c.descricao }));
  });

  return { contactTypeOptions }
}
