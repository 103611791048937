import { object, string, boolean, number } from 'yup';

export default object().shape({
  titulo: string().required().label('Titulo'),
  descricao: string().required().label('Descrição'),
  sigla: string().required().label('Sigla'),
  tem_serie: boolean().default(false).label('Tem serie'),
  tem_disciplina: boolean().default(false).label('Tem disciplina'),
  tipo_curso_id: number().required().label('Tipo do curso'),
  observacao: string().label('Observação'),
  ativo: boolean().default(false).label('Ativo'),
});
