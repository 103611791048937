import { boolean, number, string, object, array, mixed } from "yup";
import { zipCode } from "./regex";
import contactSchema from "./yup/contact";

const addressSchema = object().shape({
  principal: boolean()
    .nullable()
    .default(false),
  cidade_id: number()
    .positive()
    .required()
    .label("Cidade"),
  tipo_endereco_id: number()
    .nullable()
    .positive()
    .label("Tipo do endereço"),
  cep: string()
    .matches(zipCode)
    .required()
    .label("CEP"),
  logradouro: string()
    .required()
    .label("Logradouro"),
  numero: number()
    .required()
    .label("Número"),
  complemento: string()
    .nullable()
    .label("Complemento"),
  bairro: string()
    .required()
    .label("Bairro")
});

const fileSchema = mixed().required();

export default object().shape({
  nome: string()
    .required()
    .label("Nome"),
  autorizacao: string().label("Autorização"),
  logotipo: fileSchema.label("Logotipo"),

  assinatura: fileSchema.label("Assinatura"),
  enderecos: array().of(addressSchema),
  contatos: array().of(contactSchema)
});
