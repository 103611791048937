import Client from "../AxiosClient";

type FilterProps = {
  curso_id?: number;
  ano_letivo?: number;
  rm?: string;
  tipo_cadastro?: string;
  nome?: string;
  data_nascimento_inicio?: string;
  data_nascimento_fim?: string;
  documento?: string;
  uf?: string;
  cep?: string;
  cidade?: string;
};

export default {
  async getAll(payload: FilterProps = {}): Promise<Person[]> {
    const { data } = await Client.get("/e1-pessoa-api/public/api/treinamentos/pessoas", {
      params: payload
    });
    return data.data.data;
  },

  async get(id: string): Promise<Person> {
    const { data } = await Client.get(`/e1-pessoa-api/public/api/treinamentos/pessoas/${id}`);

    return data.data;
  },

  async create(payload: Record<string, any>) {
    const { data } = await Client.post("/e1-pessoa-api/public/api/treinamentos/pessoas", payload);

    return data.data;
  },

  async edit(id: string, payload: Record<string, any>) {
    const { data } = await Client.post(
      `/e1-pessoa-api/public/api/treinamentos/pessoas/${id}`,
      payload
    );

    return data.data;
  },

  async getRelationshipTypes(): Promise<RelationshipType[]> {
    const { data } = await Client.get(
      "/e1-pessoa-api/public/api/treinamentos/tipo_relacionamentos"
    );

    return data.data;
  }
};
