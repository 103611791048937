import { UtilsRepository } from "@/api/Repositories"
import { format } from "date-fns";
import { range } from "@/utils/functions/array";
import { ref, onMounted } from "vue";

export default function useYearTypes() {
  const yearOptions = ref<SelectOptionType[]>([]);

  onMounted(async () => {
    // const configs = await UtilsRepository.listConfigurations();
    // const firstYear = configs.find((c) => c.codigo === "ANO_LETIVO_INICIAL");
    const firstYear = "2019";
    const currentYear = Number(format(new Date(), 'yyyy'));

    const years = range(Number(firstYear), currentYear + 2);

    yearOptions.value = years.map((y) => ({value: `${y}`, label: `${y}`}));
  })

  return { yearOptions };
}
