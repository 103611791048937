<template>
  <template-dashboard>
    <dashboard-title :title="allowEdit ? `Editar ${company?.pessoa?.nome}` : company?.pessoa?.nome">
      <div class="flex gap-4">
        <app-button @click="$router.back" left-icon="arrow_back" class="w-auto back" mini>
          Voltar
        </app-button>
        <app-button v-if="!allowEdit" @click="allowEdit = true" mini left-icon="edit" class="yellow"
          >Editar</app-button
        >
      </div>
    </dashboard-title>
    <form @submit="onSubmit" class="flex flex-col justify-between h-full">
      <div class="grid grid-cols-2 gap-4">
        <simple-text-field
          name="pessoa.nome_social"
          placeholder="Nome Social"
          class="col-span-2"
          :readonly="!allowEdit"
        />
        <simple-text-field
          name="pessoa.nome"
          placeholder="Nome"
          class="col-span-2"
          :readonly="!allowEdit"
        />
        <simple-text-field
          name="pessoa.cnpj"
          placeholder="CNPJ"
          :readonly="!allowEdit"
          :mask="cnpjMask"
        />
      </div>

      <div class="mt-10">
        <p class="mb-6 text-2xl font-semibold text-primary">Endereços</p>
        <template-information-card
          :headers="addressHeaders"
          :class="{
            'pointer-events-none text-black text-opacity-60': !allowEdit
          }"
        >
          <tr v-for="(e, idx) in values?.pessoa?.enderecos" :key="e.id">
            <td>
              <radio-button
                :name="`pessoa.endereco_principal`"
                :value="idx"
                compact
                @change="changePrimaryAddress"
              />
            </td>
            <td>{{ e.cidade.nome }}</td>
            <td>{{ e.cidade.uf.sigla }}</td>
            <td>{{ e.logradouro }}, {{ e.numero }}, {{ e.bairro }}</td>
            <td>{{ e.cep }}</td>
            <td>
              <app-dropdown>
                <span class="material-icons card-actions-icon">more_vert</span>
                <template #content>
                  <app-dropdown-item @click="showAddressForm(idx)">
                    Editar
                  </app-dropdown-item>
                  <app-dropdown-item @click="removeAddress(idx)">Excluir</app-dropdown-item>
                </template>
              </app-dropdown>
            </td>
          </tr>
        </template-information-card>

        <app-button
          type="button"
          left-icon="add"
          mini
          class="w-auto mt-5 yellow"
          @click="showAddressForm(null)"
          :disabled="!allowEdit || addressShow"
          >Endereço</app-button
        >

        <div v-if="addressShow" class="grid grid-cols-4 gap-4 mt-4">
          <simple-text-field
            :name="`endereco_tmp.cep`"
            placeholder="CEP"
            class="col-span-2"
            :mask="zipCodeMask"
            @keyup="handleGetCep"
          />

          <div class="grid grid-cols-4 col-span-4 gap-4">
            <simple-text-field
              :name="`endereco_tmp.logradouro`"
              class="col-span-3"
              placeholder="Endereço"
            />
            <simple-text-field :name="`endereco_tmp.numero`" placeholder="Número" />
          </div>
          <simple-text-field
            :name="`endereco_tmp.bairro`"
            placeholder="Bairro"
            class="col-span-2"
          />
          <simple-text-field
            :name="`endereco_tmp.complemento`"
            placeholder="Complemento"
            class="col-span-2"
          />
          <app-select
            :name="`endereco_tmp.cidade_id`"
            class="col-span-3"
            label="Cidade"
            compact
            :options="cityOptions"
          />
          <app-select
            :name="`endereco_tmp.uf`"
            class="col-span-1"
            label="UF"
            @change="handleGetCities"
            compact
            :options="statesOptions"
          />

          <div class="flex justify-between col-span-4">
            <app-button
              type="button"
              left-icon="arrow_back"
              mini
              class="w-auto back"
              @click="clearAddress"
            >
              Cancelar
            </app-button>

            <app-button
              type="button"
              left-icon="add"
              mini
              class="w-auto yellow"
              @click="saveAddress"
            >
              Salvar
            </app-button>
          </div>
        </div>
      </div>

      <div class="mt-10">
        <p class="mb-6 text-2xl font-semibold text-primary">Contatos</p>
        <template-information-card
          :headers="contactHeaders"
          :class="{
            'pointer-events-none text-black text-opacity-60': !allowEdit
          }"
        >
          <tr v-for="(c, idx) in values?.pessoa?.contatos" :key="c.id">
            <td>
              <radio-button
                :name="`pessoa.contato_principal`"
                :value="idx"
                compact
                @change="changePrimaryContact"
              />
            </td>
            <td>{{ c.tipo_contato_id }}</td>
            <td>{{ c.nome_contato }}</td>
            <td>{{ c.email }}</td>
            <td>{{ c.ddd }}</td>
            <td>{{ c.telefone }}</td>
            <td>
              <app-dropdown>
                <span class="material-icons card-actions-icon">more_vert</span>
                <template #content>
                  <app-dropdown-item @click="showContactForm(idx)">
                    Editar
                  </app-dropdown-item>
                  <app-dropdown-item @click="removeContact(idx)">Excluir</app-dropdown-item>
                </template>
              </app-dropdown>
            </td>
          </tr>
        </template-information-card>

        <app-button
          type="button"
          left-icon="add"
          mini
          class="w-auto mt-5 yellow"
          @click="showContactForm(null)"
          :disabled="!allowEdit || contactShow"
        >
          Contato
        </app-button>

        <div v-if="contactShow" class="grid grid-cols-4 gap-4 mt-4">
          <simple-text-field
            :name="`contato_tmp.nome_contato`"
            class="col-span-2"
            placeholder="Contato"
          />

          <app-select
            :name="`contato_tmp.tipo_contato_id`"
            label="Tipo"
            class="col-span-2"
            :options="contactTypeOptions"
          />

          <simple-text-field :name="`contato_tmp.email`" class="col-span-3" placeholder="Email" />

          <simple-text-field
            :name="`contato_tmp.ddd`"
            class="col-span-1"
            placeholder="DDD"
            :mask="dddMask"
          />

          <simple-text-field
            :name="`contato_tmp.telefone`"
            class="col-span-2"
            placeholder="Telefone"
            :mask="phoneCellphoneMask"
          />

          <div class="flex justify-between col-span-4">
            <app-button
              type="button"
              left-icon="arrow_back"
              mini
              class="w-auto back"
              @click="clearContact"
            >
              Cancelar
            </app-button>

            <app-button
              type="button"
              left-icon="add"
              mini
              class="w-auto yellow"
              @click="saveContact"
            >
              Salvar
            </app-button>
          </div>
        </div>
      </div>
      <div class="col-span-2 pt-4 mt-4 border-t border-primary border-opacity-10">
        <app-button
          type="submit"
          mini
          left-icon="save"
          class="w-auto ml-auto yellow"
          :disabled="!allowEdit"
        >
          Salvar
        </app-button>
      </div>
    </form>
  </template-dashboard>
</template>

<script lang="ts">
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";
import AppButton from "@/components/AppButton.vue";
import AppDropdown from "@/components/AppDropdown.vue";
import AppDropdownItem from "@/components/AppDropdownItem.vue";
import AppSelect from "@/components/AppSelect.vue";
import RadioButton from "@/components/RadioButton.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import TemplateInformationCard from "@/components/TemplateInformationCard.vue";
import { SecretaryCompanyRepository } from "@/api/Repositories";
import { useForm } from "vee-validate";
import { cnpjMask } from "@/utils/validations/mask";
import useEditAddress from "@/utils/composables/editAddress";
import useEditContact from "@/utils/composables/editContact";
import { useToast } from "vue-toastification";

export default {
  components: {
    AppButton,
    AppDropdown,
    AppDropdownItem,
    AppSelect,
    TemplateDashboard,
    DashboardTitle,
    RadioButton,
    SimpleTextField,
    TemplateInformationCard
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const allowEdit = ref(false);
    const company = ref({} as Company);

    const { setValues, handleSubmit, setFieldValue, values } = useForm();
    const { id: company_id } = route.params;

    const editAddress = useEditAddress({
      formPrefix: "pessoa.enderecos",
      prefix: "endereco_tmp",
      setFieldValue,
      values
    });

    const editContact = useEditContact({
      formPrefix: "pessoa.contatos",
      prefix: "contato_tmp",
      setFieldValue,
      values
    });

    onMounted(async () => {
      company.value = await SecretaryCompanyRepository.get(Number(company_id));
      setValues({
        ...company.value
      });

      setFieldValue(
        "pessoa.endereco_principal",
        company.value.pessoa.enderecos.findIndex(e => !!e.principal)
      );

      setFieldValue(
        "pessoa.contato_principal",
        company.value.pessoa.contatos.findIndex(c => !!c.principal)
      );
    });

    const onSubmit = handleSubmit(values => {
      SecretaryCompanyRepository.edit(Number(company_id), values)
        .then(() => {
          router.push("/secretaria/empresas");
          toast.success("Empresa atualizada com sucesso!");
        })
        .catch(({ response }) => {
          const { data } = response;
          const errors = Object.values(data.data)
            .map(e => (e as string[]).join(", "))
            .join(", ");

          toast.error(errors);
        });
    });

    return {
      ...editAddress,
      ...editContact,
      allowEdit,
      company,
      onSubmit,
      cnpjMask,
      values
    };
  }
};
</script>
