<template>
  <template-dashboard>
    <template-secretary-filters>
      <template #filters>
        <app-select label="Unidade" class="select-units" />
        <app-select label="Ano" class="select-years" />
      </template>
    </template-secretary-filters>
    <dashboard-title title="Séries">
      <app-button
        class="w-auto yellow"
        mini
        leftIcon="add"
        @click="$router.push('/secretaria/series/cadastro')"
        >Inserir</app-button
      >
    </dashboard-title>
    <div class="grid mt-4 mb-8 md:grid-cols-2">
      <app-select
        label="Selecione"
        :options="courseOptions"
        @change="handleGetSeries"
      />
    </div>
    <h1 class="text-2xl dashboard-title">Educação Infantil</h1>
    <div class="w-full mt-6" v-if="series.length > 0">
      <template-information-card :headers="['Série', 'Próximo']">
        <tr class="px-4 border-gray-100" v-for="s in series" :key="s.id">
          <td v-if="s.descricao" class="py-4 table-text">
            {{ s.descricao }}
          </td>
          <td v-else>-</td>
          <td v-if="s.proxima_serie" class="py-4 table-text">
            {{ s.proxima_serie?.descricao }}
          </td>
          <td v-else>-</td>
          <td class="py-4 text-right">
            <app-dropdown>
              <span class="cursor-pointer material-icons card-actions-icon"
                >more_vert</span
              >
              <template #content>
                <app-dropdown-item @click="handleEdit(s.id)"
                  >Editar unidade</app-dropdown-item
                >
                <app-dropdown-item @click="handleDelete(s.id, s.curso_id)"
                  >Excluir unidade</app-dropdown-item
                >
              </template>
            </app-dropdown>
          </td>
        </tr>
      </template-information-card>
    </div>
  </template-dashboard>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppButton from "@/components/AppButton.vue";
import {
  CourseRepository,
  SecretarySeriesRepository,
} from "@/api/Repositories";
import TemplateSecretaryFilters from "@/components/TemplateSecretaryFilters.vue";
import TemplateInformationCard from "@/components/TemplateInformationCard.vue";
import { useRouter } from "vue-router";
import AppDropdown from "@/components/AppDropdown.vue";
import AppDropdownItem from "@/components/AppDropdownItem.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";

export default defineComponent({
  components: {
    TemplateDashboard,
    AppSelect,
    AppButton,
    TemplateSecretaryFilters,
    TemplateInformationCard,
    AppDropdown,
    AppDropdownItem,
    DashboardTitle,
  },
  name: "SecretaryUnits",
  setup() {
    const courseOptions = ref([] as SelectOptionType[]);
    const series = ref([] as Serie[]);
    const router = useRouter();

    onMounted(async () => {
      courseOptions.value = (await CourseRepository.getAll()).map((s) => ({
        label: s.descricao,
        value: s.id,
      }));
    });

    const handleGetSeries = async (value: string) => {
      series.value = await SecretarySeriesRepository.getSerieByCourse(
        Number(value)
      );
    };

    const handleEdit = (serieId: string) => {
      router.push(`/secretaria/series/${serieId}`);
    };

    const handleDelete = async (serieId: string, cursoId: string) => {
      await SecretarySeriesRepository.delete(Number(serieId));
      series.value = series.value.filter((s: Serie) => {
        return s.id !== Number(serieId);
      });

      handleGetSeries(cursoId);
    };

    const navigate = (route: string) => {
      router.push(route);
    };

    return {
      courseOptions,
      navigate,
      handleGetSeries,
      series,
      handleEdit,
      handleDelete,
    };
  },
});
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-enter-from {
  opacity: 0;
}

.title-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid transparentize($color: $primary-color, $amount: 0.88);
}

.list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.border-gray-100 {
  border: 1px solid $gray100-color;
}

.table-header {
  color: transparentize($color: $black-color, $amount: 0.38);
}

.table-text {
  color: transparentize($color: $black-color, $amount: 0.6);
}
</style>
