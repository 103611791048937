
import { defineComponent } from "vue";
import { maska } from "maska";
import ErrorMessage from "./ErrorMessage.vue";

export default defineComponent({
  components: { ErrorMessage },
  name: "TextField",
  props: {
    name: {
      type: String,
      default: ""
    },
    modelValue: {},
    type: String,
    label: String,
    placeholder: String,
    required: String,
    readonly: String,
    rightIcon: String,
    error: {},
    mask: {}
  },
  data() {
    return {
      inputType: this.type
    };
  },
  methods: {
    switchVisibility() {
      this.inputType = this.inputType === "password" ? "text" : "password";
    }
  },

  directives: {
    maska
  }
});
