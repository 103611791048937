<template>
  <error-message :error="errorMessage">
    <div
      class="w-full overflow-hidden rounded-xl b-gray"
      :class="[{ 'h-full flex flex-col': fullHeight }]"
    >
      <div
        class="relative flex items-center justify-center py-16 overflow-hidden gray min-height-image"
        :class="[{ 'flex-1': fullHeight }]"
      >
        <img v-show="imageUrl" :src="imageUrl" class="absolute object-cover w-full h-full" />
      </div>
      <div class="px-4 pt-4 pb-5 bg-white min-height-label">
        <p class="text-xs color-gray">{{ label }}</p>
        <p v-show="fileName" class="mt-2 text-sm color-gray-600">
          {{ fileName }}
        </p>
        <div class="flex justify-between mt-4">
          <div>
            <button
              type="button"
              @click="handleButtonClick"
              class="font-bold outline-none text-primary"
              :class="{ 'color-gray-600': imageUrl }"
            >
              UPLOAD
            </button>
            <input
              type="file"
              ref="inputButton"
              @change="setSelectedFile"
              accept="image/png, image/jpeg"
              :disabled="disabled"
              class="hidden"
            />
          </div>
          <button type="button">
            <span class="material-icons icon color-gray-600">more_vert</span>
          </button>
        </div>
      </div>
    </div>
  </error-message>
</template>

<script lang="ts">
import { useField } from "vee-validate";
import { defineComponent, onMounted, ref, watch } from "vue";

import ErrorMessage from "@/components/ErrorMessage.vue";

export default defineComponent({
  name: "DashboardUploadCard",
  components: { ErrorMessage },
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true,
      default: ""
    },
    initialValue: {
      type: String,
      default: ""
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {
    const inputButton = ref<HTMLInputElement | null>(null);

    const selectedFile = ref();
    const imageUrl = ref("");
    const fileName = ref("");

    const { handleChange, value: inputValue, errorMessage } = useField(props.name, undefined);

    onMounted(() => {
      if (inputButton.value?.files) {
        selectedFile.value = inputButton.value?.files[0];
      }
    });

    watch(
      () => props.initialValue,
      () => {
        imageUrl.value = props.initialValue;
      }
    );

    const handleButtonClick = () => {
      if(props.disabled) return;

      inputButton.value && inputButton.value.click();
    };

    const setSelectedFile = (e: Event) => {
      if (!e.target) return;

      const target = (e.target ?? {}) as HTMLInputElement;
      if (target && target.files) {
        selectedFile.value = target!.files![0];
        handleChange(e);
      }
    };

    watch(selectedFile, () => {
      if (selectedFile.value) {
        const reader = new FileReader();

        reader.onload = e => {
          if (!e.target) return;
          imageUrl.value = e.target.result as string;
        };

        reader.readAsDataURL(selectedFile.value);
        fileName.value = selectedFile.value.name;
      }
    });

    return {
      setSelectedFile,
      handleButtonClick,
      imageUrl,
      fileName,
      inputButton,
      errorMessage
    };
  }
});
</script>

<style lang="scss" scoped>
.gray {
  background-color: $gray100-color;
}

.color-gray-300 {
  color: $gray300-color;
}

.color-gray-600 {
  color: $gray600-color;
}

.min-height-image {
  max-height: 160px;
  height: 100%;
  width: 100%;
}

.min-height-label {
  min-height: 112px;
  width: 100%;
}

.b-gray {
  border: 1px solid $gray100-color;
}
</style>
