<template>
  <tippy
    trigger="click"
    placement="bottom-start"
    animation="fade"
    :duration="200"
    :interactive="true"
    ref="dropdown"
  >
    <slot />

    <template #content>
      <div class="app-dropdown" @click="handleHide">
        <slot name="content"></slot>
      </div>
    </template>
  </tippy>
</template>

<script>
import { defineComponent } from "vue";
import { Tippy } from "vue-tippy";

export default defineComponent({
  components: { Tippy },
  methods: {
    handleHide() {
      this.$refs.dropdown.hide();
    }
  }
});
</script>

<style lang="scss">
.app-dropdown {
  background-color: $white-color;
  border-radius: 0.25rem;
  width: 15rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
