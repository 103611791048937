
import { defineComponent, ref, PropType } from "vue";
import { useRouter } from "vue-router";
  export default defineComponent({
    name: 'UserDrawer',
    props: {
      title: {
        type: String,
        default: ''
      },
      subList: {
        type: Array as PropType<string[]>,
        default: []
      },
    },
    setup() {
      const isOpen = ref(false);
      const router = useRouter();

      const toggleList = () => {
        isOpen.value = !isOpen.value;
      }

      const logOut = () => {
        localStorage.removeItem('authHeaders');
        router.push('/login');
      }

      return { isOpen, toggleList, logOut }
    }
  });
