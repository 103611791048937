
import { ref, watch } from "vue"
import TemplateDashboard from "@/components/TemplateDashboard.vue";
import DashboardTitle from "../components/DashboardTitle.vue";
import AppButton from "../components/AppButton.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppDropdown from "../components/AppDropdown.vue";
import AppDropdownItem from "../components/AppDropdownItem.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import AppPagination from '@/components/AppPagination.vue';
import { zipCodeMask, dateMask } from "@/utils/validations/mask";
import { useForm } from "vee-validate";
import useAddress from "@/utils/composables/address";
import useCourseType from "@/utils/composables/course_type";
import { PersonRepository } from "@/api/Repositories";

export default {
  components: {
    TemplateDashboard,
    DashboardTitle,
    AppButton,
    AppSelect,
    AppDropdown,
    AppDropdownItem,
    SimpleTextField,
    AppPagination
  },
  setup() {
    const students = ref<Person[]>([]);
    const meta = ref<Meta>({} as Meta);
    const page = ref<number>(1);

    const { handleSubmit,  setFieldValue, values } = useForm();

    const rolesOptions: SelectOptionType[] = [
      { value: "ALUNO", label: "Aluno" },
      { value: "RESPONSÁVEL", label: "Responsável" }
    ];

    const address = useAddress({
      setFieldValue,
      prefix: 'endereco'
    });

    const courseOptions = useCourseType();

    const setCourseId = (value: number) => {
      setFieldValue('curso_id', value);
    }

    const getStudents = async () => {
      const { endereco, curso, tipo_cadastro, ...formValues } = values;
      const { cep, uf, cidade_id } = endereco;

      const city = address.cityOptions.value.find((c) => {
        return c.value === cidade_id
      })?.label

      const state = address.statesOptions.value.find((s) => {
        return s.value === uf
      })?.label


      const payload = {
        cep: cep,
        uf: state,
        cidade: city,
        page: page.value,
        tipo_cadastro: (tipo_cadastro ?? []).join(','),
        ...formValues
      }

      const {data, ...metaValues} = await PersonRepository.getAll(payload);

      students.value = data;
      meta.value = metaValues;
    }

    const onSubmit = handleSubmit(() => {
      getStudents();

      page.value = 1;
    });

    watch(page, () => {
      getStudents();
    });

    return {
      zipCodeMask,
      dateMask,
      ...address,
      ...courseOptions,
      setCourseId,
      rolesOptions,
      onSubmit,
      students,
      meta,
      page
    }
  }
}


