<template>
  <aside class="margin lg:drawer" :class="{ drawer: !showDrawer, 'show-drawer': showDrawer }">
    <div class="school-logo">
      <img class="logo-image" src="@/assets/logo_ed1.png" />
    </div>
    <div class="user-info">
      <span class="text">Olá,</span>
      <p class="user-name">Gustavo Santucci</p>
      <user-drawer
      title="gustavo.santucci@gmail.com"
      />
    </div>
    <section>
      <dashboard-drawer-item title="Home" icon="home.svg" href="/" />
      <dashboard-drawer-item
        title="Secretaria"
        icon="secretary.png"
        :subList="[
          {
            name: 'Unidades',
            href: '/secretaria/unidades'
          },
          {
            name: 'Empresas',
            href: '/secretaria/empresas'
          },
          {
            name: 'Cursos',
            href: '/secretaria/cursos'
          },
          {
            name: 'Séries',
            href: '/secretaria/series'
          },
          {
            name: 'Turmas',
            href: '/secretaria/turmas'
          }
        ]"
      />
      <dashboard-drawer-item
        title="Acadêmico"
        icon="academic.png"
        :subList="[
          { name: 'Matrículas', href: '/academico/matriculas' },
          { name: 'Alunos', href: '/academico/alunos' }
        ]"
      />
      <dashboard-drawer-item title="Pedagógico" icon="test.png" />
      <dashboard-drawer-item title="Eventos" icon="calendar.png" />
      <dashboard-drawer-item title="Financeiro" icon="financy.svg" />
      <dashboard-drawer-item title="Relatórios" icon="report.svg" />
      <dashboard-drawer-item title="Estoque" icon="locker.svg" />
      <dashboard-drawer-item title="Biblioteca" icon="library.png" />
    </section>
  </aside>
</template>

<script>
import { defineComponent } from "vue";
import DashboardDrawerItem from "./DashboardDrawerItem.vue";
import UserDrawer from "./UserDrawer.vue";

export default defineComponent({
  components: { DashboardDrawerItem, UserDrawer },
  name: "DashboardDrawer",
  props: {
    showDrawer: {
      type: Boolean
    }
  },
  setup(props) {}
});
</script>

<style lang="scss" scoped>
.margin {
  @apply pt-20 lg:pt-header;
}

.drawer {
  @apply bg-white md:max-w-drawer w-full h-full z-40 overflow-y-auto fixed transform -translate-x-full lg:translate-x-0 transition duration-200 ease-linear;
}

.show-drawer {
  @apply bg-white md:max-w-drawer w-full h-full z-40 overflow-y-auto fixed transform translate-x-0 transition duration-200 ease-linear;
}

.school-logo {
  & {
    background-color: #f6f7ff;
    @apply p-10 z-50;
  }

  & .logo-image {
    width: 100%;
    max-width: 10rem;
  }
}

.user-info {
  & {
    padding: $default-gap;
    font-family: "Roboto", sans-serif;
    border-bottom: 1px solid transparentize($color: $primary-color, $amount: 0.88);
  }

  & .text {
    color: transparentize($color: #000000, $amount: 0.4);
    font-size: 0.75rem;
  }

  & .email {
    & {
      display: flex;
      justify-content: space-between;
      align-content: center;
    }

    & .icon {
      font-size: 1.125rem;
      color: $primary-color;
    }
  }

  & .user-name {
    margin-top: 0.25rem;
    margin-bottom: 0.375rem;
    font-size: 1.25rem;
    color: transparentize($color: #000000, $amount: 0.2);
    font-weight: bold;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a5a5a5;
}
</style>
