<template>
  <template-register-content>
    <template #title>Criar conta</template>
    <template #subtitle>
      <template v-if="isNaturalPerson">
        Precisamos das informações do seu responsável
      </template>
      <template v-else>Precisamos de um responsável para contato</template>
    </template>
    <template #form-content>
      <template v-if="isNaturalPerson">
        <div class="row grid grid-2">
          <text-field
            v-model="cpf"
            label="Qual o CPF do responsável?"
            mask="###.###.###-##"
            :error="cpfMeta.touched && errors['contato_responsavel.cpf']"
          />
        </div>
        <div class="row">
          <text-field
            v-model="name"
            label="Qual o nome do responsável?"
            :error="nameMeta.touched && errors['contato_responsavel.nome_contato']"
          />
        </div>
        <!-- <div class="row grid grid-2">
          <app-select
            v-model="kinshipDegrees"
            label="Qual o grau de parentesco do responsável?"
            :error="kinshipDegreesMeta.touched && errors['contato_responsavel.grau_parentesco']"
          />
        </div> -->
      </template>
      <template v-else>
        <div class="row">
          <text-field
            v-model="name"
            label="Nome do contato"
            :error="nameMeta.touched && errors['contato_responsavel.nome_contato']"
          />
        </div>
      </template>
      <div class="row grid grid-2">
        <text-field
          v-model="email"
          label="E-mail do responsável"
          :error="emailMeta.touched && errors['contato_responsavel.email']"
        />
        <div class="row flex">
          <text-field
            v-model="ddd"
            class="flex-1"
            label="DDD"
            mask="(##)"
            :error="dddMeta.touched && errors['contato_responsavel.ddd']"
          />
          <text-field
            v-model="phone"
            class="flex-2"
            label="Telefone"
            :mask="['####-####', '#####-####']"
            :error="phoneMeta.touched && errors['contato_responsavel.telefone']"
          />
        </div>
      </div>
    </template>
    <template #buttons>
      <app-button type="button" @click="goBack()" class="white-primary">
        Voltar
      </app-button>
      <app-button type="submit" class="primary" rightIcon="chevron_right">
        Continuar
      </app-button>
    </template>
  </template-register-content>
</template>

<script>
import { defineComponent, inject } from "vue";

import AppButton from "@/components/AppButton.vue";
import TextField from "@/components/TextField.vue";
import TemplateRegisterContent from "@/components/TemplateRegisterContent.vue";
import AppSelect from "@/components/AppSelect.vue";
import { useField, useFormErrors } from "vee-validate";

export default defineComponent({
  components: { AppButton, TextField, TemplateRegisterContent, AppSelect },
  inject: ["goBack", "nextStep"],
  setup() {
    const isNaturalPerson = inject("isNaturalPerson");

    const { value: kinshipDegrees, meta: kinshipDegreesMeta } = useField(
      "contato_responsavel.grau_parentesco"
    );
    const { value: cpf, meta: cpfMeta } = useField("contato_responsavel.cpf");
    const { value: name, meta: nameMeta } = useField("contato_responsavel.nome_contato");
    const { value: email, meta: emailMeta } = useField("contato_responsavel.email");
    const { value: ddd, meta: dddMeta } = useField("contato_responsavel.ddd");
    const { value: phone, meta: phoneMeta } = useField("contato_responsavel.telefone");

    const errors = useFormErrors();

    return {
      isNaturalPerson,
      errors,
      kinshipDegrees,
      kinshipDegreesMeta,
      cpf,
      cpfMeta,
      name,
      nameMeta,
      email,
      emailMeta,
      ddd,
      dddMeta,
      phone,
      phoneMeta
    };
  }
});
</script>
