<template>
  <template-login>
    <h1 class="template-title">
      Sua conta foi criada <br />
      com sucesso!
    </h1>
    <p class="template-text">
      Você já pode acessar a plataforma. Encaminhamos mais informações para o e-mail cadastrado.
    </p>
    <div class="button-container">
      <app-button @click="$router.push('/')" class="primary" rightIcon="chevron_right">
        login
      </app-button>
    </div>
  </template-login>
</template>

<script>
import AppButton from "@/components/AppButton.vue";
import TemplateLogin from "@/components/TemplateLogin.vue";
export default {
  components: { AppButton, TemplateLogin }
};
</script>

<style lang="scss" scoped>
.button-container {
  margin-top: 2rem;
}
</style>
