
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: String
    }
  },
  setup(_, { emit }) {
    const handleClick = (value: string) => {
      emit("update:modelValue", value);
    };

    return { handleClick };
  }
});
