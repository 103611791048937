
import { defineComponent, onMounted, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppButton from "@/components/AppButton.vue";
import {
  CourseRepository,
  SecretarySeriesRepository,
} from "@/api/Repositories";
import TemplateSecretaryFilters from "@/components/TemplateSecretaryFilters.vue";
import TemplateInformationCard from "@/components/TemplateInformationCard.vue";
import { useRouter } from "vue-router";
import AppDropdown from "@/components/AppDropdown.vue";
import AppDropdownItem from "@/components/AppDropdownItem.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";

export default defineComponent({
  components: {
    TemplateDashboard,
    AppSelect,
    AppButton,
    TemplateSecretaryFilters,
    TemplateInformationCard,
    AppDropdown,
    AppDropdownItem,
    DashboardTitle,
  },
  name: "SecretaryUnits",
  setup() {
    const courseOptions = ref([] as SelectOptionType[]);
    const series = ref([] as Serie[]);
    const router = useRouter();

    onMounted(async () => {
      courseOptions.value = (await CourseRepository.getAll()).map((s) => ({
        label: s.descricao,
        value: s.id,
      }));
    });

    const handleGetSeries = async (value: string) => {
      series.value = await SecretarySeriesRepository.getSerieByCourse(
        Number(value)
      );
    };

    const handleEdit = (serieId: string) => {
      router.push(`/secretaria/series/${serieId}`);
    };

    const handleDelete = async (serieId: string, cursoId: string) => {
      await SecretarySeriesRepository.delete(Number(serieId));
      series.value = series.value.filter((s: Serie) => {
        return s.id !== Number(serieId);
      });

      handleGetSeries(cursoId);
    };

    const navigate = (route: string) => {
      router.push(route);
    };

    return {
      courseOptions,
      navigate,
      handleGetSeries,
      series,
      handleEdit,
      handleDelete,
    };
  },
});
