<template>
  <template-register-content>
    <template #title>Criar conta</template>
    <template #subtitle>Insira o código fornecido pelo colégio ou instituição</template>
    <template #form-content>
      <div class="grid grid-2">
        <text-field
          v-model="code"
          label="Código"
          placeholder="1234 1234 123 1234 12 123456"
          mask="#### #### ### #### ## ######"
          rightIcon="vpn_key"
          :error="errors.code"
        />
      </div>
    </template>
    <template #buttons>
      <app-button type="button" @click="goBack" class="white-primary">
        Voltar
      </app-button>
      <app-button type="submit" class="primary" rightIcon="chevron_right">
        Continuar
      </app-button>
    </template>
  </template-register-content>
</template>

<script>
import { defineComponent } from "vue";

import AppButton from "@/components/AppButton.vue";
import TextField from "@/components/TextField.vue";
import TemplateRegisterContent from "@/components/TemplateRegisterContent.vue";
import { useField, useFormErrors } from "vee-validate";

export default defineComponent({
  components: { AppButton, TextField, TemplateRegisterContent },
  inject: ["nextStep", "goBack"],
  setup() {
    const errors = useFormErrors();
    const { value: code } = useField("code");

    return {
      errors,
      code
    };
  }
});
</script>
