<template>
  <template-register v-if="step !== 'finish'">
    <form class="register-form" @submit="onSubmit">
      <transition name="step" mode="out-in">
        <keep-alive>
          <register-code v-if="step === 'code'" key="code" />
          <register-account v-else-if="step === 'account'" key="account" />
          <register-user-type v-else-if="step === 'user-type'" key="user-type" />
          <register-address v-else-if="step === 'address'" key="address" />
          <register-responsible-person
            v-else-if="step === 'responsible-person'"
            key="responsible-person"
          />
          <register-password v-else-if="step === 'password'" key="passowrd" />
        </keep-alive>
      </transition>
    </form>
  </template-register>
  <register-finish v-if="step === 'finish'" key="finish" />
</template>

<script>
import { computed, defineComponent, provide, ref } from "vue";

import AppButton from "@/components/AppButton.vue";
import TemplateRegister from "@/components/TemplateRegister.vue";
import RegisterAccount from "./RegisterAccount.vue";
import RegisterAddress from "./RegisterAddress.vue";
import RegisterCode from "./RegisterCode.vue";
import RegisterPassword from "./RegisterPassword.vue";
import RegisterResponsiblePerson from "./RegisterResponsiblePerson.vue";
import RegisterUserType from "./RegisterUserType.vue";
import RegisterFinish from "./RegisterFinish.vue";
import { useForm } from "vee-validate";
import "@/utils/i18n/yup";

import registerSchemas from "@/utils/validations/registerSchemas";
import { useRouter } from "vue-router";
import { onlyNumbers } from "@brazilian-utils/brazilian-utils";
import { UserRepository } from "@/api/Repositories";

import permissions from "@/utils/mocks/permissions";

export default defineComponent({
  name: "RegisterPage",
  components: {
    TemplateRegister,
    AppButton,
    RegisterCode,
    RegisterAccount,
    RegisterUserType,
    RegisterAddress,
    RegisterResponsiblePerson,
    RegisterPassword,
    RegisterFinish
  },
  setup() {
    const step = ref("code");
    const stepHistory = ref([]);
    const formData = ref({});

    const { handleSubmit, resetForm, values, errors } = useForm({
      validationSchema: schema
    });

    const isNaturalPerson = computed(() => values.pessoa.tipo_pessoa === "PF");
    const isStudent = computed(() => values.pessoa.tipo_conta === "student");

    const schema = computed(() => registerSchemas(isNaturalPerson.value || null)[step.value]);

    const router = useRouter();

    const nextStep = () => {
      stepHistory.value.push(step.value);
      let next;
      switch (step.value) {
        case "code":
          next = "account";
          break;
        case "account":
          next = isNaturalPerson.value ? "user-type" : "responsible-person";
          break;
        case "user-type":
          next = !isStudent.value && isNaturalPerson.value ? "password" : "responsible-person";
          break;
          // case "address":
          next = !isStudent.value && isNaturalPerson.value ? "password" : "responsible-person";
        //   break;
        case "responsible-person":
          next = "password";
      }
      if (next) step.value = next;
    };

    const goBack = () => {
      if (stepHistory.value.length === 0) {
        router.push("/");
        return;
      }

      step.value = stepHistory.value.pop();

      resetForm({ values: { ...formData.value } });
    };

    const onSubmit = handleSubmit(formValues => {
      formData.value = {
        ...formData.value,
        ...formValues
      };

      resetForm({ values: { ...formData.value } });

      if (step.value === "password") {
        onHandleSubmit();

        return;
      }

      nextStep();
    });

    const onHandleSubmit = async () => {
      const contact1 = {
        principal: isNaturalPerson.value,
        ...values.contato,
        ddd: onlyNumbers(values.contato.ddd),
        telefone: onlyNumbers(values.contato.telefone)
      };

      const contact2 = values.contato_responsavel
        ? {
            principal: !isNaturalPerson.value,
            ...values.contato_responsavel,
            cpf: onlyNumbers(values.contato_responsavel.cpf),
            ddd: onlyNumbers(values.contato_responsavel.ddd),
            telefone: onlyNumbers(values.contato_responsavel.telefone)
          }
        : {};

      const document = isNaturalPerson
        ? { cpf: onlyNumbers(values.pessoa.cpf) }
        : { cpnj: onlyNumbers(values.pessoa.cnpj) };

      const data = {
        login: onlyNumbers(isNaturalPerson ? values.pessoa.cpf : values.pessoa.cnpj),
        senha: values.senha,
        nome: values.pessoa.nome,
        pessoa: {
          ...values.pessoa,
          ...document,
          contatos: []
        }
      };

      if (isNaturalPerson.value) {
        delete data.pessoa.cnpj;
      } else {
        delete data.pessoa.cpf;
      }

      values.contato && data.pessoa.contatos.push(contact1);
      values.contato_responsavel && data.pessoa.contatos.push(contact2);

      data.permissoes = permissions;

      const { data: response } = await UserRepository.register(data);

      if (response.success) nextStep();
    };

    provide("nextStep", nextStep);
    provide("goBack", goBack);

    provide("isNaturalPerson", isNaturalPerson);
    provide("isStudent", isStudent);

    return {
      errors,
      step,
      onSubmit
    };
  }
});
</script>

<style lang="scss" scoped>
.step-enter-active {
  transition: all 0.4s ease;
}

.step-enter-from {
  transform: translateX(100px);
}

.page-enter-active {
  transition: all 0.4s ease;
}

.page-enter-from {
  transform: translateX(100px);
}

.register-form {
  height: 100%;
}
</style>
