import Client from '../AxiosClient';

type ClassFilters = {
  course_id?: string;
  serie_id?: string;
}

export default {
  async getAll(filters?: ClassFilters): Promise<Class[]> {
    const { data } = await Client.get(`/e1-secretaria-api/public/api/treinamentos/turmas`, {params: filters});
    return data.data.data;
  },

  async getPeriod(): Promise<Period[]> {
    const { data } = await Client.get('/e1-secretaria-api/public/api/treinamentos/turmas/periodos');
    return data.data;
  },

  async getSituations(): Promise<Situation[]> {
    const { data } = await Client.get('/e1-secretaria-api/public/api/treinamentos/turmas/situacoes');
    return data.data;
  },

  async getClassById(id: number): Promise<Class> {
    const { data } = await Client.get(`/e1-secretaria-api/public/api/treinamentos/turmas/${id}`);
    return data.data;
  },

  async create(payload: Record<string, unknown>): Promise<Class> {
    const { data } = await Client.post('/e1-secretaria-api/public/api/treinamentos/turmas', payload);
    return data.data;
  },

  async update(id: number, payload: Record<string, unknown>): Promise<Class> {
    const { data } = await Client.put(`/e1-secretaria-api/public/api/treinamentos/turmas/${id}`, payload);
    return data.data;
  },

  async delete(id: number): Promise<Class> {
    return await Client.delete(`/e1-secretaria-api/public/api/treinamentos/turmas/${id}`);
  },
};
