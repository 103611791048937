<template>
  <label class="radio-button">
    <input
      class="radio-hidden"
      type="radio"
      :value="value"
      :name="name"
      :checked="checked"
      @input="handleChange"
    />
    <div v-if="!compact" class="radio-container">
      <span class="checkbox" />
      <p class="label-text"><slot></slot></p>
    </div>
    <div v-else class="radio-container-transparent">
      <span class="checkbox" />
    </div>
  </label>
</template>

<script>
import { useField, useFieldValue } from "vee-validate";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RadioButton",
  props: {
    name: {
      type: String,
      required: true
    },
    value: {},
    compact: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const currentValue = useFieldValue(props.name);

    const { handleChange, value: inputValue, checked } = useField(props.name, undefined, {
      initialValue: currentValue,
      type: "radio",
      checkedValue: props.value
    });

    return { handleChange, inputValue, checked };
  }
});
</script>

<style lang="scss" scoped>
.radio-button {
  & {
    width: 100%;
    cursor: pointer;
  }

  & .radio-hidden {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .radio-hidden:checked + .radio-container {
    & {
      background-color: $primary-light-color;
    }

    & .label-text {
      color: $white-color;
      font-weight: 600;
    }

    & .checkbox:after {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  .radio-hidden:checked + .radio-container-transparent {
    & .checkbox:after {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  & .radio-container {
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    background-color: $white-color;
    border-radius: 0.75rem;
    transition: 0.4s all;
  }

  & .radio-container-transparent {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border-radius: 0.75rem;
    transition: 0.4s all;
  }

  & .label-text {
    color: $primary-color;
    font-size: 1rem;
    margin-left: 2rem;
  }

  & .checkbox {
    & {
      position: relative;
      width: 1.5rem;
      height: 1.5rem;
      border: 0.175rem solid transparentize($black-color, 0.5);
      border-radius: 1rem;
    }

    &:after {
      background-color: $primary-color;
      width: 0;
      height: 0;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 9999rem;
      transition: 0.4s all;
    }
  }

  & .radio:checked ~ & .checkbox {
    border-color: $primary-color;
  }
}
</style>
