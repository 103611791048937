<template>
  <div>
    <div class="flex items-center gap-x-2 cursor-pointer" @click="toggleList">
      <span class="text">
        {{ title }}
      </span>
      <span
        class="material-icons icon"
        :class="[{ 'is-open': isOpen }]"
      >
        expand_more
      </span>
    </div>
    <div
      class="sublist"
      :class="[{ 'sublist-open': isOpen, 'sublist-closed': !isOpen }]"
    >
      <div
        class="sublist-item"
        @click="logOut"
      >
        <span class="drawer-text">Logout</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from "vue";
import { useRouter } from "vue-router";
  export default defineComponent({
    name: 'UserDrawer',
    props: {
      title: {
        type: String,
        default: ''
      },
      subList: {
        type: Array as PropType<string[]>,
        default: []
      },
    },
    setup() {
      const isOpen = ref(false);
      const router = useRouter();

      const toggleList = () => {
        isOpen.value = !isOpen.value;
      }

      const logOut = () => {
        localStorage.removeItem('authHeaders');
        router.push('/login');
      }

      return { isOpen, toggleList, logOut }
    }
  });
</script>

<style scoped>
  .text {
    @apply text-xs text-black opacity-60;
  }

  .icon {
    transition: 0.1s transform;
    @apply text-lg text-black opacity-60;
  }

  .is-open {
    transform: rotate(180deg);
  }

  .sublist {
    transition: 0.3s all;
    @apply overflow-hidden;
  }

  .sublist-item {
    @apply cursor-pointer;
  }

  .sublist-open {
    max-height: 20rem;
  }

  .sublist-closed {
    max-height: 0;
  }

  .drawer-text {
    @apply font-medium text-gray text-sm cursor-pointer hover:text-primary;
  }
</style>
