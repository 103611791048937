
import { defineComponent, onMounted, ref } from "vue";
import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppButton from "@/components/AppButton.vue";
import AppDropdown from "@/components/AppDropdown.vue";
import AppDropdownItem from "@/components/AppDropdownItem.vue";
import DashboardItemCard from "@/components/DashboardItemCard.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";
import { SecretaryCompanyRepository } from "@/api/Repositories";

export default defineComponent({
  components: {
    AppDropdown,
    AppDropdownItem,
    TemplateDashboard,
    AppButton,
    DashboardItemCard,
    DashboardTitle,
  },
  name: "SecretaryCompanies",
  setup() {
    const companies = ref<Company[]>([]);
    const loading = ref(true);

    onMounted(async () => {
      loading.value = true;
      companies.value = await SecretaryCompanyRepository.getAll();
      loading.value = false;
    });

    const removeCompany = async (id: number) => {
      companies.value = companies.value.filter((c) => c.id !== id);
      SecretaryCompanyRepository.delete(id).then(async () => {
        companies.value = await SecretaryCompanyRepository.getAll();
      });
    };

    return { companies, removeCompany, loading };
  },
});
