
import { defineComponent } from "vue";
import { POSITION, provideToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

export default defineComponent({
  setup() {
    provideToast({ timeout: 3000, position: POSITION.BOTTOM_RIGHT });
  }
});
