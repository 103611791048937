
import { useField } from "vee-validate";
import { defineComponent, onMounted, ref, watch } from "vue";

import ErrorMessage from "@/components/ErrorMessage.vue";

export default defineComponent({
  name: "DashboardUploadCard",
  components: { ErrorMessage },
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true,
      default: ""
    },
    initialValue: {
      type: String,
      default: ""
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {
    const inputButton = ref<HTMLInputElement | null>(null);

    const selectedFile = ref();
    const imageUrl = ref("");
    const fileName = ref("");

    const { handleChange, value: inputValue, errorMessage } = useField(props.name, undefined);

    onMounted(() => {
      if (inputButton.value?.files) {
        selectedFile.value = inputButton.value?.files[0];
      }
    });

    watch(
      () => props.initialValue,
      () => {
        imageUrl.value = props.initialValue;
      }
    );

    const handleButtonClick = () => {
      if(props.disabled) return;

      inputButton.value && inputButton.value.click();
    };

    const setSelectedFile = (e: Event) => {
      if (!e.target) return;

      const target = (e.target ?? {}) as HTMLInputElement;
      if (target && target.files) {
        selectedFile.value = target!.files![0];
        handleChange(e);
      }
    };

    watch(selectedFile, () => {
      if (selectedFile.value) {
        const reader = new FileReader();

        reader.onload = e => {
          if (!e.target) return;
          imageUrl.value = e.target.result as string;
        };

        reader.readAsDataURL(selectedFile.value);
        fileName.value = selectedFile.value.name;
      }
    });

    return {
      setSelectedFile,
      handleButtonClick,
      imageUrl,
      fileName,
      inputButton,
      errorMessage
    };
  }
});
