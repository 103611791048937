export const cnpjMask = "##.###.###/####-##";

export const cpfMask = "###.###.###-##";

export const phoneCellphoneMask = ["####-####", "#####-####"];

export const dddMask = "##";

export const zipCodeMask = "#####-###";

export const dateMask = "##/##/####";

export const timeMask = "##:##";
