<template>
  <template-dashboard>
    <dashboard-title title="Pesquisar Pessoas">
      <div class="flex flex-wrap justify-end gap-4 sm:flex-nowrap">
        <app-button
          class="w-auto border-black border-opacity-10 text-primary"
          left-icon="list"
          mini
          @click="$router.push('/academico/alunos')"
          >Listar</app-button
        >
        <app-button
          class="w-auto yellow"
          left-icon="add"
          mini
          @click="$router.push('/academico/alunos/inserir')"
          >Inserir</app-button
        >
      </div>
    </dashboard-title>
    <div class="flex gap-4">
      <app-select class="w-full" label="Ano" />
      <app-select
        class="w-full"
        name="curso"
        label="Segmento"
        :options="courseOptions"
        @change="setCourseId"
      />
    </div>
    <main>
      <form
        class="mt-4"
        @submit="onSubmit"
      >
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <simple-text-field name="curso_id" placeholder="Curso Id" />
          <simple-text-field name="ano_letivo" placeholder="Ano Letivo" />
          <simple-text-field name="rm" placeholder="RM" />
          <app-select
            name="tipo_cadastro"
            label="Tipo de Cadastro"
            :options="rolesOptions"
            multi
          />
          <simple-text-field name="nome" class="col-span-1 sm:col-span-2" placeholder="Nome" />
          <simple-text-field name="documento" placeholder="Documento" />
          <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <simple-text-field
              name="data_nascimento_inicio"
              class="w-full"
              placeholder="Nascimento (Data inicial)"
              :mask="dateMask"
            />
            <simple-text-field
              name="data_nascimento_fim"
              placeholder="Nascimento (Data final)"
              :mask="dateMask"
            />
          </div>
          <app-select
          name="endereco.uf"
          label="UF"
          :options="statesOptions"
          @change="handleGetCities"
          />
          <app-select name="endereco.cidade_id" label="Cidade" :options="cityOptions" />
          <simple-text-field
          name="endereco.cep"
          placeholder="CEP"
          :mask="zipCodeMask"
          @keyup="handleGetCep"
          />
        </div>
        <app-button class="w-auto mt-4 yellow" type="submit" left-icon="search" mini>localizar</app-button>
      </form>
      <h2 class="mt-10 text-2xl font-semibold text-error">Pessoas encontradas</h2>
      <div class="flex flex-col gap-4 mt-4">
        <div
          class="flex items-center w-full px-4 py-6 bg-white border rounded border-gray border-opacity-10"
          v-for="student in students"
          :key="student.id"
        >
          <div class="flex-1">
            <img :src="student.url_foto || require('@/assets/images/placeholder.png')" class="inline-block rounded-full w-14 h-14" />
            <p class="inline-block ml-4 text-sm font-semibold text-black text-opacity-60">
              {{ student.nome }}
            </p>
          </div>
          <div class="flex justify-end flex-1 mr-40">
            <p class="inline-block w-24 text-sm font-medium text-black text-opacity-60">
              RM <span class="font-semibold">{{ student.rm }}</span>
            </p>
            <p class="inline-block ml-16 text-sm font-medium text-black w-36 text-opacity-60">
              CPF <span class="font-semibold">{{ student.cpf }}</span>
            </p>
          </div>
          <app-dropdown>
            <span class="text-black cursor-pointer material-icons text-opacity-40">more_vert</span>
            <template #content>
              <app-dropdown-item @click="$router.push(`/academico/matriculas/${student.id}/inserir`)">
                Matricular Aluno
              </app-dropdown-item>
            </template>
          </app-dropdown>
        </div>
      </div>
      <app-pagination class="mt-4" :meta="meta" @page-change="page = $event"/>
    </main>
  </template-dashboard>
</template>

<script lang="ts">
import { ref, watch } from "vue"
import TemplateDashboard from "@/components/TemplateDashboard.vue";
import DashboardTitle from "../components/DashboardTitle.vue";
import AppButton from "../components/AppButton.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppDropdown from "../components/AppDropdown.vue";
import AppDropdownItem from "../components/AppDropdownItem.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import AppPagination from '@/components/AppPagination.vue';
import { zipCodeMask, dateMask } from "@/utils/validations/mask";
import { useForm } from "vee-validate";
import useAddress from "@/utils/composables/address";
import useCourseType from "@/utils/composables/course_type";
import { PersonRepository } from "@/api/Repositories";

export default {
  components: {
    TemplateDashboard,
    DashboardTitle,
    AppButton,
    AppSelect,
    AppDropdown,
    AppDropdownItem,
    SimpleTextField,
    AppPagination
  },
  setup() {
    const students = ref<Person[]>([]);
    const meta = ref<Meta>({} as Meta);
    const page = ref<number>(1);

    const { handleSubmit,  setFieldValue, values } = useForm();

    const rolesOptions: SelectOptionType[] = [
      { value: "ALUNO", label: "Aluno" },
      { value: "RESPONSÁVEL", label: "Responsável" }
    ];

    const address = useAddress({
      setFieldValue,
      prefix: 'endereco'
    });

    const courseOptions = useCourseType();

    const setCourseId = (value: number) => {
      setFieldValue('curso_id', value);
    }

    const getStudents = async () => {
      const { endereco, curso, tipo_cadastro, ...formValues } = values;
      const { cep, uf, cidade_id } = endereco;

      const city = address.cityOptions.value.find((c) => {
        return c.value === cidade_id
      })?.label

      const state = address.statesOptions.value.find((s) => {
        return s.value === uf
      })?.label


      const payload = {
        cep: cep,
        uf: state,
        cidade: city,
        page: page.value,
        tipo_cadastro: (tipo_cadastro ?? []).join(','),
        ...formValues
      }

      const {data, ...metaValues} = await PersonRepository.getAll(payload);

      students.value = data;
      meta.value = metaValues;
    }

    const onSubmit = handleSubmit(() => {
      getStudents();

      page.value = 1;
    });

    watch(page, () => {
      getStudents();
    });

    return {
      zipCodeMask,
      dateMask,
      ...address,
      ...courseOptions,
      setCourseId,
      rolesOptions,
      onSubmit,
      students,
      meta,
      page
    }
  }
}


</script>
