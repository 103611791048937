<template>
  <button :disabled="disabled" class="base-button" :class="{ mini: mini }">
    <span class="material-icons icon icon-left">{{ leftIcon }}</span>
    <slot></slot>
    <span class="material-icons icon icon-right">{{ rightIcon }}</span>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    leftIcon: String,
    rightIcon: String,
    disabled: Boolean,
    mini: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.base-button {
  & {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    padding: 1.125rem;
    width: 100%;
    border-radius: 0.75rem;
    border: none;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    font-weight: 600;
  }

  &.white-primary {
    background-color: $white-color;
    color: $primary-color;
    box-shadow: 0px 16px 32px 0px #0000000a;
    border-color: transparentize($color: $primary-color, $amount: 0.7);
  }

  &.transparent {
    background-color: transparent;
    border: none;
  }

  &.yellow {
    background-color: $yellow-color;
    color: $primary-color;
    border: none;

    &:disabled {
      @apply bg-black bg-opacity-5 text-black text-opacity-40;
    }
  }

  &.gray {
    background-color: rgba($color: #000000, $alpha: 0.06);
    color: $gray300-color;
    border: none;
  }

  &.bold {
    font-weight: bold;
  }

  &.normal {
    font-weight: normal;
  }

  &.back {
    @apply text-primary border-black border-opacity-5;
  }

  &.primary {
    background-color: $primary-color;
    color: $white-color;
  }

  &:active {
    transform: scale(0.95);
  }

  &:focus {
    outline: none;
  }

  & .icon {
    font-size: 1.5rem;
    height: 1.5rem;

    &-left {
      margin-right: 0.5rem;
    }

    &-right {
      margin-left: 0.5rem;
    }
  }

  &.w-auto {
    width: auto;
  }

  &.mini {
    font-size: 0.875rem;
    padding: 0.5rem;
    border-radius: 0.25rem;

    &.icon {
      font-size: 0.875rem;
    }
  }
}
</style>
