import Client from '../AxiosClient';

export default {
  async getAll(filters?: StudentSearch): Promise<RequestWithMeta<Person>> {
    const { data } = await Client.get(
      '/e1-pessoa-api/public/api/treinamentos/pessoas',
      { params: filters },
    );
    return ({
      ...data.data,
      current_page: data.data.currentPage,
    });
  },
};
