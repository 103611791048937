import * as yup from 'yup';
import { parseDateString } from './format';

const schema = yup.object().shape({
  ano: yup.string().required().label('Ano'),
  curso_id: yup.string().required().label('Curso'),
  serie_id: yup.string().required().label('Serie'),
  turma_id: yup.string().required().label('Turma'),
  periodo_id: yup.string().required().label('Período'),
  data_matricula: yup
    .date()
    .required()
    .transform(parseDateString)
    .typeError(({ label }) => `${label} não é uma data válida`)
    .label('Data de Matrícula'),
  data_efetivacao: yup
    .date()
    .transform(parseDateString)
    .typeError(({ label }) => `${label} não é uma data válida`)
    .label('Data de efetivação'),
  empresa_id: yup.string().required().label('Empresa'),
  unidade_id: yup.string().required().label('Unidade'),
});

export default schema;
