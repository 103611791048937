<template>
  <template-login>
    <template v-if="!isSubmitted">
      <h1 class="template-title">Redefinir senha</h1>
      <h2 class="template-text">Por favor digite sua nova senha</h2>
      <form @submit.prevent="onSubmit">
        <simple-text-field
          v-model="password"
          class="default-margin"
          type="password"
          placeholder="Nova senha"
          :error="passwordMeta.touched && passwordError"
        />
        <simple-text-field
          v-model="passwordConfirm"
          class="default-margin"
          type="password"
          placeholder="Repetir nova senha"
          :error="passwordConfirmMeta.touched && passwordConfirmError"
        />
        <app-button type="submit" class="primary default-margin" rightIcon="chevron_right">
          Enviar
        </app-button>
      </form>
    </template>
    <template v-else>
      <h1 class="template-title">Sua senha foi redefinida</h1>
      <h2 class="template-text">
        Você já pode acessar a plataforma com sua nova senha
      </h2>
      <app-button
        @click="$router.push('/')"
        class="primary default-margin"
        rightIcon="chevron_right"
      >
        Login
      </app-button>
    </template>
  </template-login>
</template>

<script>
import { defineComponent, ref } from "vue";

import TemplateLogin from "@/components/TemplateLogin.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import AppButton from "@/components/AppButton.vue";

import { useField, useForm } from "vee-validate";
import * as yup from "yup";

export default defineComponent({
  name: "ChangePassword",
  components: { TemplateLogin, SimpleTextField, AppButton },
  setup() {
    const isSubmitted = ref(false);
    const { handleSubmit } = useForm({
      validationSchema: yup.object().shape({
        senha: yup
          .string()
          .required()
          .label("Senha"),
        senha_confirmacao: yup
          .string()
          .required()
          .oneOf([yup.ref("senha")], ({ label }) => `${label} tem que ser igual a Senha`)
          .label("Confirmação de Senha")
      })
    });

    const { value: password, meta: passwordMeta, errorMessage: passwordError } = useField("senha");
    const {
      value: passwordConfirm,
      meta: passwordConfirmMeta,
      errorMessage: passwordConfirmError
    } = useField("senha_confirmacao");

    const onSubmit = handleSubmit(values => {
      console.log(values);

      isSubmitted.value = true;
    });

    return {
      password,
      passwordMeta,
      passwordError,
      passwordConfirm,
      passwordConfirmMeta,
      passwordConfirmError,
      onSubmit,
      isSubmitted
    };
  }
});
</script>

<style lang="scss" scoped>
.default-margin {
  margin-top: $default-gap;
}
</style>
