
import { defineComponent, onMounted, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppButton from "@/components/AppButton.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";
import DashboardItemCard from "@/components/DashboardItemCard.vue";
import { SecretaryUnitRepository } from "@/api/Repositories";
import AppDropdownItem from "@/components/AppDropdownItem.vue";
import TemplateSecretaryFilters from "@/components/TemplateSecretaryFilters.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    TemplateDashboard,
    AppSelect,
    AppButton,
    DashboardItemCard,
    DashboardTitle,
    AppDropdownItem,
    TemplateSecretaryFilters,
  },
  name: "SecretaryUnits",
  setup() {
    const units = ref([] as Unity[]);
    const loading = ref(true);

    const router = useRouter();

    const getUnits = async () => {
      loading.value = true;
      const unitList = await SecretaryUnitRepository.list();
      units.value = unitList;
      loading.value = false;
    };

    onMounted(() => {
      getUnits();
    });

    const editUnit = (id: string) => {
      router.push(`/secretaria/unidades/${id}`);
    };

    const deleteUnit = async (id: string) => {
      await SecretaryUnitRepository.delete(id);

      getUnits();
    };

    return { units, deleteUnit, editUnit };
  },
});
