<template>
  <div class="wrapper">
    <main class="main-content">
      <slot></slot>
    </main>
    <aside class="aside">
      <img class="logo" src="@/assets/logo_ed1.png" />
    </aside>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  background-color: #f2f2f2;
  flex-direction: column-reverse;
  height: 100%;
  overflow: auto;
}

.main-content {
  & {
    flex: 1;
    padding: 1rem;
  }
}

.aside {
  & {
    padding: 5.625rem 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    background: url("../assets/bg_ed1.png") center bottom / cover no-repeat #e9e7ea;
  }

  & .logo {
    width: 100%;
    max-width: 15rem;
    padding: 0 2rem;
  }
}

@media (min-width: $sm) {
  .main-content {
    padding: 2rem;
  }
}

@media (min-width: $md) {
  .wrapper {
    flex-direction: row;
  }
  .main-content {
    padding: 10rem 4rem;
    margin-right: 18rem;
  }

  .aside {
    position: fixed;
    right: 0;
    width: 100%;
    height: 100vh;
    max-width: 18rem;

    & .logo {
      max-width: 13rem;
      padding: 0 2rem;
    }
  }
}

@media (min-width: $xl) {
  .main-content {
    padding: 10rem;
  }
}
</style>
