import axios from "axios";

type AddressData = {
  street: string;
  neighborhood: string;
  city: string;
  state: string;
  zipcode: string;
  ibge: string;
};

export default {
  async getAddress(zipcode: string) {
    const { data } = await axios.get(`https://viacep.com.br/ws/${zipcode}/json`);

    const address: AddressData = {
      street: data.logradouro,
      neighborhood: data.bairro,
      city: data.localidade,
      state: data.uf,
      zipcode: data.cep,
      ibge: data.ibge,
    };

    return address;
  }
};
