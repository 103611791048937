<template>
  <template-register-content>
    <template #title>Criar conta</template>
    <template #subtitle>Para finalizar, defina sua senha de acesso</template>
    <template #form-content>
      <div class="grid grid-2 row">
        <text-field
          v-model="password"
          label="Senha"
          placeholder="*************"
          type="password"
          :error="passwordMeta.touched && errors['senha']"
        />
      </div>
      <div class="grid grid-2 row">
        <text-field
          label="Repetir senha"
          v-model="passwordConfirmation"
          placeholder="*************"
          type="password"
          :error="passwordConfirmationMeta.touched && errors['senha_confirmacao']"
        />
      </div>
    </template>
    <template #buttons>
      <app-button type="button" @click="goBack" class="white-primary">
        Voltar
      </app-button>
      <app-button type="submit" class="primary" rightIcon="chevron_right">
        Continuar
      </app-button>
    </template>
  </template-register-content>
</template>

<script>
import { defineComponent } from "vue";

import AppButton from "@/components/AppButton.vue";
import TextField from "@/components/TextField.vue";
import TemplateRegisterContent from "@/components/TemplateRegisterContent.vue";
import { useField, useFormErrors } from "vee-validate";

export default defineComponent({
  components: { AppButton, TextField, TemplateRegisterContent },
  inject: ["goBack", "nextStep"],
  setup() {
    const { value: password, meta: passwordMeta } = useField("senha");
    const { value: passwordConfirmation, meta: passwordConfirmationMeta } = useField(
      "senha_confirmacao"
    );

    const errors = useFormErrors();

    return { errors, password, passwordMeta, passwordConfirmation, passwordConfirmationMeta };
  }
});
</script>
