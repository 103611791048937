import { RouteRecordRaw } from "vue-router";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ForgotPassword from "../views/LoginForgotPassword.vue";
import ChangePassword from "../views/LoginChangePassword.vue";
import Dashboard from "../views/Dashboard.vue";
import AcademicEnrollment from "../views/AcademicEnrollment.vue";
import AcademicEnrollmentSearch from "../views/AcademicEnrollmentSearch.vue";
import AcademicEnrollmentCreate from "../views/AcademicEnrollmentCreate.vue";
import AcademicStudent from "../views/AcademicStudent.vue";
import AcademicStudentCreate from "../views/AcademicStudentCreate.vue";
import AcademicStudentSearch from "../views/AcademicStudentSearch.vue";
import SecretaryUnits from "../views/SecretaryUnits.vue";
import SecretaryUnitsCreate from "../views/SecretaryUnitsCreate.vue";
import SecretaryUnitsView from "../views/SecretaryUnitsView.vue";
import SecretaryCourses from "../views/SecretaryCourses.vue";
import SecretaryCoursesCreate from "../views/SecretaryCoursesCreate.vue";
import SecretaryCompanies from "../views/SecretaryCompanies.vue";
import SecretaryCompaniesCreate from "../views/SecretaryCompaniesCreate.vue";
import SecretaryCompaniesView from "../views/SecretaryCompaniesView.vue";
import SecretaryClassView from "../views/SecretaryClassView.vue";
import SecretaryClassCreate from "../views/SecretaryClassCreate.vue";
import SecretarySeriesView from "../views/SecretarySeriesView.vue";
import SecretarySeriesCreate from "../views/SecretarySeriesCreate.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/esqueci-senha",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/redefinir-senha",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/cadastrar",
    name: "Register",
    component: Register,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/academico/matriculas",
    name: "AcademicEnrollment",
    component: AcademicEnrollment
  },
  {
    path: "/academico/matriculas/:id/inserir",
    name: "AcademicEnrollmentCreate",
    component: AcademicEnrollmentCreate
  },
  {
    path: "/academico/matriculas/pesquisar",
    name: "AcademicEnrollmentSearch",
    component: AcademicEnrollmentSearch
  },
  {
    path: "/academico/alunos",
    name: "AcademicStudent",
    component: AcademicStudent
  },
  {
    path: "/academico/alunos/inserir",
    name: "AcademicStudentCreate",
    component: AcademicStudentCreate
  },
  {
    path: "/academico/alunos/:id",
    name: "AcademicStudentCreate",
    component: AcademicStudentCreate
  },
  {
    path: "/academico/alunos/pesquisar",
    name: "AcademicStudentSearch",
    component: AcademicStudentSearch
  },
  {
    path: "/secretaria/unidades",
    name: "SecretaryUnits",
    component: SecretaryUnits
  },
  {
    path: "/secretaria/unidades/inserir",
    name: "SecretaryUnitsCreate",
    component: SecretaryUnitsCreate
  },
  {
    path: "/secretaria/unidades/:id",
    name: "SecretaryUnitsView",
    component: SecretaryUnitsView
  },
  {
    path: "/secretaria/cursos",
    name: "SecretaryCourses",
    component: SecretaryCourses
  },
  {
    path: "/secretaria/cursos/inserir",
    name: "SecretaryCoursesCreate",
    component: SecretaryCoursesCreate
  },
  {
    path: "/secretaria/empresas",
    name: "SecretaryCompanies",
    component: SecretaryCompanies
  },
  {
    path: "/secretaria/empresas/inserir",
    name: "SecretaryCompaniesCreate",
    component: SecretaryCompaniesCreate
  },
  {
    path: "/secretaria/empresas/:id",
    name: "SecretaryCompaniesView",
    component: SecretaryCompaniesView
  },
  {
    path: "/secretaria/series",
    name: "SecretarySeriesView",
    component: SecretarySeriesView
  },
  {
    path: "/secretaria/series/inserir",
    name: "SecretarySeriesCreate",
    component: SecretarySeriesCreate
  },
  {
    path: "/secretaria/series/:id",
    name: "SecretarySeriesCreate",
    component: SecretarySeriesCreate
  },
  {
    path: "/secretaria/turmas",
    name: "SecretaryClassView",
    component: SecretaryClassView
  },
  {
    path: "/secretaria/turmas/inserir",
    name: "SecretaryClassCreate",
    component: SecretaryClassCreate
  },
  {
    path: "/secretaria/turmas/:id",
    name: "SecretaryClassCreate",
    component: SecretaryClassCreate
  }
];

export default routes;
