<template>
  <div class="drawer-item" @click="onClickItem">
    <div class="list-tile">
      <div class="icon-wrapper">
        <img class="icon-image" :src="require(`../assets/icons/${icon}`)" />
      </div>
      <span class="drawer-text" :class="{ selected: isSubItemSelected }">{{ title }}</span>
    </div>
    <span v-if="hasSublist" class="material-icons expand-icon" :class="[{ 'is-open': isOpen }]">
      expand_more
    </span>
  </div>
  <div v-if="hasSublist" class="sublist-body" :class="[{ 'is-closed': !isOpen }]">
    <div
      v-for="item of subList"
      :class="{ selected: item.href === currentPath }"
      :key="item.name"
      class="sublist-item"
      @click="goToLink(item.href)"
    >
      <span class="drawer-text">{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "DashboardDrawerItem",
  props: {
    title: {
      type: String,
      required: true
    },
    subList: {
      type: Array,
      default: []
    },
    icon: {
      type: String,
      required: true
    },
    href: {
      type: String
    }
  },
  setup(props) {
    const router = useRouter();
    const isOpen = ref(false);

    const currentPath = router.currentRoute.value.path;

    const isSubItemSelected = computed(() => props.subList.some(i => i.href === currentPath));
    const hasSublist = computed(() => props.subList.length > 0);

    const onClickItem = () => {
      if (props.subList.length === 0 && props.href) {
        goToLink(props.href);
        return;
      }

      isOpen.value = !isOpen.value;
    };

    const goToLink = link => {
      router.push(link);
    };

    onMounted(() => {
      if (isSubItemSelected.value) {
        isOpen.value = true;
      }
    });

    return { currentPath, isSubItemSelected, isOpen, hasSublist, onClickItem, goToLink };
  }
};
</script>

<style lang="scss" scoped>
.drawer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  user-select: none;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  background-color: transparentize($color: $primary-color, $amount: 0.88);
  margin-right: 1rem;
}

.icon-image {
  width: 100%;
  max-width: 1rem;
}

.drawer-text {
  font-weight: 500;
  color: #656565;
  font-size: 0.875rem;

  &.selected {
    color: $primary-color;
    font-weight: 600;
  }
}

.list-tile {
  display: flex;
  align-items: center;
}

.expand-icon {
  user-select: none;
  color: $primary-color;
  font-size: 1.125rem;
  transition: 0.1s transform;
}

.is-open {
  &.expand-icon {
    transform: rotate(180deg);
  }
}

.sublist-body {
  padding: 0;
  max-height: 20rem;
  overflow: hidden;
  transition: 0.5s all;

  &.is-closed {
    max-height: 0;
  }
}

.sublist-item {
  & {
    padding: 0.75rem;
    padding-left: 4.5rem;
    cursor: pointer;
    user-select: none;

    &.selected {
      & {
        background-color: transparentize($color: $primary-color, $amount: 0.94);
      }

      & .drawer-text {
        color: $primary-color;
        font-weight: 600;
      }
    }
  }

  &:hover {
    background-color: transparentize($color: $primary-color, $amount: 0.94);
  }
}
</style>
