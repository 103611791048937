<template>
  <template-dashboard>
    <dashboard-title title="Empresas">
      <app-button
        @click="$router.push('/secretaria/empresas/inserir')"
        left-icon="add"
        class="w-auto yellow"
        mini
        >Inserir</app-button
      >
    </dashboard-title>
    <main v-if="!loading">
      <p v-if="companies.length === 0" class="empty-message">
        Nenhuma empresa cadastrada
      </p>
      <div v-else class="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
        <article
          class="p-4 bg-white rounded border-2 border-black border-opacity-5"
          v-for="c in companies"
          :key="c.id"
        >
          <h1 class="text-primary font-medium">
            {{ c.pessoa.nome_social || c.pessoa.nome }}
          </h1>
          <h3 class="text-black text-opacity-40 text-xs">CNPJ</h3>
          <p>{{ c.pessoa.cnpj }}</p>
          <div class="flex justify-end">
            <app-dropdown>
              <span class="material-icons card-actions-icon">more_vert</span>
              <template #content>
                <app-dropdown-item
                  @click="$router.push(`/secretaria/empresas/${c.id}`)"
                >
                  Editar
                </app-dropdown-item>
                <app-dropdown-item @click="removeCompany(c.id)">
                  Excluir
                </app-dropdown-item>
              </template>
            </app-dropdown>
          </div>
        </article>
      </div>
    </main>
  </template-dashboard>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppButton from "@/components/AppButton.vue";
import AppDropdown from "@/components/AppDropdown.vue";
import AppDropdownItem from "@/components/AppDropdownItem.vue";
import DashboardItemCard from "@/components/DashboardItemCard.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";
import { SecretaryCompanyRepository } from "@/api/Repositories";

export default defineComponent({
  components: {
    AppDropdown,
    AppDropdownItem,
    TemplateDashboard,
    AppButton,
    DashboardItemCard,
    DashboardTitle,
  },
  name: "SecretaryCompanies",
  setup() {
    const companies = ref<Company[]>([]);
    const loading = ref(true);

    onMounted(async () => {
      loading.value = true;
      companies.value = await SecretaryCompanyRepository.getAll();
      loading.value = false;
    });

    const removeCompany = async (id: number) => {
      companies.value = companies.value.filter((c) => c.id !== id);
      SecretaryCompanyRepository.delete(id).then(async () => {
        companies.value = await SecretaryCompanyRepository.getAll();
      });
    };

    return { companies, removeCompany, loading };
  },
});
</script>

<style scoped>
.empty-message {
  @apply text-2xl font-semibold text-primary;
}
</style>
