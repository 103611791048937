import { boolean, number, object, string } from "yup";
import { phoneCellphone } from "../regex";

const contactSchema = object().shape({
  principal: boolean()
    .nullable()
    .label("Principal"),
  email: string()
    .email()
    .required()
    .label("E-mail"),
  ddd: number()
    .min(0)
    .max(99)
    .required()
    .label("DDD"),
  telefone: string()
    .matches(phoneCellphone)
    .required()
    .label("Telefone"),
  nome_contato: string()
    .required()
    .label("Nome para contato"),
  tipo_contato_id: number()
    .positive()
    .required()
    .label("Tipo do contato")
});

export default contactSchema;
