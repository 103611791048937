import { AddressRepository, UtilsRepository } from "@/api/Repositories";
import { onMounted, ref } from "vue";

type SelectOption = {
  label: string;
  value: number;
};

type useAddressParams = {
  setFieldValue: (field: any, value: any) => void;
  prefix: string;
}

export default function useAddress({ setFieldValue, prefix }: useAddressParams) {
  const states = ref<Uf[]>([]);
  const cities = ref<City[]>([]);
  const statesOptions = ref<SelectOption[]>([]);
  const cityOptions = ref<SelectOption[]>([]);

  onMounted(async () => {
    const stateResult = await UtilsRepository.listUf()
    states.value = stateResult;

    statesOptions.value = stateResult.map((option: Uf) => ({
      label: option.nome,
      value: option.id,
    }));
  });

  const handleGetCities = async (value: number) => {
    cities.value = await UtilsRepository.listCities(value);
    cityOptions.value = cities.value.map((city) => ({
      label: city.nome,
      value: city.id,
    }));

    return cities;
  };

  const handleGetCep = async (e: Event) => {
    const { value: cep } = e.target as HTMLInputElement;
    if (cep.length !== 9) return;

    const cepFieldValue = await AddressRepository.getAddress(cep);

    const matchState = states.value.find((s) => s.sigla === cepFieldValue.state);
    if (!matchState) return;

    const cities = await handleGetCities(matchState.id);
    const matchCity = cities.value.find((c) => c.cod_ibge === cepFieldValue.ibge);

    setFieldValue(`${prefix}.cidade_id`, matchCity?.id);
    setFieldValue(`${prefix}.bairro`, cepFieldValue.neighborhood);
    setFieldValue(`${prefix}.uf`, matchState.id);
    setFieldValue(`${prefix}.logradouro`, cepFieldValue.street);
  };

  return { statesOptions, cityOptions, handleGetCities, handleGetCep, states, cities };
}
