const permissions = [
  { funcao_id: 1, permissoes: ["VER", "INSERIR", "EDITAR", "EXCLUIR"] },
  { funcao_id: 2, permissoes: ["VER", "INSERIR", "EDITAR", "EXCLUIR"] },
  { funcao_id: 3, permissoes: ["VER", "INSERIR", "EDITAR", "EXCLUIR"] },
  { funcao_id: 4, permissoes: ["VER", "INSERIR", "EDITAR", "EXCLUIR"] },
  { funcao_id: 5, permissoes: ["VER", "INSERIR", "EDITAR", "EXCLUIR"] },
  { funcao_id: 6, permissoes: ["VER", "INSERIR", "EDITAR", "EXCLUIR"] },
  { funcao_id: 7, permissoes: ["VER", "INSERIR", "EDITAR", "EXCLUIR"] },
  { funcao_id: 8, permissoes: ["VER", "INSERIR", "EDITAR", "EXCLUIR"] },
  { funcao_id: 9, permissoes: ["VER", "INSERIR", "EDITAR", "EXCLUIR"] },
  { funcao_id: 10, permissoes: ["VER", "INSERIR", "EDITAR", "EXCLUIR"] },
  { funcao_id: 11, permissoes: ["VER", "INSERIR", "EDITAR", "EXCLUIR"] },
  { funcao_id: 12, permissoes: ["VER", "INSERIR", "EDITAR", "EXCLUIR"] }
];

export default permissions;
