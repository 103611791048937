<template>
  <template-dashboard>
    <div class="relative">
      <dashboard-title title="Cadastrar Pessoas">
        <div class="flex flex-wrap justify-end gap-4 sm:flex-nowrap">
          <app-button
            class="w-auto border-black border-opacity-10 text-primary"
            left-icon="list"
            mini
            @click="$router.push('/academico/alunos')"
            >Listar</app-button
          >
          <app-button
            class="w-auto border-black border-opacity-10 text-primary"
            left-icon="search"
            mini
            @click="$router.push('/academico/alunos/pesquisar')"
            >Pesquisar</app-button
          >
        </div>
      </dashboard-title>
      <main>
        <h2 class="mt-4 text-2xl font-semibold text-primary">Informações básicas</h2>
        <form @submit="onSubmit">
          <simple-text-field type="hidden" name="tipo_pessoa" value="PF" />
          <div class="grid grid-cols-1 gap-4 mt-4 md:grid-cols-5">
            <div class="grid flex-1 grid-cols-1 col-span-4 gap-4">
              <simple-text-field name="rm" class="md:w-1/2" placeholder="RM" />
              <simple-text-field name="cpf" class="md:w-1/2" placeholder="CPF" :mask="cpfMask" />
              <simple-text-field name="nome" placeholder="Nome" />
              <simple-text-field name="nome_social" placeholder="Nome Social" />
            </div>
            <dashboard-upload-card
              class="w-full h-full col-span-4 md:col-span-1"
              full-height
              label="Foto"
              name="photo"
            />
          </div>
          <div class="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2">
            <app-select multi :options="rolesOptions" name="roles" label="Cargos" />
            <simple-text-field
              name="data_nascimento"
              placeholder="Data de Nascimento"
              :mask="dateMask"
            />
          </div>
          <div class="mt-10">
            <p class="mb-6 text-2xl font-semibold text-primary">Endereços</p>
            <template-information-card :headers="addressHeaders">
              <tr v-for="(e, idx) in values?.enderecos" :key="e.id">
                <td>
                  <radio-button
                    name="endereco_principal"
                    :value="idx"
                    compact
                    @change="changePrimaryAddress"
                  />
                </td>
                <td>{{ e.cidade.nome }}</td>
                <td>{{ e.cidade.uf.sigla }}</td>
                <td>{{ e.logradouro }}, {{ e.numero }}, {{ e.bairro }}</td>
                <td>{{ e.cep }}</td>
                <td>
                  <app-dropdown>
                    <span class="material-icons card-actions-icon">more_vert</span>
                    <template #content>
                      <app-dropdown-item @click="showAddressForm(idx)">
                        Editar
                      </app-dropdown-item>
                      <app-dropdown-item @click="removeAddress(idx)">Excluir</app-dropdown-item>
                    </template>
                  </app-dropdown>
                </td>
              </tr>
            </template-information-card>

            <app-button
              type="button"
              left-icon="add"
              mini
              class="w-auto mt-5 yellow"
              @click="showAddressForm(null)"
              :disabled="addressShow"
              >Endereço</app-button
            >

            <div v-if="addressShow" class="grid grid-cols-1 gap-4 mt-4 md:grid-cols-4">
              <simple-text-field
                :name="`endereco_tmp.cep`"
                placeholder="CEP"
                class="col-span-4 md:col-span-2"
                :mask="zipCodeMask"
                @keyup="handleGetCep"
              />

              <div class="grid grid-cols-1 col-span-4 gap-4 md:grid-cols-4">
                <simple-text-field
                  :name="`endereco_tmp.logradouro`"
                  class="col-span-4 md:col-span-3"
                  placeholder="Endereço"
                />
                <simple-text-field
                  class="col-span-4 md:col-span-1"
                  :name="`endereco_tmp.numero`"
                  placeholder="Número"
                />
              </div>
              <simple-text-field
                :name="`endereco_tmp.bairro`"
                placeholder="Bairro"
                class="col-span-4 md:col-span-2"
              />
              <simple-text-field
                :name="`endereco_tmp.complemento`"
                placeholder="Complemento"
                class="col-span-4 md:col-span-2"
              />
              <app-select
                :name="`endereco_tmp.cidade_id`"
                class="col-span-4 md:col-span-3"
                label="Cidade"
                compact
                :options="cityOptions"
              />
              <app-select
                :name="`endereco_tmp.uf`"
                class="col-span-4 md:col-span-1"
                label="UF"
                @change="handleGetCities"
                compact
                :options="statesOptions"
              />

              <div class="flex justify-between col-span-4">
                <app-button
                  type="button"
                  left-icon="arrow_back"
                  mini
                  class="w-auto back"
                  @click="clearAddress"
                >
                  Cancelar
                </app-button>

                <app-button
                  type="button"
                  left-icon="add"
                  mini
                  class="w-auto yellow"
                  @click="saveAddress"
                >
                  Salvar
                </app-button>
              </div>
            </div>
          </div>

          <div class="mt-10">
            <p class="mb-6 text-2xl font-semibold text-primary">Contatos</p>
            <template-information-card :headers="contactHeaders">
              <tr v-for="(c, idx) in values?.contatos" :key="c.id">
                <td>
                  <radio-button
                    :name="`contato_principal`"
                    :value="idx"
                    compact
                    @change="changePrimaryContact"
                  />
                </td>
                <td>
                  {{ (contactTypeOptions.find(op => op.value === c.tipo_contato_id) ?? {}).label }}
                </td>
                <td>{{ c.nome_contato }}</td>
                <td>{{ c.email }}</td>
                <td>{{ c.ddd }}</td>
                <td>{{ c.telefone }}</td>
                <td>
                  <app-dropdown>
                    <span class="material-icons card-actions-icon">more_vert</span>
                    <template #content>
                      <app-dropdown-item @click="showContactForm(idx)">
                        Editar
                      </app-dropdown-item>
                      <app-dropdown-item @click="removeContact(idx)">Excluir</app-dropdown-item>
                    </template>
                  </app-dropdown>
                </td>
              </tr>
            </template-information-card>

            <app-button
              type="button"
              left-icon="add"
              mini
              class="w-auto mt-5 yellow"
              @click="showContactForm(null)"
              :disabled="contactShow"
            >
              Contato
            </app-button>

            <div v-if="contactShow" class="grid grid-cols-1 gap-4 mt-4 md:grid-cols-4">
              <simple-text-field
                :name="`contato_tmp.nome_contato`"
                class="col-span-4 md:col-span-2"
                placeholder="Contato"
              />

              <app-select
                :name="`contato_tmp.tipo_contato_id`"
                label="Tipo"
                class="col-span-4 md:col-span-2"
                :options="contactTypeOptions"
              />

              <simple-text-field
                :name="`contato_tmp.email`"
                class="col-span-4 md:col-span-2"
                placeholder="Email"
              />

              <div class="grid w-full grid-cols-3 col-span-4 gap-4 md:col-span-2">
                <simple-text-field
                  :name="`contato_tmp.ddd`"
                  class="col-span-1"
                  placeholder="DDD"
                  :mask="dddMask"
                />

                <simple-text-field
                  :name="`contato_tmp.telefone`"
                  class="col-span-2"
                  placeholder="Telefone"
                  :mask="phoneCellphoneMask"
                />
              </div>

              <div class="flex justify-between col-span-4">
                <app-button
                  type="button"
                  left-icon="arrow_back"
                  mini
                  class="w-auto back"
                  @click="clearContact"
                >
                  Cancelar
                </app-button>

                <app-button
                  type="button"
                  left-icon="add"
                  mini
                  class="w-auto yellow"
                  @click="saveContact"
                >
                  Salvar
                </app-button>
              </div>
            </div>
          </div>
          <div class="mt-10">
            <p class="mb-6 text-2xl font-semibold text-primary">Responsáveis</p>
            <template-information-card :headers="responsablePersonHeaders">
              <tr v-for="(rp, idx) in responsablePersons" :key="rp.id">
                <td>
                  <!-- {{
                    responsablePersonTypes.find(op => op.id === rp.tipo_relacionamento_id)?.descricao
                  }} -->
                </td>
                <td>
                  <!-- {{
                    contactTypeOptions.find(op => op.value === rp.contatos[0].tipo_contato_id)?.label
                  }} -->
                </td>
                <td>{{ rp.nome }}</td>
               <td>
                 <!-- {{ rp.contatos[0].email }} -->
                </td>
                <td>
                  <!-- {{ rp.contatos[0].ddd }} -->
                </td>
                <td>
                  <!-- {{ rp.contatos[0].telefone }} -->
                </td>
                <td>
                  <app-dropdown>
                    <span class="material-icons card-actions-icon">more_vert</span>
                    <template #content>
                      <app-dropdown-item>
                        Editar
                      </app-dropdown-item>
                      <app-dropdown-item @click="removeResponsablePerson(idx)"
                        >Excluir</app-dropdown-item
                      >
                    </template>
                  </app-dropdown>
                </td>
                <simple-text-field
                  type="hidden"
                  :name="`relacionamentos[${idx}].tipo_relacionamento_id`"
                  :value="rp.tipo_relacionamento_id"
                />
                <simple-text-field
                  type="hidden"
                  :name="`relacionamentos[${idx}].pessoa_relacionada_id`"
                  :value="rp.id"
                />
              </tr>
            </template-information-card>

            <app-button
              type="button"
              left-icon="add"
              mini
              class="w-auto mt-5 yellow"
              @click="responsablePersonModalOpen = true"
            >
              Contato
            </app-button>
          </div>
          <div class="col-span-2 pt-4 mt-10 border-t border-primary border-opacity-10">
            <app-button class="w-auto ml-auto yellow" mini leftIcon="save">Salvar</app-button>
          </div>
        </form>
      </main>
      <responsable-person-modal
        :is-open="responsablePersonModalOpen"
        @close="responsablePersonModalOpen = false"
        @save="getResponsablePerson"
      />
    </div>
  </template-dashboard>
</template>

<script lang="ts">
import { useForm } from "vee-validate";

import TemplateDashboard from "../components/TemplateDashboard.vue";
import DashboardTitle from "../components/DashboardTitle.vue";
import AppButton from "../components/AppButton.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import DashboardUploadCard from "@/components/DashboardUploadCard.vue";
import AppSelect from "@/components/AppSelect.vue";
import TemplateInformationCard from "@/components/TemplateInformationCard.vue";
import RadioButton from "../components/RadioButton.vue";
import AppDropdown from "../components/AppDropdown.vue";
import AppDropdownItem from "../components/AppDropdownItem.vue";
import useEditAddress from "@/utils/composables/editAddress";
import useEditContact from "@/utils/composables/editContact";
import ResponsablePersonModal from "@/components/ResponsablePersonModal.vue";
import { onMounted, ref } from "vue";
import { cpfMask, dateMask } from "@/utils/validations/mask";
import studentSchema from "@/utils/validations/yup/student";
import { AnyObjectSchema } from "yup";
import { AcademicStudentRepository, FileRepository } from "@/api/Repositories";
import { useRoute } from "vue-router";

export default {
  components: {
    TemplateDashboard,
    DashboardTitle,
    AppButton,
    SimpleTextField,
    DashboardUploadCard,
    AppSelect,
    TemplateInformationCard,
    RadioButton,
    ResponsablePersonModal,
    AppDropdown,
    AppDropdownItem
  },
  setup() {
    const route = useRoute();
    const { id: studentId } = route.params;
    const student = ref({} as Person);

    const { setFieldValue, setValues, values, errors, handleSubmit } = useForm({
      validationSchema: studentSchema as AnyObjectSchema
    });
    const responsablePersons = ref<ResponsablePerson[]>([]);

    const editAddress = useEditAddress({
      formPrefix: "enderecos",
      prefix: "endereco_tmp",
      setFieldValue,
      values
    });

    const editContact = useEditContact({
      formPrefix: "contatos",
      prefix: "contato_tmp",
      setFieldValue,
      values
    });

    const rolesOptions: SelectOptionType[] = [
      { value: "ALUNO", label: "Aluno" },
      { value: "RESPONSÁVEL", label: "Responsável" }
    ];

    const responsablePersonModalOpen = ref(false);

    const responsablePersonHeaders = ["Descrição", "Tipo", "Contato", "Email", "DDD", "Telefone"];

    const getResponsablePerson = (responsable: ResponsablePerson) => {
      responsablePersons.value.push(responsable);
    };

    const responsablePersonTypes = ref<RelationshipType[]>([]);

    const removeResponsablePerson = (idx: number) => {
      responsablePersons.value.splice(idx, 1);
    };

    onMounted(async () => {

      responsablePersonTypes.value = await AcademicStudentRepository.getRelationshipTypes();

      student.value = await AcademicStudentRepository.get(String(studentId));
      responsablePersons.value = student.value.pessoas_relacionamento?.map((p) => {
        return ({ ...p.pessoa_relacionada, tipo_relacionamento_id: p.pessoa_relacionada_id.toString() })
      })
      setValues({
        ...student.value,
        roles: student.value.roles.map((r) => r.codigo),
      });

      setFieldValue(
        'endereco_principal',
        student.value.enderecos.findIndex(e => !!e.principal)
      );

      setFieldValue(
        'contato_principal',
        student.value.contatos.findIndex(c => !!c.principal)
      );

      setFieldValue(
        'contato_principal',
        student.value.contatos.findIndex(c => !!c.principal)
      );
    });

    const onSubmit = handleSubmit(async data => {
      console.log(data);

      const photo = data.photo[0]
        ? (await FileRepository.savePhoto({ file: data.photo[0] })).id
        : "";

      const payload = { id: null, imagem_id: photo, documentos: [], formulario: [], ...data };

      AcademicStudentRepository.create(payload).then(response => {
        console.log(response);
      });
    });

    return {
      ...editAddress,
      ...editContact,
      values,
      responsablePersonModalOpen,
      rolesOptions,
      getResponsablePerson,
      responsablePersonHeaders,
      responsablePersons,
      responsablePersonTypes,
      cpfMask,
      dateMask,
      onSubmit,
      errors,
      removeResponsablePerson
    };
  }
};
</script>
