import { nextTick, ref, watch } from "vue";
import useContactTypes from "./contact_type";
import { phoneCellphoneMask, dddMask } from "@/utils/validations/mask";

type EditContactProps = {
  formPrefix: string;
  prefix: string;
  setFieldValue: (field: any, value: any) => void;
  values: Record<string, any>;
};

type contactIdxType = number | null;

export default function useEditContact({
  formPrefix,
  prefix,
  setFieldValue,
  values
}: EditContactProps) {
  const contactIdx = ref<contactIdxType>(null);
  const contactShow = ref(false);
  const formValue = ref<CompanyContact[]>([]);
  const contactHeaders = ["Principal", "Tipo", "Contato", "Email", "DDD", "Telefone", ""];

  const contactTypes = useContactTypes();

  watch(values, () => {
    if (formPrefix.split(".").length <= 1) {
      formValue.value = values[formPrefix] ?? [];
      return;
    }

    formValue.value = formPrefix
      .split(".")
      .reduce((prev, curr: string) => (prev ? prev[curr] : []), values) as CompanyContact[];
    [];
  });

  const showContactForm = (idx: contactIdxType) => {
    contactShow.value = true;
    contactIdx.value = idx;

    if (idx !== null) {
      const contactEdit = { ...formValue.value[idx] };
      nextTick(() => {
        setFieldValue(prefix, contactEdit);
      });
    }
  };

  const clearContact = () => {
    contactIdx.value = null;
    contactShow.value = false;
    setFieldValue(prefix, {});
  };

  const changePrimaryContact = (e: Event) => {
    const newIdx = Number((e.target as HTMLInputElement).value);

    const newContacts = formValue.value.map((c, idx) => {
      c.principal = idx === newIdx;
      return c;
    });

    setFieldValue(formPrefix, newContacts);
  };

  const saveContact = async () => {
    const idx = contactIdx.value || formValue.value.length;

    setFieldValue(`${formPrefix}[${idx}]`, {
      ...values[prefix]
    });
    clearContact();
  };

  const removeContact = (idx: number) => {
    const newContacts = [...formValue.value].filter((_, index) => index !== idx);
    setFieldValue(formPrefix, newContacts);
  };

  return {
    ...contactTypes,
    contactIdx,
    showContactForm,
    contactHeaders,
    clearContact,
    contactShow,
    saveContact,
    phoneCellphoneMask,
    dddMask,
    changePrimaryContact,
    removeContact
  };
}
