<template>
  <div class="wrapper">
    <input
      :name="name"
      :value="inputValue"
      @input="handleChange(true)"
      class="sr-only"
      type="checkbox"
      :id="name"
    />

    <label class="text-primary font-medium text-base label-svg" :for="name">
      <svg viewBox="0 0 100 100">
        <path
          class="path"
          fill="none"
          stroke="#000"
          stroke-width="13"
          d="M12.1 52.1l24.4 24.4 53-53"
        />
      </svg>
      {{ label }}
    </label>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {},
  },
  setup(props) {
    const { handleChange, value: inputValue } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value,
        type: "checkbox",
        checkedValue: true,
        uncheckedValue: false,
      }
    );

    return { handleChange, inputValue };
  },
});
</script>

<style scoped>
label {
  @apply select-none;
}

.wrapper {
  @apply relative;
}

.wrapper input:checked + label::before {
  @apply bg-primary border-primary;
}

.wrapper input:checked + label .path {
  stroke: #fff;
  stroke-dashoffset: 0;
}

label {
  @apply flex pl-8;
}

label::before {
  content: "";
  @apply inline-block align-middle w-4 h-4 border-2 border-primary mr-2 rounded-sm;
}

label::before {
  @apply absolute left-0 w-6 h-6 transition-all duration-300 ease-out;
}

label svg {
  @apply absolute left-1 top-1 w-4 h-4 pointer-events-none;
}

label .path {
  stroke-dashoffset: 111.46px;
  stroke-dasharray: 111.46px;
  stroke: #3863d9;
  @apply transition-all duration-300 ease-out;
}

.sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  @apply absolute p-0 border-0 h-0 w-0 overflow-hidden;
}
</style>
