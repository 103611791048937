
import { defineComponent, onMounted, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppButton from "@/components/AppButton.vue";
import TemplateSecretaryFilters from "@/components/TemplateSecretaryFilters.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import {
  SecretaryClassRepository,
  SecretaryCompanyRepository,
  SecretaryUnitRepository,
} from "@/api/Repositories";
import semesterOptions from "@/utils/mocks/semester";
import { useForm } from "vee-validate";
import schema from "@/utils/validations/class";
import { useRoute, useRouter } from "vue-router";
import usePeriodType from "@/utils/composables/period_type";
import useCourseType from "@/utils/composables/course_type";
import useSeriesType from "@/utils/composables/series_type";
import DashboardTitle from "@/components/DashboardTitle.vue";

export default defineComponent({
  components: {
    TemplateDashboard,
    AppSelect,
    AppButton,
    TemplateSecretaryFilters,
    SimpleTextField,
    DashboardTitle,
  },
  name: "SecretaryClassCreate",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const periodOptions = usePeriodType();
    const courseOptions = useCourseType();
    const situationOptions = ref<SelectOptionType[]>([]);
    const unityOptions = ref<SelectOptionType[]>([]);
    const companyOptions = ref<SelectOptionType[]>([]);
    const { getSeriesByCourse, seriesOptions } = useSeriesType();
    const editId = ref();

    const {
      handleSubmit,
      meta: formMeta,
      setValues,
    } = useForm({
      validationSchema: schema,
    });

    onMounted(async () => {
      editId.value = Number(route.params.id);

      situationOptions.value = (
        await SecretaryClassRepository.getSituations()
      ).map((s) => ({
        label: s.descricao,
        value: s.id,
      }));

      unityOptions.value = (await SecretaryUnitRepository.list()).map((u) => ({
        label: u.nome,
        value: u.id,
      }));

      companyOptions.value = (await SecretaryCompanyRepository.getAll()).map(
        (c) => ({
          label: c.pessoa.nome_social || c.pessoa.nome,
          value: c.id!,
        })
      );

      if (!editId.value) {
        return;
      }

      const formValues = await SecretaryClassRepository.getClassById(
        editId.value
      );

      await getSeriesByCourse(formValues.curso_id);

      setValues({
        id: formValues.id,
        descricao: formValues.descricao,
        periodo_id: formValues.periodo_id,
        ano: formValues.ano,
        situacao_id: formValues.situacao_id,
        curso_id: formValues.curso_id,
        serie_id: formValues.serie_id,
        unidade_id: formValues.unidade_id,
        empresa_id: formValues.empresa_id,
        vagas: formValues.vagas,
        ordem: formValues.ordem,
        data_inicio: formValues.data_inicio,
        data_termino: formValues.data_termino,
        hora_entrada: formValues.hora_entrada,
        hora_saida: formValues.hora_saida,
        dias_letivos: formValues.dias_letivos,
        semestre: formValues.semestre,
        carga_horaria: formValues.carga_horaria,
      });
    });

    const onSubmit = handleSubmit((values) => {
      if (!editId.value) {
        SecretaryClassRepository.create(values).then(() => {
          router.push("/secretaria/turmas");
        });

        return;
      }

      SecretaryClassRepository.update(editId.value, values).then(() => {
        console.log("entrou", editId.value);
        router.push("/secretaria/turmas");
      });
    });

    return {
      ...periodOptions,
      ...courseOptions,
      seriesOptions,
      situationOptions,
      unityOptions,
      semesterOptions,
      companyOptions,
      onSubmit,
      getSeriesByCourse,
    };
  },
});
