<template>
  <div class="flex">
    <label
      :for="name"
      class="font-medium mr-4 transition-all"
      :class="checked ? 'text-primary' : 'text-black text-opacity-40'"
    >
      {{ label }}
    </label>
    <div>
      <input
        @input="handleChange(true)"
        :value="true"
        :id="name"
        type="checkbox"
        class="checkbox"
      />
      <label :for="name" class="switch"></label>
    </div>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {},
  },

  setup(props) {
    const { handleChange, checked } = useField(props.name, null, {
      initialValue: props.value,
      type: "checkbox",
      checkedValue: true,
      uncheckedValue: false,
    });

    return { handleChange, checked };
  },
});
</script>

<style scoped>
label {
  @apply cursor-pointer select-none;
}

.switch {
  @apply relative inline-block w-10 h-4 transition-all rounded-full bg-black bg-opacity-20;
}

.switch::after {
  content: "";
  top: -4px;
  background: #A3A3A3;
  @apply absolute w-6 h-6 left-0 rounded-full transition-all;
}

.checkbox:checked + .switch::after {
  @apply left-5 bg-primary bg-opacity-100;
}

.checkbox:checked + .switch {
  @apply bg-primary bg-opacity-40;
}

.checkbox {
  @apply hidden;
}
</style>
