import Client from "../AxiosClient";

export default {
  async login(payload: Login) {
    return await Client.post("/e1-user-api/public/api/treinamentos/login", payload);
  },
  async register(payload: Record<string, string>) {
    return await Client.post("/e1-user-api/public/api/treinamentos/usuarios", payload);
  }
};
