<template>
  <div
    class="
      flex
      flex-wrap
      gap-6
      items-center
      justify-between
      pb-4
      mb-4
      border-b border-primary border-opacity-10
    "
  >
    <h1 class="text-2xl md:text-3xl font-semibold text-primary">{{ title }}</h1>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
  },
};
</script>
