import * as yup from "yup";
import { parseDateString } from "./format";
import { cnpj, cpf, phoneCellphone, zipCode } from "./regex";

const registerSchemas = (isNaturalPerson: boolean): Record<string, any> => ({
  code: yup.object().shape({
    code: yup
      .string()
      .length(28)
      .required()
      .label("Código")
  }),

  account: yup.object().shape({
    tipo_pessoa: yup.ref("pessoa.tipo_pessoa"),
    pessoa: yup.object().shape({
      tipo_pessoa: yup
        .string()
        .required()
        .oneOf(["PF", "PJ"])
        .label("Tipo Pessoa"),
      nome: yup
        .string()
        .required()
        .label("Nome")
        .when("tipo_pessoa", { is: "PJ", then: yup.string().label("Nome da Instituição") }),
      cpf: yup
        .string()
        .when("tipo_pessoa", {
          is: "PF",
          then: yup
            .string()
            .required()
            .matches(cpf, ({ label }) => `${label} Inválido`)
        })
        .label("CPF"),
      cnpj: yup
        .string()
        .when("tipo_pessoa", {
          is: "PJ",
          then: yup
            .string()
            .required()
            .matches(cnpj)
        })
        .label("CNPJ"),
      nome_social: yup
        .string()
        .optional()
        .label("Nome Social"),
      razao_social: yup
        .string()
        .when("tipo_pessoa", { is: "PJ", then: yup.string().required() })
        .label("Razão Social"),
      genero: yup
        .string()
        .when("tipo_pessoa", {
          is: "PF",
          then: yup
            .string()
            .required()
            .oneOf(["M", "F", "Prefiro não dizer"])
        })
        .label("Gênero"),
      data_nascimento: yup
        .date()
        .when("tipo_pessoa", {
          is: "PF",
          then: yup
            .date()
            .transform(parseDateString)
            .typeError(({ label }) => `${label} não é uma data válida`)
            .max(new Date(), ({ label }) => `a ${label} tem que ser antes do dia de hoje`)
            .required()
        })
        .label("Data de Nascimento")
    }),
    contato: yup.object().when("tipo_pessoa", {
      is: "PF",
      then: yup.object().shape({
        email: yup
          .string()
          .email()
          .required()
          .label("E-mail"),
        ddd: yup
          .string()
          .required()
          .label("DDD"),
        telefone: yup
          .string()
          .required()
          .matches(phoneCellphone, ({ label }) => `${label} Inválido`)
          .label("Telefone")
      })
    })
  }),

  address: yup.object().shape({
    endereco: yup.object().shape({
      cep: yup
        .string()
        .required()
        .matches(zipCode)
        .label("CEP"),
      endereco: yup
        .string()
        .required()
        .label("Endereço"),
      numero: yup
        .number()
        .required()
        .typeError(({ label }) => `${label} Inválido`)
        .label("Número"),
      complemento: yup
        .string()
        .optional()
        .label("Complemento"),
      bairro: yup
        .string()
        .required()
        .label("Bairro"),
      cidade: yup
        .string()
        .required()
        .label("Cidade"),
      estado: yup
        .string()
        .required()
        .label("Estado")
    })
  }),

  "responsible-person": yup.object().shape({
    contato_responsavel: yup.object().shape({
      nome_contato: yup
        .string()
        .required()
        .label(isNaturalPerson ? "Nome do responsável" : "Nome de contato"),
      cpf: yup
        .string()
        .when("nome_contato", {
          is: () => isNaturalPerson,
          then: yup
            .string()
            .required()
            .matches(cpf, ({ label }) => `${label} Inválido`)
        })
        .label("CPF"),
      // grau_parentesco: yup
      //   .string()
      //   .when("tipo_pessoa", {
      //     is: () => isNaturalPerson,
      //     then: yup.string().required()
      //   })
      //   .label("Grau de parentesco"),
      email: yup
        .string()
        .email()
        .required()
        .label("E-mail"),
      ddd: yup
        .string()
        .required()
        .label("DDD"),
      telefone: yup
        .string()
        .required()
        .matches(phoneCellphone, ({ label }) => `${label} Inválido`)
        .label("Telefone")
    })
  }),

  password: yup.object().shape({
    senha: yup
      .string()
      .required()
      .label("Senha"),
    senha_confirmacao: yup
      .string()
      .required()
      .oneOf([yup.ref("senha")], ({ label }) => `${label} tem que ser igual a Senha`)
      .label("Confirmação de Senha")
  })
});

export default registerSchemas;
