<template>
  <section class="container">
    <h1 class="title">
      <slot name="title" />
    </h1>
    <h2 class="subtitle">
      <slot name="subtitle" />
    </h2>
    <div class="form-content">
      <div class="inputs">
        <slot name="form-content" />
      </div>
      <div class="buttons">
        <slot name="buttons" />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.container {
  max-width: $lg;
  height: 100%;
  margin: 0 auto;
}

.title {
  color: $primary-color;
  font-weight: bold;
  font-size: 2.25rem;
}

.subtitle {
  margin-top: $default-gap;
  color: transparentize($color: $black-color, $amount: 0.5);
  font-size: 1.25rem;
  font-weight: normal;
}

.form-content {
  & {
    margin-top: $default-gap;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & .buttons {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $default-gap;
  }

  @media (max-width: $sm) {
    .buttons {
      margin-top: 1rem;
      grid-template-columns: minmax(0, 1fr);
    }
  }

  @media (max-width: $lg) {
    .buttons {
      line-height: 3.375rem;
    }
  }
}

@media (min-width: $md) {
  .form-content {
    height: 100%;
  }
}
</style>
