<template>
  <error-message :error="error">
    <div>
      <input
        @input="$emit('update:modelValue', $event.target.value)"
        :value="modelValue"
        :type="inputType"
        :placeholder="placeholder"
        :required="required"
        :readonly="readonly"
        class="text-field"
        v-maska="mask"
      />
      <label>{{ label }}</label>
      <span
        @click="switchVisibility"
        v-if="type === 'password'"
        class="material-icons icon icon-right"
      >
        {{ inputType === "password" ? "visibility" : "visibility_off" }}
      </span>
      <span v-else-if="rightIcon" class="material-icons icon icon-right">{{ rightIcon }}</span>
    </div>
  </error-message>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { maska } from "maska";
import ErrorMessage from "./ErrorMessage.vue";

export default defineComponent({
  components: { ErrorMessage },
  name: "TextField",
  props: {
    name: {
      type: String,
      default: ""
    },
    modelValue: {},
    type: String,
    label: String,
    placeholder: String,
    required: String,
    readonly: String,
    rightIcon: String,
    error: {},
    mask: {}
  },
  data() {
    return {
      inputType: this.type
    };
  },
  methods: {
    switchVisibility() {
      this.inputType = this.inputType === "password" ? "text" : "password";
    }
  },

  directives: {
    maska
  }
});
</script>

<style scoped lang="scss">
div {
  width: 100%;
  display: inline-block;
  position: relative;
}

.text-field {
  width: 100%;
  padding: 36px 16px 16px 16px;
  border: 1px solid #ececec;
  font-size: 16px;
  border-radius: 12px;
  outline: none;
  color: $primary-color;
}

.text-field::placeholder {
  color: transparentize($color: #000000, $amount: 0.7);
}

label {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 13px;
  font-weight: 500;
  color: transparentize($color: #000000, $amount: 0.5);
  pointer-events: none;
  transition: all 0.3s ease;
}

.icon {
  font-size: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: transparentize($color: #0f233e, $amount: 0.7);
  user-select: none;
  &-right {
    right: 1rem;
  }
}
</style>
