const historic = [
  { label: "1° Ano", value: 1 },
  { label: "2° Ano", value: 2 },
  { label: "3° Ano", value: 3 },
  { label: "4° Ano", value: 4 },
  { label: "5° Ano", value: 5 },
  { label: "6° Ano", value: 6 },
  { label: "7° Ano", value: 7 },
  { label: "8° Ano", value: 8 },
  { label: "9° Ano", value: 9 },
  { label: "1 Médio", value: 21 },
  { label: "2 Médio", value: 22 },
  { label: "3 Médio", value: 23 },
];

export default historic;
