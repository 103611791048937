import { nextTick, ref, watch } from "vue";
import useAddress from "./address";
import { zipCodeMask } from "@/utils/validations/mask";

type EditAddressProps = {
  formPrefix: string;
  prefix: string;
  setFieldValue: (field: any, value: any) => void;
  values: Record<string, any>;
};

type addressIdxType = number | null;

export default function useEditAddress({
  formPrefix,
  prefix,
  setFieldValue,
  values
}: EditAddressProps) {
  const addressIdx = ref<addressIdxType>(null);
  const addressShow = ref(false);
  const formValue = ref<CompanyAddress[]>([]);
  const addressHeaders = ["Principal", "Cidade", "UF", "Endereço", "CEP", ""];

  const address = useAddress({
    setFieldValue,
    prefix
  });

  watch(values, () => {
    if (formPrefix.split(".").length <= 1) {
      formValue.value = values[formPrefix] ?? [];
      return;
    }

    formValue.value = formPrefix
      .split(".")
      .reduce((prev, curr: string) => (prev ? prev[curr] : []), values) as CompanyAddress[];
  });

  const changePrimaryAddress = (e: Event) => {
    const newIdx = Number((e.target as HTMLInputElement).value);

    const newAddresses = formValue.value.map((e, idx) => {
      e.principal = idx === newIdx;
      return e;
    });

    setFieldValue(formPrefix, newAddresses);
  };

  const showAddressForm = async (idx: addressIdxType) => {
    addressShow.value = true;
    addressIdx.value = idx;

    if (idx !== null) {
      const addressEdit = { ...formValue.value[idx] };
      await address.handleGetCities(addressEdit?.cidade?.uf_id!);
      nextTick(() => {
        setFieldValue(prefix, { ...addressEdit, uf: addressEdit?.cidade?.uf_id! });
      });
    }
  };

  const clearAddress = () => {
    addressIdx.value = null;
    addressShow.value = false;
    setFieldValue(prefix, {});
  };

  const saveAddress = async () => {
    const idx = addressIdx.value || formValue.value.length;

    const matchState = address.states.value.find(s => s.id === values[prefix].uf);
    if (!matchState) return;

    const matchCity = address.cities.value.find(c => c.id === values[prefix].cidade_id);

    setFieldValue(`${formPrefix}[${idx}]`, {
      ...values[prefix],
      cidade: {
        ...matchCity,
        uf: matchState
      }
    });
    clearAddress();
  };

  const removeAddress = (idx: number) => {
    const newAddresses = [...formValue.value].filter((_, index) => index !== idx);
    setFieldValue(formPrefix, newAddresses);
  };

  return {
    ...address,
    addressIdx,
    showAddressForm,
    addressHeaders,
    clearAddress,
    addressShow,
    saveAddress,
    zipCodeMask,
    changePrimaryAddress,
    removeAddress
  };
}
