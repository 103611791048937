import * as yup from "yup";

export default yup.object({
  descricao: yup.string().required().label("Descrição"),
  curso_id: yup.number().required().label("Segmento"),
  ordem: yup.number().required().label("Ordem"),
  proxima_serie_id: yup.number().required().label("Série"),
  codserie_historico: yup.number().required().label("Histórico"),
  proxima_serie_curso: yup.number().label("Segmento"),
});
