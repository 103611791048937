import Client from '../AxiosClient';

type EnrollmentFilterType = {
  serie_id?: number;
  turma_id?: number;
  page?: number;
  data_matricula_inicio?: string;
  data_matricula_fim?: string;
  instituicao_anterior_id?: number;
  ano?: number;
  paginar?: 'S' | 'N';
}

export default {
  async getAll(payload?: EnrollmentFilterType): Promise<RequestWithMeta<Enrollment>> {
    const { data } = await Client.get('/e1-matricula-api/public/api/treinamentos/matriculas', { params: payload });

    return data.data;
  },

  async create(payload: Record<string, unknown>): Promise<Enrollment[]> {
    const { data } = await Client.post('/e1-matricula-api/public/api/treinamentos/matriculas', payload);

    return data;
  },

  async get(id: string): Promise<Enrollment> {
    const { data } = await Client.get(`/e1-matricula-api/public/api/treinamentos/matriculas/${id}`);

    return data.data;
  },

  async edit(id: string, payload: Record<string, unknown>): Promise<Enrollment> {
    const { data } = await Client.put(
      `/e1-matricula-api/public/api/treinamentos/matriculas/${id}`,
      payload,
    );

    return data.data;
  },

  async getSituations(): Promise<Situation[]> {
    const { data } = await
    Client.get('/e1-matricula-api/public/api/treinamentos/matriculas/situacoes');

    return data.data;
  },
};
