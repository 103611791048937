<template>
  <nav class="nav">
    <div class="flex items-center w-full md:w-auto pl-4">
      <div
        class="
          lg:hidden
          material-icons
          icon
          text-2xl
          mr-6
          text-white
          cursor-pointer
        "
        @click="toggleDrawer"
      >
        menu
      </div>
      <div class="logo">
        <img src="@/assets/logo.svg" />
      </div>
    </div>
    <div class="search-area" @click="openInput">
      <p class="material-icons icon">search</p>
      <p class="search-text hidden md:block">Clique para pesquisar ou CTRL+B</p>
    </div>
    <div class="mobile-notification-area">
      <div class="notification">
        <p class="material-icons icon">notifications</p>
        <span class="badge">9+</span>
      </div>
    </div>
    <div class="notification-area">
      <div class="notification">
        <p class="material-icons icon">notifications</p>
        <span class="badge">9+</span>
      </div>
    </div>
    <div
      class="input-area"
      v-show="showInput"
      :class="[{ 'is-show': showInput }]"
    >
      <input
        ref="input"
        @blur="closeInput"
        class="search-input"
        placeholder="Digite o nome, CPF ou RM"
      />
      <span class="material-icons icon" @click="closeInput">close</span>
    </div>
  </nav>
</template>

<script>
import { defineComponent, onMounted, onUpdated, ref } from "vue";
export default defineComponent({
  name: "DashboardNavbar",
  props: {
    showInput: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const input = ref(null);

    const closeInput = () => {
      emit("closeInput");
    };

    onUpdated(() => {
      input.value.focus();
    });

    const toggleDrawer = () => {
      emit("toggleDrawer");
    };

    const openInput = () => {
      emit("openInput");
    };

    return { input, closeInput, openInput, toggleDrawer };
  },
});
</script>

<style lang="scss" scoped>
.nav {
  @apply fixed bg-primary w-full flex justify-between items-center z-50;
}

.logo {
  @apply py-6 lg:py-7;
}

.search-area {
  @apply flex items-center cursor-pointer pl-4 md:pb-0 md:pl-0;

  & .icon {
    user-select: none;
    @apply mr-4 text-2xl md:text-lg text-white;
  }

  & .search-text {
    font-size: 0.875rem;
    font-family: "Roboto", sans-serif;
    color: transparentize($color: $white-color, $amount: 0.2);
  }
}

.input-area {
  & {
    opacity: 0;
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
    transition: 0.4 all;
  }

  &.is-show {
    opacity: 1;
  }

  & input {
    & {
      @apply h-full w-full text-lg border-none outline-none;
    }

    &::placeholder {
      color: transparentize($color: #000000, $amount: 0.5);
    }
  }

  & .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
    font-size: 1.125rem;
    color: $black-color;
    user-select: none;
    cursor: pointer;
  }
}
.notification-area {
  @apply hidden md:flex justify-end md:pr-16 lg:pr-12;
}

.mobile-notification-area {
  @apply md:hidden w-auto pr-4 ml-auto md:ml-0;
}

.notification {
  & {
    background-color: transparentize($color: $white-color, $amount: 0.88);
    border-radius: 2rem;
    @apply relative w-10 h-10 flex justify-center items-center;
  }

  & .icon {
    color: $white-color;
    font-size: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
  }

  & .badge {
    font-size: 0.625rem;
    color: $white-color;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background-color: $red-color;
    padding: 0.25rem;
    border-radius: 1rem;
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
  }
}
</style>
