<template>
  <template-register-content>
    <template #title>Criar conta</template>
    <template #subtitle>
      <template v-if="isNaturalPerson">
        Agora, precisamos do seu endereço
      </template>
      <template v-else>
        Precisamos do endereço da instituição
      </template>
    </template>
    <template #form-content>
      <div class="row grid grid-2">
        <text-field
          name="endereco.cep"
          label="Qual o CEP?"
          mask="#####-###"
          v-model="zipcode"
          :error="zipcodeMeta.touched && errors['endereco.cep']"
        />
      </div>
      <div class="row flex">
        <text-field
          name="endereco.endereco"
          label="Qual o endereço?"
          class="flex-2"
          v-model="street"
          :error="streetMeta.touched && errors['endereco.endereco']"
        />
        <text-field
          name="endereco.numero"
          label="Número"
          mask="#*"
          class="flex-1"
          v-model="number"
          :error="numberMeta.touched && errors['endereco.numero']"
        />
      </div>
      <div class="row grid grid-2">
        <text-field
          name="endereco.complemento"
          label="Complemento"
          v-model="complement"
          :error="complementMeta.touched && errors['endereco.complemento']"
        />
        <text-field
          name="endereco.bairro"
          label="Bairro"
          v-model="neighborhood"
          :error="neighborhoodMeta.touched && errors['endereco.bairro']"
        />
      </div>
      <div class="row grid grid-2">
        <app-select
          :options="cities"
          v-model="city"
          label="Cidade"
          :error="cityMeta.touched && errors['endereco.cidade']"
        />
        <app-select
          :options="states"
          v-model="state"
          label="Estado"
          :error="stateMeta.touched && errors['endereco.estado']"
        />
      </div>
    </template>
    <template #buttons>
      <app-button type="button" @click="goBack" class="white-primary">
        Voltar
      </app-button>
      <app-button type="submit" class="primary" rightIcon="chevron_right">
        Continuar
      </app-button>
    </template>
  </template-register-content>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";

import TemplateRegisterContent from "@/components/TemplateRegisterContent.vue";
import AppButton from "@/components/AppButton.vue";
import TextField from "@/components/TextField.vue";
import AppSelect from "@/components/AppSelect.vue";
import { useField, useFormErrors, useFieldValue, useFormValues } from "vee-validate";

import { getCities, getStates, onlyNumbers } from "@brazilian-utils/brazilian-utils";
import { AddressRepository } from "@/api/Repositories";

export default defineComponent({
  components: { TemplateRegisterContent, AppButton, TextField, AppSelect },
  inject: ["goBack", "isNaturalPerson"],
  setup() {
    const states = ref([]);
    const cities = ref([]);

    const { value: state, meta: stateMeta } = useField("endereco.estado");
    const { value: city, meta: cityMeta } = useField("endereco.cidade");
    const { value: zipcode, meta: zipcodeMeta } = useField("endereco.cep", null);
    const { value: street, meta: streetMeta } = useField("endereco.endereco", null);
    const { value: complement, meta: complementMeta } = useField("endereco.complemento", null);
    const { value: number, meta: numberMeta } = useField("endereco.numero", null);
    const { value: neighborhood, meta: neighborhoodMeta } = useField("endereco.bairro", null);

    const errors = useFormErrors();
    const values = useFormValues();

    const cep = useFieldValue("endereco.cep");

    onMounted(() => {
      states.value = getStates().map(state => ({ value: state.code, label: state.name }));
    });

    watch(state, state => {
      cities.value = getCities(state).map(city => ({
        value: city,
        label: city
      }));
    });

    watch(cep, async cep => {
      if (cep.length !== 9) return;

      const rawValue = onlyNumbers(cep);

      const address = await AddressRepository.getAddress({ zipcode: rawValue });

      street.value = address.street;
      neighborhood.value = address.neighborhood;
      state.value = address.state;
      city.value = address.city;
    });

    return {
      errors,
      states,
      state,
      stateMeta,
      cities,
      city,
      cityMeta,
      zipcode,
      zipcodeMeta,
      street,
      streetMeta,
      complement,
      complementMeta,
      number,
      numberMeta,
      neighborhood,
      neighborhoodMeta
    };
  }
});
</script>

<style></style>
