import { CourseRepository } from "@/api/Repositories";
import { onMounted, ref } from "vue";
import { courseSelectMapper } from "../mappers/course";

export default function useCourseType() {
  const courseOptions = ref<SelectOptionType[]>([]);

  onMounted(async () => {
    courseOptions.value = courseSelectMapper(await CourseRepository.getAll())
  });

  return { courseOptions }
}
