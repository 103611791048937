import Client from '../AxiosClient';

export default {
  async getAll(): Promise<Company[]> {
    const { data } = await Client.get('/e1-secretaria-api/public/api/treinamentos/empresas');
    return data.data.data;
  },

  async create(payload: Record<string, any>): Promise<Company> {
    const { data } = await Client.post('/e1-secretaria-api/public/api/treinamentos/empresas', payload);
    return data.data;
  },

  async edit(id: number, payload: Record<string, any>): Promise<Company> {
    const { data } = await Client.put(`/e1-secretaria-api/public/api/treinamentos/empresas/${id}`, payload);
    return data.data;
  },

  async get(id: number): Promise<Company> {
    const { data } = await Client.get(`/e1-secretaria-api/public/api/treinamentos/empresas/${id}`);
    return data.data;
  },

  async delete(id: number): Promise<Company> {
    return await Client.delete(`/e1-secretaria-api/public/api/treinamentos/empresas/${id}`);
  },
}
