import {  SecretarySeriesRepository } from "@/api/Repositories";
import { ref } from "vue";
import { serieSelectMapper } from "../mappers/serie";

export default function useSeriesType() {
  const seriesOptions = ref<SelectOptionType[]>([]);

  const getSeriesByCourse = (async (id: string | number) => {
    seriesOptions.value = serieSelectMapper(await SecretarySeriesRepository.getSerieByCourse(Number(id)))
  });

  return { getSeriesByCourse, seriesOptions }
}
