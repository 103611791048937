<template>
  <template-dashboard>
    <dashboard-title title="Inserir Curso">
      <div>
        <app-button
          @click="$router.back"
          left-icon="arrow_back"
          class="w-auto back"
          mini
          >Voltar</app-button
        >
      </div>
    </dashboard-title>
    <form @submit="onSubmit">
      <div class="flex flex-col md:flex-row justify-between">
        <div class="flex gap-4 mb-6 md:mb-0">
          <app-checkbox label="Tem disciplina" name="tem_disciplina" />
          <app-checkbox label="Tem Série" name="tem_serie" />
        </div>
        <app-switch name="ativo" label="Ativo" class="self-end" />
      </div>
      <div class="grid grid-cols-2 mt-6 md:mt-4 gap-4">
        <app-select
          name="tipo_curso_id"
          :options="courseTypeOptions"
          label="Tipo de Curso"
          class="col-span-2 md:col-span-1"
        />
        <simple-text-field
          name="titulo"
          placeholder="Titulo"
          class="col-span-2 md:col-span-1"
        />
        <simple-text-field name="descricao" placeholder="Descrição" />
        <simple-text-field name="sigla" placeholder="Sigla" />
        <simple-text-area
          name="observacoes"
          placeholder="Observações"
          class="col-span-2 h-28"
        />
      </div>
      <div
        class="col-span-2 pt-4 mt-4 border-t border-primary border-opacity-10"
      >
        <app-button mini left-icon="save" class="w-auto ml-auto yellow">
          Salvar
        </app-button>
      </div>
    </form>
  </template-dashboard>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";
import AppButton from "@/components/AppButton.vue";
import AppCheckbox from "@/components/AppCheckbox.vue";
import AppSwitch from "@/components/AppSwitch.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import SimpleTextArea from "@/components/SimpleTextArea.vue";
import AppSelect from "@/components/AppSelect.vue";
import { useForm } from "vee-validate";
import schema from "@/utils/validations/course";
import { useRouter } from "vue-router";
import { CourseRepository, UtilsRepository } from "@/api/Repositories";
import { useToast } from "vue-toastification";

export default defineComponent({
  components: {
    TemplateDashboard,
    DashboardTitle,
    AppButton,
    AppCheckbox,
    AppSwitch,
    SimpleTextField,
    SimpleTextArea,
    AppSelect,
  },

  setup() {
    const courseTypeOptions = ref([]);
    const router = useRouter();
    const toast = useToast();

    const { handleSubmit } = useForm({
      validationSchema: schema,
      initialValues: schema.cast(),
    });

    const onSubmit = handleSubmit((values) => {
      CourseRepository.create(values)
        .then(() => {
          router.push("/secretaria/cursos");
          toast.success("Curso criado com sucesso!");
        })
        .catch(({ response }) => {
          const { data } = response;
          const errors = Object.values(data.data)
            .map((e) => e.join(", "))
            .join(", ");

          toast.error(errors);
        });
    });

    onMounted(async () => {
      courseTypeOptions.value = (await UtilsRepository.listCourseTypes()).map(
        (c) => ({
          label: c.descricao,
          value: c.id,
        })
      );
    });

    return { onSubmit, courseTypeOptions };
  },
});
</script>

<style>
</style>
