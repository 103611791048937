const semester = [
  {
    label: 'Anual',
    value: 0,
  },
  {
    label: '1 Semestre',
    value: 1,
  },
  {
    label: '2 Semestre',
    value: 2,
  },
];

export default semester;
