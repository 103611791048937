<template>
  <template-register-content>
    <template #title>Criar conta</template>
    <template #subtitle>Para começar, precisamos de algumas informações</template>
    <template #form-content>
      <div class="row grid grid-2">
        <app-select
          label="Você é"
          v-model="accountType"
          :options="profileTypeOptions"
          :error="accountTypeMeta.touched && errors['pessoa.tipo_pessoa']"
        />
        <div>
          <text-field
            v-if="isNaturalPerson"
            name="pessoa.cpf"
            label="Qual seu CPF?"
            mask="###.###.###-##"
            v-model="cpf"
            :error="cpfMeta.touched && errors['pessoa.cpf']"
          />
          <text-field
            v-else
            name="pessoa.cnpj"
            label="Qual o CNPJ?"
            mask="##.###.###/####-##"
            v-model="cnpj"
            :error="cnpjMeta.touched && errors['pessoa.cnpj']"
          />
        </div>
      </div>
      <div class="row">
        <text-field
          v-if="isNaturalPerson"
          name="pessoa.nome"
          label="Qual seu nome completo?"
          v-model="name"
          :error="nameMeta.touched && errors['pessoa.nome']"
        />
        <text-field
          v-else
          name="pessoa.razao_social"
          label="Qual a razão social?"
          v-model="corporateName"
          :error="corporateNameMeta.touched && errors['pessoa.razao_social']"
        />
      </div>
      <template v-if="isNaturalPerson">
        <div class="row grid grid-2">
          <app-select
            :options="genderOptions"
            v-model="gender"
            label="Qual o seu gênero?"
            :error="genderMeta.touched && errors['pessoa.genero']"
          />
          <text-field
            name="pessoa.data_nascimento"
            label="Data de Nascimento"
            mask="##/##/####"
            v-model="birthdate"
            :error="birthdateMeta.touched && errors['pessoa.data_nascimento']"
          />
        </div>
        <div class="row">
          <text-field
            name="pessoa.nome_social"
            label="Possui nome social?"
            v-model="socialName"
            :error="socialNameMeta.touched && errors['pessoa.nome_social']"
          />
        </div>
        <div class="row grid grid-2">
          <text-field
            label="E-mail"
            v-model="email"
            :error="emailMeta.touched && errors['contato.email']"
          />
          <div class="row flex">
            <text-field
              class="flex-1"
              name="pessoa.contatos[0].ddd"
              label="DDD"
              mask="(##)"
              v-model="ddd"
              :error="dddMeta.touched && errors['contato.ddd']"
            />
            <text-field
              class="flex-2"
              name="pessoa.contatos[0].phone"
              label="Telefone"
              :mask="['####-####', '#####-####']"
              v-model="phone"
              :error="phoneMeta.touched && errors['contato.telefone']"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row">
          <text-field
            name="pessoa.nome"
            label="Qual o nome da instituição?"
            v-model="name"
            :error="nameMeta.touched && errors['pessoa.nome']"
          />
        </div>
      </template>
    </template>
    <template #buttons>
      <app-button type="button" @click="goBack" class="white-primary">
        Voltar
      </app-button>
      <app-button type="submit" class="primary" rightIcon="chevron_right">
        Continuar
      </app-button>
    </template>
  </template-register-content>
</template>

<script>
import { defineComponent, inject } from "vue";

import AppButton from "@/components/AppButton.vue";
import TextField from "@/components/TextField.vue";
import TemplateRegisterContent from "@/components/TemplateRegisterContent.vue";
import AppSelect from "@/components/AppSelect.vue";
import { useField, useFormErrors } from "vee-validate";

export default defineComponent({
  components: { AppButton, TextField, TemplateRegisterContent, AppSelect },
  inject: ["goBack"],
  data() {
    return {
      profileTypeOptions: [
        {
          value: "PF",
          label: "Pessoa Física"
        },
        {
          value: "PJ",
          label: "Pessoal Jurídica"
        }
      ],
      genderOptions: [
        {
          value: "F",
          label: "Feminino"
        },
        {
          value: "M",
          label: "Masculino"
        },
        {
          value: "Prefiro não dizer",
          label: "Prefiro não dizer"
        }
      ]
    };
  },
  setup() {
    const errors = useFormErrors();

    const { value: accountType, meta: accountTypeMeta } = useField("pessoa.tipo_pessoa", null, {
      initialValue: "PF",
      validateOnMount: true
    });

    const { value: gender, meta: genderMeta } = useField("pessoa.genero");
    const { value: cpf, meta: cpfMeta } = useField("pessoa.cpf");
    const { value: cnpj, meta: cnpjMeta } = useField("pessoa.cnpj");
    const { value: name, meta: nameMeta } = useField("pessoa.nome");
    const { value: birthdate, meta: birthdateMeta } = useField("pessoa.data_nascimento");
    const { value: socialName, meta: socialNameMeta } = useField("pessoa.nome_social");
    const { value: corporateName, meta: corporateNameMeta } = useField("pessoa.razao_social");
    const { value: email, meta: emailMeta } = useField("contato.email", null);
    const { value: ddd, meta: dddMeta } = useField("contato.ddd");
    const { value: phone, meta: phoneMeta } = useField("contato.telefone");

    const isNaturalPerson = inject("isNaturalPerson");

    return {
      errors,
      isNaturalPerson,
      accountType,
      accountTypeMeta,
      gender,
      genderMeta,
      cpf,
      cpfMeta,
      cnpj,
      cnpjMeta,
      name,
      nameMeta,
      birthdate,
      birthdateMeta,
      socialName,
      socialNameMeta,
      corporateName,
      corporateNameMeta,
      email,
      emailMeta,
      ddd,
      dddMeta,
      phone,
      phoneMeta
    };
  }
});
</script>
