<template>
  <article class="card-wrapper">
    <img v-if="item.logotipo" class="card-image" :src="item.logotipo" />
    <div class="card-content">
      <h2 class="card-title">{{ item.nome }}</h2>
      <p class="text-xs card-adress">Endereço</p>
      <p class="card-info">{{ item.info?.text }}</p>
      <div class="card-actions">
        <span class="select-item">Selecionar</span>
        <app-dropdown>
          <span class="material-icons card-actions-icon">more_vert</span>
          <template #content>
            <slot name="dropdown" />
          </template>
        </app-dropdown>
      </div>
    </div>
  </article>
</template>

<script>
import AppDropdown from "./AppDropdown.vue";
import AppDropdownItem from "./AppDropdownItem.vue";
export default {
  components: { AppDropdown, AppDropdownItem },
  name: "DashboardItemCard",
  props: {
    item: {
      type: Object,
      required: true,
      default: {
        info: {},
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  @apply inline-block bg-white border-2 border-black border-opacity-5 rounded min-h-card;
}

.card-image {
  @apply w-full h-40 object-contain;
}

.card-adress {
  color: transparentize($color: $black-color, $amount: 0.38);
}

.card-content {
  @apply p-4;
}

.card-title {
  @apply text-xl text-primary font-semibold;
}

.card-info-label {
  @apply text-xs text-black text-opacity-30 mt-2;
}

.card-info {
  & {
    @apply text-sm text-black text-opacity-60 mt-1;
  }

  &--warning {
    @apply text-warning;
  }

  &--uppercase {
    @apply uppercase;
  }

  &-mt-2 {
    @apply mt-2;
  }
}

.select-item {
  @apply text-sm text-primary font-semibold uppercase select-none cursor-pointer;
}

.card-actions {
  & {
    @apply flex items-center justify-between mt-4;
  }

  &-icon {
    @apply text-lg text-black cursor-pointer;
  }
}
</style>
