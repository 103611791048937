<template>
  <div class="w-full">
    <div>
      <slot name="label" />
    </div>
    <div class="w-full pb-2 overflow-x-auto md:pb-0">
      <table class="w-full text-left app-table">
        <thead class="px-4">
          <slot name="headers" />
          <tr>
            <th v-for="h in headers" :key="h">{{ h }}</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <slot />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    headers: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
});
</script>


<style>
.app-table {
  border-spacing: 0 0.5rem;
}

.app-table th {
  @apply px-5 pb-4 font-normal text-black text-opacity-60;
}

.app-table td {
  @apply px-5 py-6 border-b border-primary border-opacity-10;
}

.app-table tr td:first-child,
.app-table tr td:last-child {
  @apply rounded;
}
</style>
