<template>
  <error-message :error="errorMessage">
    <div class="container h-full" :class="{ 'container--readonly': readonly }">
      <textarea
        :name="name"
        :value="inputValue"
        :required="required"
        :readonly="readonly"
        class="text-area h-full"
        @input="handleChange"
        @blur="handleBlur"
      />
      <label :class="{ typed: inputValue.length > 0 }">{{ placeholder }}</label>
    </div>
  </error-message>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ErrorMessage from "./ErrorMessage.vue";
import { useField } from "vee-validate";

export default defineComponent({
  components: { ErrorMessage },
  name: "SimpleTextArea",
  props: {
    name: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    label: String,
    placeholder: String,
    required: String,
    readonly: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const { handleChange, handleBlur, value: inputValue, errorMessage } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value
      }
    );

    return {
      handleChange,
      handleBlur,
      inputValue,
      errorMessage
    };
  }
});
</script>

<style scoped lang="scss">
.container {
  @apply inline-block w-full relative rounded bg-white border-2 border-primary border-opacity-10 text-base px-4 py-2;
}

.text-area {
  @apply w-full mt-3 outline-none text-primary bg-transparent resize-none;
}

.container--readonly {
  @apply bg-transparent;
}

.text-area::placeholder {
  color: transparentize($color: #000000, $amount: 0.7);
}

label {
  @apply absolute top-6 left-4 text-base font-medium text-black text-opacity-50 pointer-events-none;
  transition: all 0.3s ease;
  transform: translateY(-50%);
}

.text-area:focus ~ label,
label.typed {
  @apply top-1 left-4 text-xs font-normal;
  transform: translateY(0);
}
</style>
