<template>
  <template-dashboard>
    <template-secretary-filters>
      <template #filters>
        <app-select label="Unidade" class="select-units" />
        <app-select label="Ano" class="select-years" />
      </template>
    </template-secretary-filters>
    <dashboard-title title="Unidades">
      <div>
        <app-button
          @click="$router.back"
          left-icon="arrow_back"
          class="w-auto back"
          mini
          >Voltar</app-button
        >
      </div>
    </dashboard-title>
    <div class="form-content">
      <form @submit="onSubmit" class="grid gap-6">
        <div class="grid col-span-1">
          <simple-text-field name="nome" placeholder="Nome" />
        </div>
        <div class="grid col-span-1">
          <simple-text-field name="autorizacao" placeholder="Autorização" />
        </div>

        <div class="grid w-full grid-cols-1 gap-6 md:grid-cols-2">
          <dashboard-upload-card name="logotipo" label="Logotipo" />
          <dashboard-upload-card name="assinatura" label="Assinatura" />
        </div>

        <div class="grid md:grid-cols-2">
          <simple-text-field
            name="enderecos[0].cep"
            placeholder="CEP"
            :mask="zipCodeMask"
            @keyup="handleGetCep"
          />
        </div>

        <div class="grid grid-cols-2 gap-6 md:grid-cols-4">
          <simple-text-field
            name="enderecos[0].logradouro"
            placeholder="Endereço"
            class="col-span-2 md:col-span-3"
          />
          <simple-text-field
            name="enderecos[0].numero"
            placeholder="Número"
            class="col-span-2 md:col-span-1"
          />
        </div>

        <div class="grid grid-cols-2 gap-6">
          <simple-text-field
            name="enderecos[0].bairro"
            placeholder="Bairro"
            class="col-span-2 md:col-span-1"
          />
          <simple-text-field
            name="enderecos[0].complemento"
            placeholder="Complemento"
            class="col-span-2 md:col-span-1"
          />
        </div>

        <div class="grid grid-cols-2 gap-6 md:grid-cols-4">
          <app-select
            name="enderecos[0].cidade_id"
            label="Cidade"
            class="md:col-span-3"
            :options="cityOptions"
          />
          <app-select
            label="UF"
            name="enderecos[0].uf"
            @change="handleGetCities"
            :compact="true"
            :options="statesOptions"
          />
        </div>

        <div class="grid grid-cols-2 gap-6">
          <simple-text-field
            name="contatos[0].nome_contato"
            placeholder="Contato"
          />
          <app-select
            name="contatos[0].tipo_contato_id"
            label="Tipo"
            :compact="true"
            :options="contactTypeOptions"
          />
        </div>

        <div class="grid gap-6 md:grid-cols-6">
          <simple-text-field
            name="contatos[0].email"
            placeholder="Email"
            class="col-span-3"
          />
          <simple-text-field
            name="contatos[0].ddd"
            placeholder="DDD"
            :mask="dddMask"
          />
          <simple-text-field
            name="contatos[0].telefone"
            placeholder="Telefone"
            class="col-span-2"
            :mask="phoneCellphoneMask"
          />
        </div>
        <div class="flex justify-end w-full mt-10">
          <app-button type="submit" class="w-auto yellow mini" leftIcon="save"
            >Salvar</app-button
          >
        </div>
      </form>
    </div>
  </template-dashboard>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppButton from "@/components/AppButton.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import TemplateSecretaryFilters from "@/components/TemplateSecretaryFilters.vue";
import DashboardUploadCard from "@/components/DashboardUploadCard.vue";
import { useForm } from "vee-validate";
import useAddress from "@/utils/composables/address";
import useContactTypes from "@/utils/composables/contact_type";
import { zipCodeMask } from "@/utils/validations/mask";
import { FileRepository, SecretaryUnitRepository } from "@/api/Repositories";
import schema from "@/utils/validations/unity";
import { phoneCellphoneMask, dddMask } from "@/utils/validations/mask";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default defineComponent({
  components: {
    TemplateDashboard,
    AppSelect,
    AppButton,

    TemplateSecretaryFilters,
    SimpleTextField,
    DashboardUploadCard,
    DashboardTitle,
  },
  name: "SecretaryUnitsCreate",
  setup() {
    const units = ref([]);

    const router = useRouter();
    const toast = useToast();

    const {
      meta: formMeta,
      errors,
      setFieldValue,
      values,
      handleSubmit,
    } = useForm<Unity>({
      validationSchema: schema as any,
      initialValues: {} as Unity,
    });

    const address = useAddress({
      setFieldValue,
      prefix: "enderecos[0]",
    });

    const contactTypes = useContactTypes();

    const onSubmit = handleSubmit(async (data) => {
      const logo = await FileRepository.savePhoto({ file: data.logotipo[0] });
      const signature = await FileRepository.saveFile({
        file: data.assinatura[0],
      });

      const formData = {
        ...data,
        logotipo: logo.url,
        assinatura: signature.url,
      };

      SecretaryUnitRepository.create(formData)
        .then(() => {
          router.push("/secretaria/unidades");
          toast.success("Unidade criado com sucesso!");
        })
        .catch(({ response }) => {
          const { data } = response;
          const errors = Object.values(data.data as Record<string, string[]>)
            .map((e) => e.join(", "))
            .join(", ");

          toast.error(errors);
        });
    });

    return {
      zipCodeMask,
      onSubmit,
      phoneCellphoneMask,
      dddMask,
      ...address,
      ...contactTypes,
    };
  },
});
</script>

<style lang="scss" scoped>
.selects-container {
  margin-bottom: 3rem;
  display: flex;
  justify-content: flex-end;
  gap: $default-gap;
}

.form-content {
  margin-top: $default-gap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.select-years {
  @apply md:max-w-yearFilter w-full;
}

.select-units {
  @apply md:max-w-unityFilter w-full;
}

.title-container {
  border-bottom: 1px solid transparentize($color: $primary-color, $amount: 0.88);
}

.gap {
  gap: $default-gap;
}
</style>
