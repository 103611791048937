<template>
  <div :class="{ 'error-message': hasError }">
    <slot></slot>
    <transition name="error-text">
      <p v-if="hasError" class="text-message">{{ error }}</p>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    error: {
      type: [String, Boolean],
      default: ""
    }
  },
  computed: {
    hasError() {
      return this.error.length > 0;
    }
  }
});
</script>

<style lang="scss">
input.text-field,
div.app-select {
  transition: 0.2s border ease-in;
}

.error-message {
  & input.text-field,
  & div.app-select {
    color: $error-color !important;
    border-color: $error-color;
  }
}
</style>

<style lang="scss" scoped>
.error-message {
  position: relative;
}

.text-message {
  position: absolute;
  margin-top: 0.125rem;
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: $error-color;
}

.error-text-enter-active,
.error-text-leave-active {
  transition: opacity 0.2s ease;
}

.error-text-enter-from,
.error-text-leave-to {
  opacity: 0;
}
</style>
