import Client from '../AxiosClient';

export default {
  async getAll(): Promise<Course[]> {
    const { data } = await Client.get('/e1-secretaria-api/public/api/treinamentos/cursos');
    return data.data.data;
  },

  async create(payload: Record<string, any>): Promise<Course> {
    const { data } = await Client.post('/e1-secretaria-api/public/api/treinamentos/cursos', payload);
    return data.data;
  },
};
