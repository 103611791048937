<template>
  <error-message :error="errorMessage">
    <div
      class="app-select"
      :class="{ open: open, 'app-select--readonly': readonly }"
      :tabindex="tabIndex"
      @click="!readonly && (open = !open)"
      @blur="open = false"
    >
      <div class="select-container" :class="{ selected: hasSelecteds }">
        <div>
          <label class="label">{{ label }}</label>
          <p class="selected-item">
            {{ selectLabel || "" }}
          </p>
        </div>
        <span class="material-icons icon"> expand_more </span>
      </div>
      <div class="options" :class="{ hidden: !open }">
        <div
          class="option"
          v-for="option of options"
          :key="option.value"
          @click.stop="handleChangeValue(option)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </error-message>
</template>

<script lang="ts">
import { useField } from "vee-validate";
import { defineComponent } from "vue";
import ErrorMessage from "./ErrorMessage.vue";

export default defineComponent({
  components: { ErrorMessage },
  name: "Select",
  props: {
    options: {
      type: Array as () => SelectOptionType[],
      default: []
    },
    label: {
      type: String,
      required: true
    },
    tabIndex: {
      type: Number,
      required: false,
      default: 0
    },
    name: {
      type: String,
      required: true,
      default: ""
    },
    value: {
      type: [String, Number, Array]
    },
    multi: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      selected: this.multi ? [] : {}
    };
  },
  methods: {
    handleChangeValue(option: SelectOptionType) {
      if (this.multi) {
        const selecteds = this.selected as SelectOptionType[];

        const isSelected = selecteds.findIndex(s => s.value === option.value);

        if (isSelected === -1) {
          selecteds.push(option);
        } else {
          selecteds.splice(isSelected, 1);
        }

        const values = selecteds.map(s => s.value);

        this.handleChange(values);
        this.$emit("change", values);
      } else {
        this.open = false;

        this.handleChange(option.value);
        this.$emit("change", option.value);
      }
    }
  },
  watch: {
    inputValue() {
      if (this.multi) {
        const inputs = this.inputValue as Array<string | number>;

        this.selected = this.options.filter(op => inputs.includes(op.value));
      } else {
        this.selected = this.options.find(v => v.value === this.inputValue) || {};
      }
    },
    value(value) {
      this.$emit("change", value);
    }
  },
  computed: {
    selectLabel(): string {
      if (this.multi) {
        return (this.selected as SelectOptionType[]).map(s => s.label).join(", ");
      } else {
        return this.selected ? (this.selected as SelectOptionType).label : "";
      }
    },
    hasSelecteds(): boolean {
      if (this.multi) {
        return (this.selected as SelectOptionType[]).length > 0;
      } else {
        return ((this.selected as SelectOptionType).label ?? {}).length > 0;
      }
    }
  },
  setup(props) {
    const { value: inputValue, errorMessage, handleChange } = useField(props.name, undefined, {
      initialValue: props.value
    });

    return {
      handleChange,
      errorMessage,
      inputValue
    };
  }
});
</script>

<style lang="scss" scoped>
.app-select {
  & {
    position: relative;
    width: 100%;
    background-color: $white-color;
    @apply h-14 rounded border-2 border-primary border-opacity-10;
    outline: none;
    user-select: none;
  }


  &--readonly {
    @apply bg-transparent;
  }

  &.open {
    & {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    & .select-container .icon {
      transform: rotate(180deg);
    }

    & .simple-select-container .icon {
      transform: rotate(180deg);
    }
  }

  & .select-container {
    & {
      position: relative;
      padding: 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .label {
      @apply text-black text-opacity-50 text-base;
    }

    & .icon {
      position: relative;
      transition: 0.1s transform;
      @apply text-black text-opacity-60;
    }

    &.selected {
      & {
        @apply py-1 truncate;
      }

      & .label {
        @apply text-xs;
      }
    }
  }

  & .label {
    top: 1rem;
    left: 1rem;
    font-size: 0.8125rem;

    font-weight: 500;
    color: transparentize($color: #000000, $amount: 0.5);
    pointer-events: none;
    transition: all 0.3s ease;
  }

  & .selected-item {
    margin-top: 0.125rem;
    line-height: 1rem;
    @apply text-primary outline-none;
  }

  & .options {
    & {
      background-color: $white-color;
      position: absolute;
      width: 100%;
      border-bottom-left-radius: 0.875rem;
      border-bottom-right-radius: 0.875rem;
      z-index: 99;
      max-height: 10rem;
      overflow-y: auto;
    }

    &.hidden {
      display: none;
    }

    & .option {
      & {
        cursor: pointer;
        padding: 1rem;
        border-bottom: 1px solid transparentize($color: #000000, $amount: 0.9);
        color: $primary-color;
      }

      &:hover {
        background-color: transparentize($color: #000000, $amount: 0.98);
      }

      &:last-child {
        border: none;
      }
    }
  }
}
</style>
