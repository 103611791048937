import Client from "../AxiosClient";

export default {
  async list(): Promise<Unity[]> {
    const { data } = await Client.get("/e1-secretaria-api/public/api/treinamentos/unidades");

    return data.data.data;
  },

  async create(payload: Unity) {
    return await Client.post("/e1-secretaria-api/public/api/treinamentos/unidades", payload);
  },

  async get(id: string): Promise<Unity> {
    const { data } = await Client.get(`/e1-secretaria-api/public/api/treinamentos/unidades/${id}`);

    return data.data;
  },

  async edit(id: string, payload: Unity): Promise<Unity> {
    const { data } = await Client.put(
      `/e1-secretaria-api/public/api/treinamentos/unidades/${id}`,
      payload
    );

    return data.data;
  },

  async delete(id: string) {
    return await Client.delete(`/e1-secretaria-api/public/api/treinamentos/unidades/${id}`);
  }
};
