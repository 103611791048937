<template>
  <template-dashboard></template-dashboard>
</template>

<script>
import TemplateDashboard from "@/components/TemplateDashboard.vue";

export default {
  name: "Dashboard",
  components: { TemplateDashboard }
};
</script>

<style></style>
