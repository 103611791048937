import Client from "../AxiosClient";

type FilePayload = {
  file: File;
  description?: string;
};

const save = async (url: string, payload: FilePayload): Promise<FileData> => {
  const formData = new FormData();
  formData.append("arquivo", payload.file);
  if (payload.description) {
    formData.append("description", payload.description);
  }

  const { data } = await Client.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

  return data.data;
};

export default {
  async saveFile(payload: FilePayload) {
    return save("/e1-util-api/public/api/treinamentos/arquivo/salvar", payload);
  },
  async savePhoto(payload: FilePayload) {
    return save("/e1-util-api/public/api/treinamentos/arquivo/salvar/foto", payload);
  }
};
