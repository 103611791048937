<template>
  <template-dashboard>
    <template-secretary-filters>
      <template #filters>
        <app-select label="Unidade" class="select-units" />
        <app-select label="Ano" class="select-years" />
      </template>
    </template-secretary-filters>
    <dashboard-title title="Inserir Turma">
      <app-button
        left-icon="arrow_back"
        class="w-auto back"
        mini
        @click="$router.back"
        >Voltar</app-button
      >
    </dashboard-title>
    <form @submit="onSubmit">
      <div class="mt-4">
        <simple-text-field
          class="md:col-span-4"
          placeholder="Descrição"
          name="descricao"
        />
      </div>

      <div class="grid grid-cols-2 mt-4 gap-x-4 md:grid-cols-4">
        <app-select
          label="Período"
          class="col-span-2"
          :options="periodOptions"
          name="periodo_id"
        />
        <app-select label="Sala" name="sala_id" warn />
        <simple-text-field placeholder="Ano Letivo" name="ano" warn />
      </div>

      <div class="grid mt-4 md:grid-cols-2 gap-x-4">
        <app-select
          label="Situação"
          :options="situationOptions"
          name="situacao_id"
        />
        <app-select
          label="Segmento"
          :options="courseOptions"
          @change="getSeriesByCourse"
          name="curso_id"
        />
      </div>

      <div class="grid grid-cols-2 mt-4 gap-x-4">
        <app-select label="Série" :options="seriesOptions" name="serie_id" />
        <app-select label="Conta" name="conta_id" warn />
      </div>

      <div class="grid mt-4 md:grid-cols-2 gap-x-4">
        <app-select label="Unidade" :options="unityOptions" name="unidade_id" />
        <app-select
          label="Empresa"
          name="empresa_id"
          :options="companyOptions"
        />
      </div>

      <div class="grid grid-cols-2 mt-4 gap-x-4">
        <simple-text-field placeholder="Vagas" name="vagas" />
        <simple-text-field placeholder="Ordem" name="ordem" />
      </div>

      <div class="grid mt-4 md:grid-cols-4 gap-x-4">
        <simple-text-field placeholder="Data Início" name="data_inicio" />
        <simple-text-field placeholder="Data Término" name="data_termino" />
        <simple-text-field
          placeholder="Horário de entrada"
          name="hora_entrada"
        />
        <simple-text-field placeholder="Horario de saída" name="hora_saida" />
      </div>

      <div class="grid grid-cols-2 mt-4 gap-x-4">
        <simple-text-field placeholder="Dias Letivos" name="dias_letivos" />
        <app-select
          label="Semestre"
          :options="semesterOptions"
          name="semestre"
        />
      </div>

      <div class="grid mt-4 md:grid-cols-2 gap-x-4">
        <simple-text-field placeholder="Carga horária" name="carga_horaria" />
      </div>

      <div
        class="flex justify-end w-full col-span-2 mt-10 footer-separation-border"
      >
        <app-button class="w-auto mt-4 yellow mini" leftIcon="save"
          >Salvar</app-button
        >
      </div>
    </form>
  </template-dashboard>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppButton from "@/components/AppButton.vue";
import TemplateSecretaryFilters from "@/components/TemplateSecretaryFilters.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import {
  SecretaryClassRepository,
  SecretaryCompanyRepository,
  SecretaryUnitRepository,
} from "@/api/Repositories";
import semesterOptions from "@/utils/mocks/semester";
import { useForm } from "vee-validate";
import schema from "@/utils/validations/class";
import { useRoute, useRouter } from "vue-router";
import usePeriodType from "@/utils/composables/period_type";
import useCourseType from "@/utils/composables/course_type";
import useSeriesType from "@/utils/composables/series_type";
import DashboardTitle from "@/components/DashboardTitle.vue";

export default defineComponent({
  components: {
    TemplateDashboard,
    AppSelect,
    AppButton,
    TemplateSecretaryFilters,
    SimpleTextField,
    DashboardTitle,
  },
  name: "SecretaryClassCreate",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const periodOptions = usePeriodType();
    const courseOptions = useCourseType();
    const situationOptions = ref<SelectOptionType[]>([]);
    const unityOptions = ref<SelectOptionType[]>([]);
    const companyOptions = ref<SelectOptionType[]>([]);
    const { getSeriesByCourse, seriesOptions } = useSeriesType();
    const editId = ref();

    const {
      handleSubmit,
      meta: formMeta,
      setValues,
    } = useForm({
      validationSchema: schema,
    });

    onMounted(async () => {
      editId.value = Number(route.params.id);

      situationOptions.value = (
        await SecretaryClassRepository.getSituations()
      ).map((s) => ({
        label: s.descricao,
        value: s.id,
      }));

      unityOptions.value = (await SecretaryUnitRepository.list()).map((u) => ({
        label: u.nome,
        value: u.id,
      }));

      companyOptions.value = (await SecretaryCompanyRepository.getAll()).map(
        (c) => ({
          label: c.pessoa.nome_social || c.pessoa.nome,
          value: c.id!,
        })
      );

      if (!editId.value) {
        return;
      }

      const formValues = await SecretaryClassRepository.getClassById(
        editId.value
      );

      await getSeriesByCourse(formValues.curso_id);

      setValues({
        id: formValues.id,
        descricao: formValues.descricao,
        periodo_id: formValues.periodo_id,
        ano: formValues.ano,
        situacao_id: formValues.situacao_id,
        curso_id: formValues.curso_id,
        serie_id: formValues.serie_id,
        unidade_id: formValues.unidade_id,
        empresa_id: formValues.empresa_id,
        vagas: formValues.vagas,
        ordem: formValues.ordem,
        data_inicio: formValues.data_inicio,
        data_termino: formValues.data_termino,
        hora_entrada: formValues.hora_entrada,
        hora_saida: formValues.hora_saida,
        dias_letivos: formValues.dias_letivos,
        semestre: formValues.semestre,
        carga_horaria: formValues.carga_horaria,
      });
    });

    const onSubmit = handleSubmit((values) => {
      if (!editId.value) {
        SecretaryClassRepository.create(values).then(() => {
          router.push("/secretaria/turmas");
        });

        return;
      }

      SecretaryClassRepository.update(editId.value, values).then(() => {
        console.log("entrou", editId.value);
        router.push("/secretaria/turmas");
      });
    });

    return {
      ...periodOptions,
      ...courseOptions,
      seriesOptions,
      situationOptions,
      unityOptions,
      semesterOptions,
      companyOptions,
      onSubmit,
      getSeriesByCourse,
    };
  },
});
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-enter-from {
  opacity: 0;
}

.select-years {
  @apply md:max-w-yearFilter w-full;
}

.select-units {
  @apply md:max-w-unityFilter w-full;
}

.title-container {
  border-bottom: 1px solid transparentize($color: $primary-color, $amount: 0.88);
  @apply flex flex-col md:flex-row justify-between pb-4;
}

.list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.table-text {
  color: transparentize($color: $black-color, $amount: 0.6);
}

.footer-separation-border {
  border-top: 1px solid transparentize($color: $primary-color, $amount: 0.88);
}
</style>
