<template>
  <template-login>
    <template v-if="!isSubmitted">
      <h1 class="template-title">Esqueci minha senha</h1>
      <h2 class="template-text">Por favor digite seu e-mail para enviarmos as instruções</h2>
      <form @submit.prevent="onSubmit">
        <simple-text-field
          v-model="email"
          class="default-margin"
          placeholder="E-mail"
          :error="emailMeta.touched && emailError"
        />
        <app-button type="submit" class="primary default-margin" rightIcon="chevron_right">
          Enviar
        </app-button>
      </form>
    </template>
    <template v-else>
      <h1 class="template-title">Pronto! Verifique seu e-mail</h1>
      <h2 class="template-text">
        Enviamos as instruções de redefinição de senha para o email <br />
        <span class="email-text">{{ values.email }}</span>
      </h2>
      <app-button
        @click="$router.push('/')"
        class="primary default-margin"
        rightIcon="chevron_right"
      >
        Login
      </app-button>
    </template>
  </template-login>
</template>

<script>
import { defineComponent, ref } from "vue";

import TemplateLogin from "@/components/TemplateLogin.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import AppButton from "@/components/AppButton.vue";

import { useField, useForm } from "vee-validate";
import * as yup from "yup";

export default defineComponent({
  name: "ForgotPassword",
  components: { TemplateLogin, SimpleTextField, AppButton },
  setup() {
    const isSubmitted = ref(false);
    const { handleSubmit, values } = useForm();

    const { value: email, meta: emailMeta, errorMessage: emailError } = useField(
      "email",
      yup
        .string()
        .email()
        .required()
        .label("E-mail")
    );

    const onSubmit = handleSubmit(values => {
      console.log(values);

      isSubmitted.value = true;
    });

    return {
      values,
      email,
      emailMeta,
      emailError,
      onSubmit,
      isSubmitted
    };
  }
});
</script>

<style lang="scss" scoped>
.default-margin {
  margin-top: $default-gap;
}

.email-text {
  font-weight: bold;
}
</style>
