<template>
  <error-message :error="errorMessage" :class="{ 'hidden': type === 'hidden' }">
    <div class="text-field-container" :class="{ 'container--readonly': readonly }">
      <input
        :name="name"
        :value="inputValue"
        :type="inputType"
        :required="required"
        :readonly="readonly"
        class="text-field"
        @input="handleChange"
        @blur="handleBlur"
        v-maska="mask"
      />
      <label :class="{ typed: inputValue?.length > 0 }">{{ placeholder }}</label>
      <span
        @click="switchVisibility"
        v-if="type === 'password'"
        class="material-icons icon icon-right"
      >
        {{ inputType === "password" ? "visibility" : "visibility_off" }}
      </span>
      <span v-else-if="rightIcon" class="material-icons icon icon-right">{{ rightIcon }}</span>
    </div>
  </error-message>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { maska } from "maska";
import ErrorMessage from "./ErrorMessage.vue";
import { useField } from "vee-validate";

export default defineComponent({
  components: { ErrorMessage },
  name: "SimpleTextField",
  props: {
    name: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    type: String,
    label: String,
    placeholder: String,
    required: Boolean,
    readonly: {
      type: Boolean,
      default: false
    },
    rightIcon: String,
    mask: {}
  },
  data() {
    return {
      inputType: this.type
    };
  },
  methods: {
    switchVisibility() {
      this.inputType = this.inputType === "password" ? "text" : "password";
    }
  },
  directives: {
    maska
  },
  setup(props) {
    const { handleChange, handleBlur, value: inputValue, errorMessage } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value
      }
    );

    return {
      handleChange,
      handleBlur,
      inputValue,
      errorMessage
    };
  }
});
</script>

<style scoped lang="scss">
.text-field-container {
  @apply inline-block h-14 w-full relative rounded bg-white border-2 border-primary border-opacity-10 text-base px-4 py-2;
}

.text-field {
  @apply w-full mt-3 outline-none text-primary bg-transparent;
}

.container--readonly {
  @apply bg-transparent;
}

.text-field::placeholder {
  color: transparentize($color: #000000, $amount: 0.7);
}

label {
  @apply absolute top-1/2 left-4 text-base font-medium text-black text-opacity-50 pointer-events-none;
  transition: all 0.3s ease;
  transform: translateY(-50%);
}

.text-field:focus ~ label,
label.typed {
  @apply top-1 left-4 text-xs font-normal;
  transform: translateY(0);
}

.icon {
  font-size: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: transparentize($color: #0f233e, $amount: 0.7);
  user-select: none;
  &-right {
    right: 1rem;
  }
}
</style>
