
import { defineComponent, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppButton from "@/components/AppButton.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import TemplateSecretaryFilters from "@/components/TemplateSecretaryFilters.vue";
import DashboardUploadCard from "@/components/DashboardUploadCard.vue";
import { useForm } from "vee-validate";
import useAddress from "@/utils/composables/address";
import useContactTypes from "@/utils/composables/contact_type";
import { zipCodeMask } from "@/utils/validations/mask";
import { FileRepository, SecretaryUnitRepository } from "@/api/Repositories";
import schema from "@/utils/validations/unity";
import { phoneCellphoneMask, dddMask } from "@/utils/validations/mask";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default defineComponent({
  components: {
    TemplateDashboard,
    AppSelect,
    AppButton,

    TemplateSecretaryFilters,
    SimpleTextField,
    DashboardUploadCard,
    DashboardTitle,
  },
  name: "SecretaryUnitsCreate",
  setup() {
    const units = ref([]);

    const router = useRouter();
    const toast = useToast();

    const {
      meta: formMeta,
      errors,
      setFieldValue,
      values,
      handleSubmit,
    } = useForm<Unity>({
      validationSchema: schema as any,
      initialValues: {} as Unity,
    });

    const address = useAddress({
      setFieldValue,
      prefix: "enderecos[0]",
    });

    const contactTypes = useContactTypes();

    const onSubmit = handleSubmit(async (data) => {
      const logo = await FileRepository.savePhoto({ file: data.logotipo[0] });
      const signature = await FileRepository.saveFile({
        file: data.assinatura[0],
      });

      const formData = {
        ...data,
        logotipo: logo.url,
        assinatura: signature.url,
      };

      SecretaryUnitRepository.create(formData)
        .then(() => {
          router.push("/secretaria/unidades");
          toast.success("Unidade criado com sucesso!");
        })
        .catch(({ response }) => {
          const { data } = response;
          const errors = Object.values(data.data as Record<string, string[]>)
            .map((e) => e.join(", "))
            .join(", ");

          toast.error(errors);
        });
    });

    return {
      zipCodeMask,
      onSubmit,
      phoneCellphoneMask,
      dddMask,
      ...address,
      ...contactTypes,
    };
  },
});
