
import { useField } from "vee-validate";
import { defineComponent } from "vue";
import ErrorMessage from "./ErrorMessage.vue";

export default defineComponent({
  components: { ErrorMessage },
  name: "Select",
  props: {
    options: {
      type: Array as () => SelectOptionType[],
      default: []
    },
    label: {
      type: String,
      required: true
    },
    tabIndex: {
      type: Number,
      required: false,
      default: 0
    },
    name: {
      type: String,
      required: true,
      default: ""
    },
    value: {
      type: [String, Number, Array]
    },
    multi: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      selected: this.multi ? [] : {}
    };
  },
  methods: {
    handleChangeValue(option: SelectOptionType) {
      if (this.multi) {
        const selecteds = this.selected as SelectOptionType[];

        const isSelected = selecteds.findIndex(s => s.value === option.value);

        if (isSelected === -1) {
          selecteds.push(option);
        } else {
          selecteds.splice(isSelected, 1);
        }

        const values = selecteds.map(s => s.value);

        this.handleChange(values);
        this.$emit("change", values);
      } else {
        this.open = false;

        this.handleChange(option.value);
        this.$emit("change", option.value);
      }
    }
  },
  watch: {
    inputValue() {
      if (this.multi) {
        const inputs = this.inputValue as Array<string | number>;

        this.selected = this.options.filter(op => inputs.includes(op.value));
      } else {
        this.selected = this.options.find(v => v.value === this.inputValue) || {};
      }
    },
    value(value) {
      this.$emit("change", value);
    }
  },
  computed: {
    selectLabel(): string {
      if (this.multi) {
        return (this.selected as SelectOptionType[]).map(s => s.label).join(", ");
      } else {
        return this.selected ? (this.selected as SelectOptionType).label : "";
      }
    },
    hasSelecteds(): boolean {
      if (this.multi) {
        return (this.selected as SelectOptionType[]).length > 0;
      } else {
        return ((this.selected as SelectOptionType).label ?? {}).length > 0;
      }
    }
  },
  setup(props) {
    const { value: inputValue, errorMessage, handleChange } = useField(props.name, undefined, {
      initialValue: props.value
    });

    return {
      handleChange,
      errorMessage,
      inputValue
    };
  }
});
