<template>
  <template-dashboard>
    <template-secretary-filters>
      <template #filters>
        <app-select label="Unidade" class="select-units" />
        <app-select label="Ano" class="select-years" />
      </template>
    </template-secretary-filters>
    <dashboard-title title="Turmas">
      <app-button
        class="w-auto yellow"
        mini
        leftIcon="add"
        @click="$router.push('/secretaria/turmas/cadastro')"
        >Inserir</app-button
      >
    </dashboard-title>
    <div class="grid mt-4 md:grid-cols-2 gap-x-4">
      <app-select
        label="Segmento"
        :options="courseOptions"
        @change="getSeries"
      />
      <app-select
        v-show="showSeries"
        :options="seriesOptions"
        label="Série"
        @change="getData"
      />
    </div>
    <div class="w-full mt-6" v-if="tableData.length > 0">
      <template-information-card
        v-show="tableData"
        :headers="[
          'Série',
          'Período',
          'Vagas',
          'Alunos',
          'Disponíveis',
          'Situação',
          'Ano',
        ]"
      >
        <tr class="px-4 border-gray-100" v-for="d in tableData" :key="d.id">
          <td class="py-4 table-text">{{ d.serie.descricao }}</td>
          <td class="py-4 table-text">{{ d.situacao_id }}</td>
          <td class="py-4 table-text">{{ d.vagas }}</td>
          <td class="py-4 table-text">-</td>
          <td class="py-4 table-text">-</td>
          <td class="py-4 table-text">{{ d.situacao_id }}</td>
          <td class="py-4 table-text">{{ d.ano }}</td>
          <td class="py-4 text-right">
            <app-dropdown>
              <span class="cursor-pointer material-icons card-actions-icon"
                >more_vert</span
              >
              <template #content>
                <app-dropdown-item @click="handleEdit(d.id)"
                  >Editar unidade</app-dropdown-item
                >
                <app-dropdown-item @click="handleDelete(d.id)"
                  >Excluir unidade</app-dropdown-item
                >
              </template>
            </app-dropdown>
          </td>
        </tr>
      </template-information-card>
    </div>
  </template-dashboard>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

import TemplateDashboard from "@/components/TemplateDashboard.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppButton from "@/components/AppButton.vue";
import {
  SecretaryClassRepository,
  SecretarySeriesRepository,
} from "@/api/Repositories";
import AppDropdownItem from "@/components/AppDropdownItem.vue";
import TemplateSecretaryFilters from "@/components/TemplateSecretaryFilters.vue";
import TemplateInformationCard from "@/components/TemplateInformationCard.vue";
import { useRouter } from "vue-router";
import useCourseType from "@/utils/composables/course_type";
import useSeriesType from "@/utils/composables/series_type";
import AppDropdown from "@/components/AppDropdown.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";

type OptionType = {
  label: string;
  value: number;
};

export default defineComponent({
  components: {
    TemplateDashboard,
    AppSelect,
    AppButton,
    TemplateInformationCard,
    AppDropdownItem,
    TemplateSecretaryFilters,
    AppDropdown,
    DashboardTitle,
  },
  name: "SecretaryUnits",
  setup() {
    const courseOptions = useCourseType();
    const showSeries = ref(false);
    const tableData = ref([] as Class[]);
    const CourseOptionId = ref();
    const router = useRouter();
    const { getSeriesByCourse, seriesOptions } = useSeriesType();

    const navigate = (route: string) => {
      router.push(route);
    };

    const getData = async (value: string) => {
      if (!CourseOptionId.value) {
        return;
      }

      console.log("entrou");

      tableData.value = await SecretaryClassRepository.getAll({
        course_id: CourseOptionId.value,
        serie_id: value
      });
    };

    const getSeries = async (value: string) => {
      CourseOptionId.value = Number(value);
      await getSeriesByCourse(CourseOptionId.value);
      showSeries.value = true;
    };

    const handleEdit = (classId: string) => {
      router.push(`/secretaria/turmas/${classId}`);
    };

    const handleDelete = async (id: string) => {
      await SecretaryClassRepository.delete(Number(id));
      tableData.value = tableData.value.filter((c: Class) => {
        return c.id !== Number(id);
      });
    };

    return {
      ...courseOptions,
      navigate,
      showSeries,
      seriesOptions,
      handleEdit,
      getData,
      tableData,
      getSeries,
      handleDelete,
    };
  },
});
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-enter-from {
  opacity: 0;
}

.select-years {
  @apply md:max-w-yearFilter w-full;
}

.select-units {
  @apply md:max-w-unityFilter w-full;
}

.title-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid transparentize($color: $primary-color, $amount: 0.88);
}

.list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.table-text {
  color: transparentize($color: $black-color, $amount: 0.6);
}
</style>
