import { array, object, number, string, date } from "yup";
import addressSchema from "./address";
import contactSchema from "./contact";
import { cpf } from "../regex";
import { parseDateString } from "../format";

const studentSchema = object().shape({
  id: number()
    .positive()
    .nullable()
    .label("ID"),
  rm: number()
    .positive()
    .nullable()
    .notRequired()
    .label("RM"),
  tipo_pessoa: string()
    .oneOf(["PF", "PJ"])
    .required()
    .label("Tipo da pessoa"),
  roles: array()
    .of(string())
    .required()
    .min(1)
    .label("Cargos"),
  cpf: string()
    .matches(cpf)
    .required()
    .label("CPF"),
  nome: string()
    .required()
    .label("Nome"),
  nome_social: string()
    .notRequired()
    .label("Nome Social"),
  data_nascimento: date()
    .transform(parseDateString)
    .max(new Date())
    .required()
    .typeError(({ path }) => `${path} Inválida`)
    .label("Data de Nascimento"),
  enderecos: array()
    .of(addressSchema)
    .min(1)
    .required(),
  contatos: array()
    .of(contactSchema)
    .min(1)
    .required(),
  relacionamentos: array().of(
    object().shape({
      tipo_relacionamento_id: number()
        .positive()
        .required(),
      pessoa_relacionada_id: number()
        .positive()
        .required()
    })
  )
});

export default studentSchema;
