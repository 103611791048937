<template>
  <div class="options-wrapper">
    <div
      :class="[{ selected: modelValue === 'list' }]"
      class="material-icons options-wrapper-icon"
      @click="handleClick('list')"
    >
      list
    </div>
    <div
      :class="[{ selected: modelValue === 'grid' }]"
      class="material-icons options-wrapper-icon"
      @click="handleClick('grid')"
    >
      grid_view
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: String
    }
  },
  setup(_, { emit }) {
    const handleClick = (value: string) => {
      emit("update:modelValue", value);
    };

    return { handleClick };
  }
});
</script>

<style lang="scss" scoped>
.options-wrapper {
  background-color: $white-color;
  display: inline-block;
  border-radius: 0.25rem;
  overflow: hidden;

  &-icon {
    color: $primary-color;
    padding: 0.5rem;
    cursor: pointer;

    &.selected {
      background-color: transparentize($primary-color, 0.9);
      color: transparentize($primary-color, $amount: 0.4);
    }
  }
}
</style>
