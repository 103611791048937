import Client from "../AxiosClient";

export default {
  async listUf(): Promise<Uf[]> {
    const { data } = await Client.get("/e1-util-api/public/api/treinamentos/ufs");
    return data.data;
  },

  async listCities(id: number): Promise<City[]> {
    const { data } = await Client.get(`/e1-util-api/public/api/treinamentos/ufs/${id}/cidades`);
    return data.data;
  },

  async listCourseTypes(): Promise<CourseType[]> {
    const { data } = await Client.get('/e1-util-api/public/api/treinamentos/opcoes/tipo/curso');
    return data.data;
  },

  async listContactTypes(): Promise<ContactType[]> {
    const { data } = await Client.get('/e1-util-api/public/api/treinamentos/opcoes/tipo/contato');
    return data.data;
  },

  async listConfigurations(): Promise<Configuration[]> {
    const { data } = await Client.get('/e1-util-api/public/api/treinamentos/configuracoes');

    return data.data;
  },

  async listRoleTypes(): Promise<Role[]> {
    const { data } = await Client.get('/e1-util-api/public/api/treinamentos/opcoes/tipo/roles');
    return data.data;
  }
};
