<template>
  <template-register-content>
    <template #title>Criar conta</template>
    <template #subtitle>Para qual tipo de usuário deseja criar acesso?</template>
    <template #form-content>
      <div class="row grid grid-2">
        <radio-button v-model="accountType" name="user_type" value="guardian">
          Responsável
        </radio-button>
      </div>
      <div class="row grid grid-2">
        <radio-button v-model="accountType" name="user_type" value="student"> Aluno </radio-button>
      </div>
    </template>
    <template #buttons>
      <app-button type="button" @click="goBack" class="white-primary">Voltar</app-button>
      <app-button type="submit" class="primary" rightIcon="chevron_right">
        Continuar
      </app-button>
    </template>
  </template-register-content>
</template>

<script>
import { defineComponent } from "vue";

import TemplateRegisterContent from "@/components/TemplateRegisterContent.vue";
import RadioButton from "@/components/RadioButton.vue";
import AppButton from "@/components/AppButton.vue";
import { useField } from "vee-validate";
export default defineComponent({
  components: { TemplateRegisterContent, RadioButton, AppButton },
  inject: ["goBack", "nextStep"],
  setup() {
    const { value: accountType } = useField("pessoa.tipo_conta", null, {
      initialValue: "guardian"
    });

    return { accountType };
  }
});
</script>

<style></style>
