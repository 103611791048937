import axios from "axios";

const baseURL = process.env.VUE_APP_API_BASE_URL;

const instance = axios.create({
  baseURL
});

export const setAuth = (data: Record<string, string>) => {
  const authHeaders = {
    "x-auth-token": data["token"],
    "Content-Type": "application/json"
  };

  localStorage.setItem("authHeaders", JSON.stringify(authHeaders));
};

instance.interceptors.request.use(
  config => {
    const authHeaders = JSON.parse(localStorage.getItem("authHeaders") || "{}");
    if (authHeaders && config.method) {
      config.headers[config.method] = {
        ...authHeaders,
        Accept: "*/*"
      };
    }
    return config;
  },
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem("authHeaders");
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default instance;
