
import TemplateDashboard from "@/components/TemplateDashboard.vue";
import DashboardTitle from "@/components/DashboardTitle.vue";
import AppButton from "@/components/AppButton.vue";
import SimpleTextField from "@/components/SimpleTextField.vue";
import AppSelect from "@/components/AppSelect.vue";
import personSchema from "@/utils/validations/yup/person";
import { useForm } from "vee-validate";
import {
  cnpjMask,
  dddMask,
  phoneCellphoneMask,
  zipCodeMask,
} from "@/utils/validations/mask";
import useAddress from "@/utils/composables/address";
import useContactTypes from "@/utils/composables/contact_type";
import { SecretaryCompanyRepository } from "@/api/Repositories";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default {
  components: {
    TemplateDashboard,
    DashboardTitle,
    AppButton,
    SimpleTextField,
    AppSelect,
  },
  setup() {
    const router = useRouter();
    const toast = useToast();
    const { handleSubmit, values, errors, meta, setFieldValue } = useForm({
      validationSchema: personSchema as any,
    });

    const address = useAddress({
      setFieldValue,
      prefix: "pessoa.enderecos[0]",
    });

    const contactTypes = useContactTypes();

    const onSubmit = handleSubmit(async (values) => {
      SecretaryCompanyRepository.create(values)
        .then(() => {
          router.push("/secretaria/cursos");
          toast.success("Empresa criada com sucesso!");
        })
        .catch(({ response }) => {
          const { data } = response;
          const errors = Object.values(data.data)
            .map((e) => (e as string[]).join(", "))
            .join(", ");

          toast.error(errors);
        });
    });

    return {
      onSubmit,
      cnpjMask,
      dddMask,
      phoneCellphoneMask,
      zipCodeMask,
      values,
      errors,
      meta,
      ...address,
      ...contactTypes,
    };
  },
};
